import RequestService from "./RequestService";

import {
  CLIENT_API_URL,
  CONTACTS_API_URL,
  COVERAGE_API_URL,
  DOCUMENTS_API_URL,
  TASKS_API_URL,
  USERS_API_URL,
  REACT_APP_GATEWAY_API_URL,
  REACT_APP_AGGREGATOR_API_URL,

} from "../constants";
// const baseUrl="https://localhost:53713/api/"

class NetworkService {
  addClient(params) {
    let url = `${REACT_APP_GATEWAY_API_URL}/Client`;
    return RequestService.postRequest(url, params);
  }
  getClient(params) {
    let url = `${REACT_APP_GATEWAY_API_URL}/Client`;
    return RequestService.getRequest(url, params);
  }
  clientDetails(params) {
    let url = `${REACT_APP_GATEWAY_API_URL}/ClientPolicy/Graphql`;
    return RequestService.postRequest(url, params);
  }

  addPolicy(params) {
    let url = `${REACT_APP_GATEWAY_API_URL}/Policy`;
    return RequestService.postRequest(url, params);
  }
  getCountries() {
    let url = `${REACT_APP_GATEWAY_API_URL}/GetCountries`;
    return RequestService.getRequest(url);
  }
  Member() {
    let url = `${REACT_APP_GATEWAY_API_URL}/Member`;
    return RequestService.getRequest(url);
  }
  GetGetCoverageTypes() {
    let url = `${REACT_APP_GATEWAY_API_URL}/Static/GetGetCoverageTypes`;
    return RequestService.getRequest(url);
  }
  GetCurrencies() {
    let url = `${REACT_APP_GATEWAY_API_URL}/GetCurrencies`;
    return RequestService.getRequest(url);
  }
  GetLanguages() {
    let url = `${REACT_APP_GATEWAY_API_URL}/GetLanguages`;
    return RequestService.getRequest(url);
  }
  GetPolicyTypes() {
    let url = `${REACT_APP_GATEWAY_API_URL}/GetPolicyTypes`;
    return RequestService.getRequest(url);
  }
  Insurer() {
    let url = `${REACT_APP_GATEWAY_API_URL}/Insurer`;
    return RequestService.getRequest(url);
  }
  ArchiveClients(params) {
    let url = `${REACT_APP_GATEWAY_API_URL}/Client/ArchiveClients`;
    return RequestService.postRequest(url, params);
  }

  CancelPolicy(params) {
    let url = `${REACT_APP_GATEWAY_API_URL}/Policy/CancelPolicy`;
    return RequestService.postRequest(url, params);
  }
  RenewalPolicy(params) {
    let url = `${REACT_APP_GATEWAY_API_URL}/Policy/RenewPolicy`;
    return RequestService.postRequest(url, params);
  }
  RevokeCancellation(params) {
    let url = `${REACT_APP_GATEWAY_API_URL}/Policy/RevokeCancellation`;
    return RequestService.postRequest(url, params);
  }

  clientGraphql(params) {
    let url = `${REACT_APP_GATEWAY_API_URL}/ClientPolicy/Graphql`;
    return RequestService.postRequest(url, params);
  }

  addLocation(params, headers) {
    let url = `${REACT_APP_GATEWAY_API_URL}/Location`;
    return RequestService.postRequest(url, params);
  }
  deleteLocation(params) {
    let url = `${REACT_APP_GATEWAY_API_URL}/Location`;
    return RequestService.deleteRequestWithBody(url, params);
  }
  updateLocation(params) {
    let url = `${REACT_APP_GATEWAY_API_URL}/Location`;
    return RequestService.putRequest(url, params);
  }

  updateClient(params) {
    let url = `${REACT_APP_GATEWAY_API_URL}/Client`;
    return RequestService.putRequest(url, params);
  }
  updatePolicy(params) {
    let url = `${REACT_APP_GATEWAY_API_URL}/Policy`;
    return RequestService.putRequest(url, params);
  }

  createMember(params) {
    let url = `${REACT_APP_GATEWAY_API_URL}/Member`;
    return RequestService.postRequest(url, params);
  }
  updateMemberFirm(params) {
    let url = `${REACT_APP_GATEWAY_API_URL}/Member`;
    return RequestService.putRequest(url , params);
  }
  deleteMemberFirm(params) {
    let url = `${REACT_APP_GATEWAY_API_URL}/Member`;
    return RequestService.deleteRequestWithBody(url, params);
  }

  DeletePolicy(params) {
    let url = `${REACT_APP_GATEWAY_API_URL}/Policy/DeletePolicy`;
    return RequestService.postRequest(url, params);
  }

  documentsForPolicy(params ,  headers) {
    let url = `${REACT_APP_GATEWAY_API_URL}/Document/Graphql`
    return RequestService.postRequestWithHeaders(url , params , headers)
}

  Coverage() {
    let url = `${REACT_APP_GATEWAY_API_URL}/Coverage`;
    return RequestService.getRequest(url);
  }
  getByidCoverage(id) {
    let url = `${REACT_APP_GATEWAY_API_URL}/Coverage/${id}`;
    return RequestService.getRequest(url);
  }
  CoverageData(params) {
    let url = `${REACT_APP_GATEWAY_API_URL}/Coverage`;
    return RequestService.postRequest(url, params);
  }
  updateCoverageData(params) {
    let url = `${REACT_APP_GATEWAY_API_URL}/Coverage`;
    return RequestService.putRequest(url, params);
  }

  getCoverageDetailsData(params) {
    let url = `${REACT_APP_GATEWAY_API_URL}/Coverage/Data?PolicyId=${params}`;
    return RequestService.getRequest(url, params);
  }

  addExclusion(params) {
    
    let url = `${REACT_APP_GATEWAY_API_URL}/PolicyExclusions`;
    return RequestService.postRequest(url , params);
  }
  getPolicyExclusion(params) {
    
    let url = `${REACT_APP_GATEWAY_API_URL}/PolicyExclusions`;
    return RequestService.getRequest(url , params);
  }
  
  contactsgraphql(params) {
    let url = `${REACT_APP_GATEWAY_API_URL}/Contact/Graphql`;
    return RequestService.postRequest(url, params);
  }

  addContacts(params) {
    let url = `${REACT_APP_GATEWAY_API_URL}/Contact`;
    return RequestService.postRequest(url, params);
  }

  contactType(params) {
    let url = `${REACT_APP_GATEWAY_API_URL}/Contact/Type`;
    return RequestService.getRequest(url, params);
  }
  deleteContactData(params , ClientProducingBroker) {
    let url = `${REACT_APP_GATEWAY_API_URL}/Contact/${params}/${ClientProducingBroker}`;
    return RequestService.deleteRequest(url);
  }
  addMulitpleContacts(params) {
    let url = `${REACT_APP_GATEWAY_API_URL}/Contact/Multiple`;
    return RequestService.postRequest(url, params);
  }

  updateContact(params) {
    let url = `${REACT_APP_GATEWAY_API_URL}/Contact`;
    return RequestService.putRequest(url, params);
  }

  deleteMultipleContact(params) {
    // let url = `${REACT_APP_GATEWAY_API_URL}/Contact?${params}`;
    // return RequestService.deleteRequest(url);
    let url = `${REACT_APP_GATEWAY_API_URL}/Contact`;
    return RequestService.deleteRequestWithBody(url , params);
  }

  recentContact(params) {
    let url = `${REACT_APP_GATEWAY_API_URL}/contact/Graphql`
    return RequestService.postRequest(url , params)
}

  getDocumentTypes() {
    let url = `${REACT_APP_GATEWAY_API_URL}/Document/Types`;
    return RequestService.getRequest(url);
  }
  uploadDocument(params , headers) {
    let url = `${REACT_APP_GATEWAY_API_URL}/Document`;
    return RequestService.uploadDocumentRequest(url, params , headers);
  }
  getDocuments(params, headers) {
    let url = `${REACT_APP_GATEWAY_API_URL}/Document/Graphql`;
    return RequestService.postRequestWithHeaders(url, params, headers);
  }

  DeleteDocument(params, category, headers) {
    let url = `${REACT_APP_GATEWAY_API_URL}/Document`;
    return RequestService.deleteRequestWithHeaderAndBody(url, params , headers);
  }

  updateDocuments(params , from , headers) {
    let url = `${REACT_APP_GATEWAY_API_URL}/Document`;
    return RequestService.putRequestWithHeaderAndBody(url , headers , params);
  }

  getUsers(params) {
    let url = `${REACT_APP_GATEWAY_API_URL}/Users`;
    return RequestService.getRequest(url, params);
  }
  getUsersInTasks(params) {
    let url = `${REACT_APP_GATEWAY_API_URL}/Users/Task`;
    return RequestService.getRequest(url, params);
  }
  getUserById(params) {
    let url = `${REACT_APP_GATEWAY_API_URL}/Users/${params.Id}`;
    return RequestService.getRequest(url, params);
  }
  addUser(params) {
    let url = `${REACT_APP_GATEWAY_API_URL}/Users`;
    return RequestService.postRequest(url, params);
  }

  updateUser(params) {
    let url = `${REACT_APP_GATEWAY_API_URL}/Users`;
    return RequestService.putRequest(url, params);
  }
  deleteUser(params) {
    let url = `${REACT_APP_GATEWAY_API_URL}/Users`;
    return RequestService.deleteRequestWithBody(url, params);
  }
  changeFirstLogin(params) {
    let url = `${REACT_APP_GATEWAY_API_URL}/Users/ChangeFirstLogin`;
    return RequestService.patchRequest(url, params);
  }

  getMemberFirmByuserid(params) {
    let url = `${REACT_APP_GATEWAY_API_URL}/Users/MemberFirm/${params?.id}`;
    return RequestService.getRequest(url);
  }
  getUserByFilter(params) {
    let url = `${REACT_APP_GATEWAY_API_URL}/Users/Filter`;
    return RequestService.postRequest(url, params);
  }

  
  taksGraphql(params) {
    let url = `${REACT_APP_GATEWAY_API_URL}/Task/Graphql`;
    return RequestService.postRequest(url, params);
  }
  addTasks(params) {
    let url = `${REACT_APP_GATEWAY_API_URL}/Task`;
    return RequestService.postRequest(url, params);
  }
  addTag(params) {
    let url = `${REACT_APP_GATEWAY_API_URL}/Task/Tag`;
    return RequestService.postRequest(url, params);
  }
  taskCompleted(params) {
    let url = `${REACT_APP_GATEWAY_API_URL}/Task/UpdateIsCompleted`;
    return RequestService.postRequest(url, params);
  }
  updateTasks(params) {
    let url = `${REACT_APP_GATEWAY_API_URL}/Task/UpdateTask`;
    return RequestService.postRequest(url, params);
  }
  deleteTasks(params) {
    let url = `${REACT_APP_GATEWAY_API_URL}/Task/DeleteTask`;
    return RequestService.postRequest(url, params);
  }
  getTasksDetails(params) {
    let url = `${REACT_APP_GATEWAY_API_URL}/Task/GetTaskDetails`;
    return RequestService.postRequest(url, params);
  }
  deleteMultipleTasks(params) {
    let url = `${REACT_APP_GATEWAY_API_URL}/Task/DeleteTasks`;
    return RequestService.postRequest(url, params);
  }
  getMemberFirm(params) {
    let url = `${REACT_APP_GATEWAY_API_URL}/Member/List`;
    return RequestService.getRequest(url);
  }
  getMemberFirmList(params) {
    let url = `${REACT_APP_GATEWAY_API_URL}/ClientPolicy/Graphql`;
    return RequestService.postRequest(url , params);
  }
  getMemberFirmByClientId(params) {
    let url = `${REACT_APP_GATEWAY_API_URL}/Member/List/Client?clientId=${params}`;
    return RequestService.getRequest(url);
  }
  getMemberFirmByMemberFirmName(params) {
    let url = `${REACT_APP_GATEWAY_API_URL}/Member/List/Name?memberName=${params}`;
    return RequestService.getRequest(url);
  }
  getMemberFirmByCountry(params) {
    let url = `${REACT_APP_GATEWAY_API_URL}/Member/List?countryId=${params}`;
    return RequestService.getRequest(url);
  }
  addInsurer(params) {
    let url = `${REACT_APP_GATEWAY_API_URL}/Insurer`;
    return RequestService.postRequest(url , params);
  }
  getTimezones(params) {
    let url = `${REACT_APP_GATEWAY_API_URL}/GetTimeZones`;
    return RequestService.getRequest(url);
  }
  changePassword(params) {
    let url = `${REACT_APP_GATEWAY_API_URL}/users/changePassword`;
    return RequestService.postRequest(url , params);
  }
  //Reports
  getReports(params) {
    let url = `${REACT_APP_GATEWAY_API_URL}/report/Graphql`;
    return RequestService.postRequest(url , params);
  }
  addReport(params) {
    let url = `${REACT_APP_GATEWAY_API_URL}/report`;
    return RequestService.postRequest(url , params);
  }
  deleteReport(params) {
    let url = `${REACT_APP_GATEWAY_API_URL}/report/DeleteReport`;
    return RequestService.postRequest(url , params);
  }
  getTemplate(params) {
    let url = `${REACT_APP_GATEWAY_API_URL}/report/Graphql`;
    return RequestService.postRequest(url , params);
  }
  getGeneratedReport(params) {
    
    let url = `${REACT_APP_GATEWAY_API_URL}/Report/ExcelFile/${params}`;
    return RequestService.getDocumentRequest(url , );
  }
  getGeneratedPDFReport(params) {
    
    let url = `${REACT_APP_GATEWAY_API_URL}/Report/PdfFile/${params}`;
    return RequestService.getDocumentRequest(url , );
  }
  
  


}

export default new NetworkService();
