import { Box, Typography, Grid, Button } from "@mui/material";

const CreateReportGenerate = () => {
    return (
        <Box sx={{height:'calc(100vh - 338px) !important' , position:'relative'}}>
            <Box className="text-center generate-group">
                <Box className="mb-4"><img src="../wwwroot/assets/images/download-report.png" className="img-fluid"></img></Box>
                <Button className="generate-report-btn mb-4">Generate report</Button>
                <Typography className="report-text m-auto">Once generated the report will export to your given download folder. It will then be stored in the table to be accessed another time.</Typography>
            </Box>
        </Box>
    )
}

export default CreateReportGenerate;