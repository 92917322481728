import NetworkService from "../../network/NetworkService";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  successToast,
  errorToast,
  loadingToast,
} from "../../components/common/Toast";

import * as base from "./BaseActions";
import { MEMBER_FIRM_CONST } from "./actionTypes/MemberFirmActionTypes";
import { startLoading, stopLoading } from "./LoaderAction";

export const getMember = (params) => async (dispatch) => {
  dispatch(base.getRequest(MEMBER_FIRM_CONST.GET_MEMBER_FIRM_REQUEST));
  dispatch(startLoading())
  return await NetworkService.getMemberFirm()
    .then((response) => {
      toast.dismiss();
      dispatch(stopLoading())
      
      if (
        response.status === 201 ||
        response.statusText === "OK" ||
        response.ok === true
      ) {
        response.json().then((responseJson) => {
          dispatch(
            base.getSuccess(
              MEMBER_FIRM_CONST.GET_MEMBER_FIRM_SUCCESS,
              responseJson
            )
          );
        });
      } else {
        response.json().then((responseJson) => {
          dispatch(
            base.getFailure(
              MEMBER_FIRM_CONST.GET_MEMBER_FIRM_FAILURE,
              responseJson
            )
          );
        });
      }
    })
    .catch((error) => {
     
    });
};

export const getMemberByid = (params) => async (dispatch) => {
  let payload = {
    query: `
    query Members {
        members(where: { id: { eq: "${params}" } }) {
            nodes {
                id
                memberName
                memberDisplayName
                street1
                street2
                city
                state
                createdBy
                createdOn
                zipcode
                county
                website
                isActive
                country {
                    id
                    country
                    region
                    countryCode
                }
                base64EncodedLogo
            }
        }
    }
    
    
    
    
    `,
  };

  dispatch(base.getRequest(MEMBER_FIRM_CONST.GET_MEMEBR_FIRM_BY_ID_REQUEST));
  return await NetworkService.clientGraphql(payload)
    .then((response) => {
      toast.dismiss();
      if (
        response.status === 201 ||
        response.statusText === "OK" ||
        response.ok === true
      ) {
        response.json().then((responseJson) => {
          dispatch(
            base.getSuccess(
              MEMBER_FIRM_CONST.GET_MEMBER_FIRM_BY_ID_SUCCESS,
              responseJson
            )
          );
        });
      } else {
        response.json().then((responseJson) => {
          dispatch(
            base.getFailure(
              MEMBER_FIRM_CONST.GET_MEMBER_FIRM_BY_ID_FAILURE,
              responseJson
            )
          );
        });
      }
    })
    .catch((error) => {
      
    });
};

export const createMemeber = (params) => async (dispatch) => {
  loadingToast("Creating member firm...");

  dispatch(startLoading())
  dispatch(base.getRequest(MEMBER_FIRM_CONST.CREATE_MEMBER_FIRM_REQUEST));
  return await NetworkService.createMember(params)
    .then((response) => {
      toast.dismiss();
      dispatch(stopLoading())
      if (
        response.status === 201 ||
        response.statusText === "OK" ||
        response.ok === true
      ) {
        response.json().then((responseJson) => {
          dispatch(
            base.getSuccess(
              MEMBER_FIRM_CONST.CREATE_MEMBER_FIRM_SUCCESS,
              "success"
            )
          );
          setTimeout(() => successToast("Member firm added successfully"), 980);
        });
      } else {
        if (!response.ok) {
          if (response.status === 404) {
            setTimeout(
              () =>
                errorToast(
                  "The resource you are trying to update was not found"
                ),
              980
            );
            dispatch(
              base.getFailure(
                MEMBER_FIRM_CONST.CREATE_MEMBER_FIRM_FAILURE,
                "The resource you are trying to update was not found"
              )
            );
          } else if (response.status === 500) {
            // setTimeout(() => errorToast("Please try again later", 980));
            dispatch(
              base.getFailure(
                MEMBER_FIRM_CONST.CREATE_MEMBER_FIRM_FAILURE,
                "Please try again later"
              )
            );
          } else {
            response.json().then((responseJson) => {
              dispatch(
                base.getFailure(
                  MEMBER_FIRM_CONST.CREATE_MEMBER_FIRM_FAILURE,
                  responseJson
                )
              );
              setTimeout(() => errorToast(responseJson), 980);
            });
          }
        }
      }
    })
    .catch((error) => {
      // console.log(error);
      setTimeout(() => errorToast("Failed to create member firm. Please try again later"), 980)
    });
};

export const createMemberFirm = (payload) => ({
  type: MEMBER_FIRM_CONST.CREATE_MEMBER_FIRM_FAILURE,
  payload,
});
export const updateMemeber = (params) => async (dispatch) => {
  loadingToast("Updating member firm...");

  dispatch(startLoading())
  dispatch(base.getRequest(MEMBER_FIRM_CONST.UPDATE_MEMBER_FIRM_REQUEST));
  return await NetworkService.updateMemberFirm(params)
    .then((response) => {
      toast.dismiss();
      dispatch(stopLoading())
      if (
        response.status === 201 ||
        response.statusText === "OK" ||
        response.ok === true
      ) {
        dispatch(
          base.getSuccess(
            MEMBER_FIRM_CONST.UPDATE_MEMBER_FIRM_SUCCESS,
            "success"
          )
        );
        setTimeout(() => successToast("Member firm updated successfully"), 980);
      }
      if (!response.ok) {
        if (response.status === 404) {
          setTimeout(
            () =>
              errorToast("The resource you are trying to update was not found"),
            980
          );
          dispatch(
            base.getFailure(
              MEMBER_FIRM_CONST.UPDATE_MEMBER_FIRM_FAILURE,
              "The resource you are trying to update was not found"
            )
          );
        } else if (response.status === 500) {
          // setTimeout(() => errorToast("Please try again later", 980));
          dispatch(
            base.getFailure(
              MEMBER_FIRM_CONST.UPDATE_MEMBER_FIRM_FAILURE,
              "Please try again later"
            )
          );
        } else {
          response.json().then((responseJson) => {
            dispatch(
              base.getFailure(
                MEMBER_FIRM_CONST.UPDATE_MEMBER_FIRM_FAILURE,
                responseJson
              )
            );
            setTimeout(() => errorToast(responseJson?.errors?.[0]), 980);
          });
        }
      }
    })
    .catch((error) => {
      
    });
};

export const updateMemberFirm = (payload) => ({
  type: MEMBER_FIRM_CONST.UPDATE_MEMBER_FIRM_SUCCESS,
  payload,
});
export const deleteMemeber = (params) => async (dispatch) => {
  loadingToast("Deleting member firm...");

  dispatch(startLoading())
  dispatch(base.getRequest(MEMBER_FIRM_CONST.DELETE_MEMBER_FIRM_REQUEST));
  return await NetworkService.deleteMemberFirm(params)
    .then((response) => {
      toast.dismiss();
      dispatch(stopLoading())

      if (
        response.status === 201 ||
        response.statusText === "OK" ||
        response.ok === true
      ) {
        dispatch(
          base.getSuccess(
            MEMBER_FIRM_CONST.DELETE_MEMBER_FIRM_SUCCESS,
            "success"
          )
        );
        setTimeout(() => successToast("Member firm deleted successfully"), 980);
      } else {
        if (!response.ok) {
          if (response.status === 404) {
            setTimeout(
              () =>
                errorToast(
                  "The resource you are trying to update was not found"
                ),
              980
            );
            dispatch(
              base.getFailure(
                MEMBER_FIRM_CONST.DELETE_MEMBER_FIRM_FAILURE,
                "The resource you are trying to update was not found"
              )
            );
          } else if (response.status === 500) {
            // setTimeout(() => errorToast("Please try again later", 980));
            dispatch(
              base.getFailure(
                MEMBER_FIRM_CONST.DELETE_MEMBER_FIRM_FAILURE,
                "Please try again later"
              )
            );
          } else {
            response.json().then((responseJson) => {
              dispatch(
                base.getFailure(
                  MEMBER_FIRM_CONST.DELETE_MEMBER_FIRM_FAILURE,
                  responseJson
                )
              );
              setTimeout(() => errorToast(responseJson), 980);
            });
          }
        }
      }
    })
    .catch((error) => {
      // console.log(error);
      setTimeout(() => errorToast("Failed to delete member firm. Please try again later"), 980)
    });
};

export const deleteMemberFirm = (payload) => ({
  type: MEMBER_FIRM_CONST.DELETE_MEMBER_FIRM_SUCCESS,
  payload,
});
export const getUserByMemeberid = (params) => async (dispatch) => {
  dispatch(
    base.getRequest(MEMBER_FIRM_CONST.GET_USERS_BY_MEMBER_FIRM_ID_REQUEST)
  );
  dispatch(startLoading())

  return await NetworkService.getMemberFirmByuserid(params)
    .then((response) => {
      dispatch(stopLoading())
      
      if (
        response.status === 201 ||
        response.statusText === "OK" ||
        response.ok === true
      ) {
        response.json().then((responseJson) => {
          dispatch(
            base.getSuccess(
              MEMBER_FIRM_CONST.GET_USERS_BY_MEMBER_FIRM_ID_SUCCESS,
              responseJson
            )
          );
        });
      } else {
        if (!response.ok) {
          if (response.status === 404) {
            setTimeout(
              () =>
                errorToast(
                  "The resource you are trying to update was not found"
                ),
              980
            );
            dispatch(
              base.getFailure(
                MEMBER_FIRM_CONST.GET_USERS_BY_MEMBER_FIRM_ID_FAILURE,
                "The resource you are trying to update was not found"
              )
            );
          } else if (response.status === 500) {
            // setTimeout(() => errorToast("Please try again later", 980));

            dispatch(
              base.getFailure(
                MEMBER_FIRM_CONST.GET_USERS_BY_MEMBER_FIRM_ID_FAILURE,
                "Please try again later"
              )
            );
          } else {
            response.json().then((responseJson) => {
              dispatch(
                base.getFailure(
                  MEMBER_FIRM_CONST.GET_USERS_BY_MEMBER_FIRM_ID_FAILURE,
                  responseJson
                )
              );
            });
          }
        }
      }
    })
    .catch((error) => {
      
    });
};

export const memberusers = (payload) => ({
  type: MEMBER_FIRM_CONST.GET_USERS_BY_MEMBER_FIRM_ID_SUCCESS,
  payload,
});

export const filterMemeberByCountry = (params) => async (dispatch) => {
  let payload = {
    query: `
    query Members {
      members(where: { country: { id: { eq: ${params} } } }) {
          nodes {
              id
              memberName
              memberDisplayName
              street1
              street2
              city
              state
              zipcode
              county
              website
              base64EncodedLogo
              isActive
              adminCount
              contributorCount
              clientCount
              country {
                  id
                  country
                  region
                  countryCode
              }
          }
      }
  }
  
    
    `,
  };

  dispatch(base.getRequest(MEMBER_FIRM_CONST.GET_MEMBER_FIRM_REQUEST));
  return await NetworkService.clientGraphql(payload)
    .then((response) => {
      toast.dismiss();
      if (
        response.status === 201 ||
        response.statusText === "OK" ||
        response.ok === true
      ) {
        response.json().then((responseJson) => {
          dispatch(
            base.getSuccess(
              MEMBER_FIRM_CONST.GET_MEMBER_FIRM_SUCCESS,
              responseJson
            )
          );
        });
      } else {
        response.json().then((responseJson) => {
          dispatch(
            base.getFailure(
              MEMBER_FIRM_CONST.GET_MEMBER_FIRM_FAILURE,
              responseJson
            )
          );
        });
      }
    })
    .catch((error) => {
      
    });
};
export const filterMemeberByid = (params) => async (dispatch) => {
  let payload = {
    query: `
    query Members {
      members(where: { id: { eq: "${params}" } }) {
          nodes {
              id
              memberName
              memberDisplayName
              street1
              street2
              city
              state
              zipcode
              county
              website
              base64EncodedLogo
              isActive
              adminCount
              contributorCount
              clientCount
          }
      }
  }
  
  
    
    `,
  };

  dispatch(base.getRequest(MEMBER_FIRM_CONST.GET_MEMBER_FIRM_REQUEST));
  return await NetworkService.clientGraphql(payload)
    .then((response) => {
      toast.dismiss();
      if (
        response.status === 201 ||
        response.statusText === "OK" ||
        response.ok === true
      ) {
        response.json().then((responseJson) => {
          dispatch(
            base.getSuccess(
              MEMBER_FIRM_CONST.GET_MEMBER_FIRM_SUCCESS,
              responseJson
            )
          );
        });
      } else {
        response.json().then((responseJson) => {
          dispatch(
            base.getFailure(
              MEMBER_FIRM_CONST.GET_MEMBER_FIRM_FAILURE,
              responseJson
            )
          );
        });
      }
    })
    .catch((error) => {
      
      
    });
};
export const filterMemeberByClient = (params) => async (dispatch) => {
  let payload = {
    query: `
    query Members {
      members(where: { country: { id: { eq: ${params} } } }) {
          nodes {
              id
              memberName
              memberDisplayName
              street1
              street2
              city
              state
              zipcode
              county
              website
              base64EncodedLogo
              isActive
              adminCount
              contributorCount
              clientCount
              country {
                  id
                  country
                  region
                  countryCode
              }
          }
      }
  }
  
    
    `,
  };

  dispatch(base.getRequest(MEMBER_FIRM_CONST.GET_MEMBER_FIRM_REQUEST));
  return await NetworkService.clientGraphql(payload)
    .then((response) => {
      toast.dismiss();
      if (
        response.status === 201 ||
        response.statusText === "OK" ||
        response.ok === true
      ) {
        response.json().then((responseJson) => {
          dispatch(
            base.getSuccess(
              MEMBER_FIRM_CONST.GET_MEMBER_FIRM_SUCCESS,
              responseJson
            )
          );
        });
      } else {
        response.json().then((responseJson) => {
          dispatch(
            base.getFailure(
              MEMBER_FIRM_CONST.GET_MEMBER_FIRM_FAILURE,
              responseJson
            )
          );
        });
      }
    })
    .catch((error) => {
      
    });
};
export const searchMemeberFirm = (params) => async (dispatch) => {
  dispatch(base.getRequest(MEMBER_FIRM_CONST.GET_MEMBER_FIRM_BY_NAME_REQUEST));
  return await NetworkService.getMemberFirmByMemberFirmName(params)
    .then((response) => {
      toast.dismiss();
      if (
        response.status === 201 ||
        response.statusText === "OK" ||
        response.ok === true
      ) {
        response.json().then((responseJson) => {
          dispatch(
            base.getSuccess(
              MEMBER_FIRM_CONST.GET_MEMBER_FIRM_BY_NAME_SUCCESS,
              responseJson
            )
          );
        });
      } else {
        dispatch(
          base.getFailure(
            MEMBER_FIRM_CONST.GET_MEMBER_FIRM_BY_NAME_FAILURE,
            "error"
          )
        );
      }
    })
    .catch((error) => {
      
    });
};

export const getMemberFirmByName = (payload) => ({
  type: MEMBER_FIRM_CONST.GET_MEMBER_FIRM_BY_NAME_SUCCESS,
  payload,
});
export const gethMemeberFirmByClient = (params) => async (dispatch) => {
  dispatch(
    base.getRequest(MEMBER_FIRM_CONST.GET_MEMBER_FIRM_BY_CLIENTS_REQUEST)
  );
  dispatch(startLoading())

  return await NetworkService.getMemberFirmByClientId(params)
    .then((response) => {
      toast.dismiss();
      dispatch(stopLoading())

      if (
        response.status === 201 ||
        response.statusText === "OK" ||
        response.ok === true
      ) {
        response.json().then((responseJson) => {
          dispatch(
            base.getSuccess(
              MEMBER_FIRM_CONST.GET_MEMBER_FIRM_BY_CLIENTS_SUCCESS,
              responseJson
            )
          );
        });
      } else {
        if (!response.ok) {
          if (response.status === 404) {
            setTimeout(
              () =>
                errorToast(
                  "The resource you are trying to update was not found"
                ),
              980
            );

            dispatch(
              base.getFailure(
                MEMBER_FIRM_CONST.GET_MEMBER_FIRM_BY_CLIENTS_FAILURE,
                "The resource you are trying to update was not found"
              )
            );
          } else if (response.status === 500) {
            // setTimeout(() => errorToast("Please try again later", 980));

            dispatch(
              base.getFailure(
                MEMBER_FIRM_CONST.GET_MEMBER_FIRM_BY_CLIENTS_FAILURE,
                "Please try again later"
              )
            );
          } else {
            dispatch(
              base.getFailure(
                MEMBER_FIRM_CONST.GET_MEMBER_FIRM_BY_CLIENTS_FAILURE,
                "error"
              )
            );
          }
        }
      }
    })
    .catch((error) => {
      
    });
};

export const getMemberFirmByClient = (payload) => ({
  type: MEMBER_FIRM_CONST.GET_MEMBER_FIRM_BY_CLIENTS_SUCCESS,
  payload,
});

export const getMemberByCountry = (params) => async (dispatch) => {
  dispatch(base.getRequest(MEMBER_FIRM_CONST.GET_MEMBER_FIRM_REQUEST));
  dispatch(startLoading())

  return await NetworkService.getMemberFirmByCountry(params)
    .then((response) => {
      toast.dismiss();
      dispatch(stopLoading())
      
      if (
        response.status === 201 ||
        response.statusText === "OK" ||
        response.ok === true
      ) {
        response.json().then((responseJson) => {
          dispatch(
            base.getSuccess(
              MEMBER_FIRM_CONST.GET_MEMBER_FIRM_SUCCESS,
              responseJson
            )
          );
        });
      } else {
        if (!response.ok) {
          if (response.status === 404) {
            setTimeout(
              () =>
                errorToast(
                  "The resource you are trying to update was not found"
                ),
              980
            );

            dispatch(
              base.getFailure(
                MEMBER_FIRM_CONST.GET_MEMBER_FIRM_FAILURE,
                "The resource you are trying to update was not found"
              )
            );
          } else if (response.status === 500) {
            // setTimeout(() => errorToast("Please try again later", 980));

            dispatch(
              base.getFailure(
                MEMBER_FIRM_CONST.GET_MEMBER_FIRM_FAILURE,
                "Please try again later"
              )
            );
          } else {
            response.json().then((responseJson) => {
              dispatch(
                base.getFailure(
                  MEMBER_FIRM_CONST.GET_MEMBER_FIRM_FAILURE,
                  responseJson
                )
              );
            });
          }
        }
      }
    })
    .catch((error) => {
      
    });
};
export const getSortedMembers = () => async (dispatch) => {
  let payload = {
    query: `
    query Members {
      members(first: 300  order: { memberName: ASC }) {
          nodes {
              id
              memberName
              memberDisplayName
              street1
              street2
              city
              state
              zipcode
              county
              website
              base64EncodedLogo
              isActive
              adminCount
              contributorCount
              clientCount
          }
      }
  }
  
    `,
  };

  dispatch(base.getRequest(MEMBER_FIRM_CONST.GET_SORTED_MEMBER_FIRM_REQUEST));
  return await NetworkService.clientGraphql(payload)
    .then((response) => {
      toast.dismiss();
      if (
        response.status === 201 ||
        response.statusText === "OK" ||
        response.ok === true
      ) {
        response.json().then((responseJson) => {
          dispatch(
            base.getSuccess(
              MEMBER_FIRM_CONST.GET_SORTED_MEMBER_FIRM_SUCCESS,
              responseJson
            )
          );
        });
      } else {
        response.json().then((responseJson) => {
          dispatch(
            base.getFailure(
              MEMBER_FIRM_CONST.GET_SORTED_MEMBER_FIRM_FAILURE,
              responseJson
            )
          );
        });
      }
    })
    .catch((error) => {
      
    });
};

export const getSortedMemberFirm = (payload) => ({
  type: MEMBER_FIRM_CONST.GET_SORTED_MEMBER_FIRM_SUCCESS,
  payload,
});

export const getMemberFirmsList = (params) => async (dispatch) => {
  dispatch(base.getRequest(MEMBER_FIRM_CONST.GET_MEMBER_FIRM_REQUEST));
  dispatch(startLoading())

  let payload = {
    query:`

    query Members {
      members (first: 300) {
          nodes {
              id
              memberName
              memberDisplayName
              street1
              street2
              city
              state
              zipcode
              website
              base64EncodedLogo
              isActive
              adminCount
              contributorCount
              clientCount
              createdOn
              createdBy
              logoFileExtension
              country {
                  id
                  country
                  region
                  countryCode
              }
          }
      }
  }

  
    `
  }
  return await NetworkService.getMemberFirmList(payload)
    .then((response) => {
      toast.dismiss();
      dispatch(stopLoading())
      
      if (
        response.status === 201 ||
        response.statusText === "OK" ||
        response.ok === true
      ) {
        response.json().then((responseJson) => {
          dispatch(
            base.getSuccess(
              MEMBER_FIRM_CONST.GET_MEMBER_FIRM_SUCCESS,
              responseJson
            )
          );
        });
      } else {
        response.json().then((responseJson) => {
          dispatch(
            base.getFailure(
              MEMBER_FIRM_CONST.GET_MEMBER_FIRM_FAILURE,
              responseJson
            )
          );
        });
      }
    })
    .catch((error) => {
     
    });
};
export const filterMemberFirmsList = (params) => async (dispatch) => {
  dispatch(base.getRequest(MEMBER_FIRM_CONST.GET_MEMBER_FIRM_REQUEST));
  dispatch(startLoading());

  const { clientId, memberName, countryId } = params;

  
  let clientQuery = clientId ? `clientId: "${clientId}"` : '';
  let memberQuery = memberName ? `memberName: "${memberName}"` : '';
  let countryQuery = countryId ? `countryId: ${countryId}` : '';

  let payload = {
    query: `
      query Members {
        members(
          ${clientQuery}
         ${memberQuery}
           ${countryQuery}
          first: 300
        ) {
          nodes {
            id
            memberName
            memberDisplayName
            street1
            street2
            city
            state
            zipcode
            website
            base64EncodedLogo
            isActive
            adminCount
            contributorCount
            clientCount
            createdOn
            createdBy
            logoFileExtension
            country {
              id
              country
              region
              countryCode
            }
          }
        }
      }
    `
  };

  try {
    const response = await NetworkService.getMemberFirmList(payload);
    toast.dismiss();
    dispatch(stopLoading());

    if (response.ok) {
      const responseJson = await response.json();
      dispatch(
        base.getSuccess(
          MEMBER_FIRM_CONST.GET_MEMBER_FIRM_SUCCESS,
          responseJson
        )
      );
    } else {
      const responseJson = await response.json();
      dispatch(
        base.getFailure(
          MEMBER_FIRM_CONST.GET_MEMBER_FIRM_FAILURE,
          responseJson
        )
      );
    }
  } catch (error) {
    console.error("Error fetching member firms:", error);
    // Handle error as needed
  }
};

