import React, { useEffect, useRef, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { Box, Typography } from "@mui/material";

import NavTabs from "../../components/NavTabs";

import {
  checkDocumentSize,
  countriesOptions,
  
  
  dateFormat,
  dropDownOptions,
  getBase64,
  
  
  validateInput,
  
  
  limitDecimalValue,
  limitPercentageValue,
  loggedInUserDetails,
  mapAndConvertObject,
  
  
  validateDocument,
} from "../../constants/index";

import { addDocuments, updateDocuemntsData } from "../../store/actions/DocumentsAction";
import PIIConfirmationModal from "../../components/common/PIIConfirmationModal";

import {
  AddNewPolicy,
  GetGetCoverageTypes,
  getMember,
  GetLanguages,
  GetPolicyTypes,
  Insurer,
  GetCurrencies,
  GetGetCoverageTypesById,
  addFieldData,
  getCoverageDetailsData,
  updatePolicy,
  updateFieldData,
  getclientMember,
  setCoverageDetailsData,
  addInsurerData,
  setaddInsurerData,
  setCoverageData,
  deleteDocument,
} from "../../store/actions/ClientAction";
import { getCountries } from "../../store/actions/CountriesAction";

import Tab1 from "./addPolicy/Tab1";
import Tab2 from "./addPolicy/Tab2";
import Tab3 from "./addPolicy/Tab3";
import Tab4 from "./addPolicy/Tab4";
import Tab5 from "./addPolicy/Tab5";
import TopSection from "./addPolicy/TopSection";
import Button from "../../components/Button";
import { errorToast } from "../../components/common/Toast";

const CreateNewPolicy = (props) => {
  const [policyNumber, setPolicyNumber] = useState("");

  const [nameInsured, setNameInsured] = useState("");
  const [comment, setComment] = useState("");
  const [selectedgCoverage, setselectedgCoverage] = useState("");
  const [coverageTypes, setCoverageTypes] = useState([]);
  const [selectedPolicyCountry, setPolicyCountry] = useState("");
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [language, setLanguage] = useState([]);
  const [selectedPolicyType, setSelectedPolicyType] = useState("");
  const [policyType, setPolicyType] = useState([]);
  const [selectedExclusion, setExclusion] = useState("");
  const [selectedCurrency, setCurrency] = useState("");
  const [currencies, setcurrencies] = useState([]);
  const [selectedPaymentFrequency, setPaymentFrequency] = useState("");
  const [selectedCommisionPaymentFrequency, setCommisionPaymentFrequency] =
    useState("");
  const [locallyAdmittedCoverage, setLocallyAdmittedCoverage] = useState(false);
  const [renewalReminder, setRenewalReminder] = useState(false);
  const [evergreenRenewal, setEverGreenRenewal] = useState(false);
  const [selectedExpirationDate, setExpirationDate] = useState("");
  const [tab, setTab] = useState(1);
  const [countries, setCountries] = useState([]);
  const [selectedInspectionDate, setInspectionDate] = useState("");
  const [taxes, setTaxes] = useState("");
  const [charges, setCharges] = useState("");
  const [fieldData, setFieldData] = useState("");
  const [commisionRate, setCommisionRate] = useState("");
  const [totalEstimatedAnnualPremium, setTotalEstimatedAnnualPremium] =
    useState("");

  const [formData, setFormData] = useState([]);
  const [coverageFields, setCoverageFields] = useState([]);

  const [brokerdats, setbrokerdata] = useState([]);
  const [brokerId, setbrokerid] = useState([]);
  const [exclusion, setExclusiontext] = useState("");
  const [premiumPaid, setPremiumPaid] = useState("");
  const [members, setMembersData] = useState([]);

  const [selectedValue, setSelectedValue] = useState(null);
  const [addedInsurer, setaddedInsurer] = useState(null);
  const [numberlimit, setnumberlimit] = useState(true);
  const [filetype, setFileType] = useState("");
  const [showprogress, setShowProgress] = useState("");
  const [base64, setBase64] = useState("");
  const [progressbarpercentage, setProgressPercentage] = useState(0);
  const [fileError, setFileError] = useState("");
  const [document, setDocument] = useState([]);
  const [selecteddocumentType, setSelectedDocumentType] = useState("");
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [confirmupload, setconfirmupload] = useState(false);
  const [documentTypes, setDocumentTypes] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [inceptionStartDate, setInceptionStartDate] = useState("");

  const [selectedgCoverage1, setselectedgCoverage1] = useState("");
  
  const [documentsDetailsdata, setDocumentsDetailsData] = useState([]);
  const [updateDocumentVisibility, setupdateDocumentVisibility] = useState([]);
  const [updateVisibility, setupdatevisibility] = useState([]);
  const [deletedDocumentData, setdeletedDocumentData] = useState([]);
  const [deletedServicingbrokerData, setDeletedServicingbrokers] = useState([]);
  const [accessgroupid, setaccessgroupid] = useState(
    props.clientid ? props.clientid : ""
  );
  const [visibility, setvisibility] = useState("Client");
  const [isInsure, setInsure] = useState([]);

  const [errors, setError] = useState({
    servicingbroker: "",
    selectedgCoverage: "",
    policynumber: "",
    country: "",
    language: "",
    policytype: "",
    insurer: "",
    nameinsure: "",
    inceptionDate: "",
    expirationDate: "",
    currency: "",
  });

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [filename, setfilename] = useState("");
  const [brokerforpolicy, setBrokerForPolicy] = useState("");
  const [filesize, setfilesize] = useState("");
  const [producingBrokerName, setProducingBrokerName] = useState("");
  const [exclusionOption, setexclusionOption] = useState([{label:"Select" , value:""} , {label:"Other" , value:"Other"}]);
  const [premiumpaymentfrequencyOption, setpremiumpaymentfrequencyOption] = useState([{label:"Select" , value:""} ,{label:"Annual" , value:"1"} , {label:"Quarterly" , value:"2"} , {label:"Monthly" , value:"3"}])
  const [producingBrokerCountry, setProducingBrokerCountry] = useState("");

  //extract specific pieces of state from the Redux store
  const stateData = useSelector((state) => {
    return {
      currencies: state?.fetchData?.currenciesData,
      membersadata: state?.fetchData?.memberData,
      countries: state?.countries?.Countries,
      language: state?.fetchData?.laguagesData?.data,
      policyData: state?.fetchData?.policyTypesData?.data,
      coverageTypes: state?.fetchData?.coverageTypes,
      coverageData: state?.fetchData?.coverageTypeById,
      insurer: state?.fetchData?.insureData,
      addpolicyData: state?.fetchData?.addpolicyData,
      documentTypes: state?.documents?.documentsTypes,
      coverageDetailsData: state?.fetchData?.coverageDetailsData,
      updatedPolicyData: state?.fetchData?.updatedPolicyData,
      brokerforpolicyData: state?.fetchData?.brokerforpolicyData,
      documentsForPolicyData:state?.fetchData?.documentsForPolicyData,
      clientMemberData: state?.fetchData?.clientMemberData,
      addInsurerData: state?.fetchData?.addInsurerData,
      

    };
  });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(GetGetCoverageTypes());
    if (!stateData?.countries) {
      dispatch(getCountries());
    }
    // if (!stateData?.membersadata) {
      // dispatch(getMember());
    // }

    if (!stateData?.language) {
      dispatch(GetLanguages());
    }

    dispatch(GetPolicyTypes());
    dispatch(Insurer());

    if (!stateData?.currencies) {
      dispatch(GetCurrencies());
    }
    
    setMembersData([])
   let ClientId =
          props.from === "listing"
            ? props.clientDetail[0]?.id
            : props.clientDetail?.id
            ? props.clientDetail?.id
            : ""
            // let isProducingBroker = ""
            
            
    dispatch(getclientMember(ClientId))
    
  }, []);

  useEffect(()=> {

    return () => {
      dispatch(setCoverageDetailsData(""))
      dispatch(setCoverageData(""))
    }
  } , [])
  
  useEffect(()=> {
  if(stateData?.clientMemberData) {
    setMembersData(stateData?.clientMemberData?.data?.brokersForClient?.nodes?.filter((item , key)=> item?.brokerType!=="Producing"))
    // stateData.brokerforpolicyData?.data?.brokersForPolicy
  }
  } , [stateData?.clientMemberData])
  //update currencies
  useEffect(() => {
    if (stateData.currencies) {
      setcurrencies(stateData.currencies);
    }
  }, [stateData.currencies]);

  //update languages
  useEffect(() => {
    if (stateData.language) {
      setLanguage(stateData.language);
    }
  }, [stateData.language]);
  useEffect(() => {
    if (stateData.addInsurerData?.data?.id) {
      dispatch(Insurer())
      
      setaddedInsurer(stateData?.addInsurerData?.data?.id)
      
      dispatch(setaddInsurerData(""))
    }
  }, [stateData.addInsurerData?.data?.id]);

  useEffect(()=> {
if(addedInsurer && stateData.insurer?.data) {
  
 let insurer =  dropDownOptions(stateData.insurer?.data , "id" , "code")?.find((item , key)=> item.value === addedInsurer)
 setSelectedValue(insurer)
}
  } , [addedInsurer , stateData.insurer?.data])

  
  //update policy types
  useEffect(() => {
    if (stateData.policyData) {
      setPolicyType(stateData.policyData);
    }
  }, [stateData.policyData]);

  //update coverage types
  useEffect(() => {
    if (stateData.coverageTypes) {
      setCoverageTypes(stateData.coverageTypes);
    }
  }, [stateData.coverageTypes]);

  //update countries
  useEffect(() => {
    if (stateData.countries) {
      setCountries(stateData.countries);
    }
  }, [stateData.countries]);

  //update memebers
  useEffect(() => {
    if (stateData.membersadata) {
      // setMembersData(stateData.membersadata);
      let producingBrokerId = props.from === "listing"
      ? props.clientDetail[0]?.producingBroker?.id
      : props.clientDetail?.producingBrokerId
      ? props.clientDetail?.producingBrokerId:""
      let name = stateData?.membersadata?.find((item , key)=> item.id === producingBrokerId)
    
    setProducingBrokerName(name?.memberName)
    setProducingBrokerCountry(name?.country?.country)
    }
  }, [stateData.membersadata]);

  //update insurer
  useEffect(() => {
    if (stateData.insurer?.data) {
      setInsure(stateData.insurer?.data);
    }
  }, [stateData.insurer]);

  //update coverage fields
  useEffect(() => {
    if (stateData.coverageData?.coverageFields) {
      setCoverageFields(stateData.coverageData?.coverageFields);
    }
    
  }, [stateData.coverageData]);
  // useEffect(() => {
  //   if (stateData.coverageDetailsData) {
  //     // setCoverageFields(stateData.coverageDetailsData?.coverageFields);
  //     stateData.coverageDetailsData?.coverageFields?.forEach((field) => {
  //       formData[field.coverageFieldId] = field.value;
  
        
  //     });
  //     // setCoverageFields(stateData.coverageDetailsData?.coverageFields)
  //   }
  // }, [stateData.coverageDetailsData]);

  useEffect(() => {
    if (stateData.coverageDetailsData) {
      const updatedFormData = { ...formData }; // Create a copy of formData
  
      stateData.coverageDetailsData.coverageFields.forEach((field) => {
        updatedFormData[field.coverageFieldId] = field.value; // Update the field value in formData
        updatedFormData[`${field.coverageFieldId}code`] = field.fieldOptions?.length>0 ? getCodeFromFieldOptions(field.fieldOptions, field.value) : field.code; // Update the corresponding code in formData
      });
  
      // Update the state with the new formData
      setFormData(updatedFormData);
    }
  }, [stateData.coverageDetailsData])
  
  //update document types
  useEffect(() => {
    if (stateData.documentTypes) {
      setDocumentTypes(stateData?.documentTypes);
    }
  }, [stateData.documentTypes]);

  useEffect(() => {
    const { policyDetails, page } = props;

    const initializePolicyDetails = () => {
      if (
        page === "edit" &&
        policyDetails &&
        
        countries &&
        language &&
        currencies
      ) {
        // setCoverageFields([])
        setPolicyNumber(policyDetails.policyNumber);

        const selectedCountryOption = countriesOptions(countries)?.find(
          (item) => item.value === policyDetails.country?.id
        );
        const selectedLanguageOption = dropDownOptions(language , "id" , "language")?.find(
          (item) => item.value === policyDetails.language?.id
        );
        const selectedPolicyTypeOption = dropDownOptions(policyType , "id" , "policyType")?.find(
          (item) => item.value === policyDetails.policyType?.id
        );
        const selectedInsurerOption = dropDownOptions(isInsure , "id" , "code")?.find(
          (item) => item.value === policyDetails.insurer?.id
        );
        const selectedCurrencyOption = dropDownOptions(currencies , "id" , "currency")?.find(
          (item) => item.value === policyDetails.currency?.id
        );
        const selectedCoverageTypeOption = dropDownOptions(coverageTypes , "id" , "name")?.find((item) => item.value === policyDetails.coverageTypeId);

        const selectedexclusionOption = exclusionOption?.find((item) => item.value === policyDetails.exclusions);
        const selectedpremiumpaymentfrequencyOption = premiumpaymentfrequencyOption?.find((item) => item.value == policyDetails.policyPeriodDetail?.premiumPaymentFrequency);
        const selectedcommistionfrequencyOption = premiumpaymentfrequencyOption?.find((item) => item.value == policyDetails.policyPeriodDetail?.commissionPaymentFrequency);

        setPolicyCountry(selectedCountryOption);
        setSelectedLanguage(selectedLanguageOption);
        setLocallyAdmittedCoverage(policyDetails.isLocallyAdmittedCoverage);
        setselectedgCoverage(selectedCoverageTypeOption);
        dispatch(getCoverageDetailsData(policyDetails.id));
        setSelectedPolicyType(selectedPolicyTypeOption);
        setSelectedValue(selectedInsurerOption);
        setCurrency(selectedCurrencyOption);
        setEverGreenRenewal(policyDetails.isAutoRenewal);
        setNameInsured(policyDetails.namedInsured);

        if(props.renewPolicy === true) {
          setInceptionStartDate("")
          setExpirationDate("")
          setEndDate("")
          setStartDate("")
        }
        else {
        let date = dateFormat(policyDetails.policyPeriodDetail?.inceptionDate);
        handleInspectionDate(date);
        let expirationdate = dateFormat(
          policyDetails.policyPeriodDetail?.expirationDate
        );

        handleExpirationDate(expirationdate);
      }

        // setExpirationDate(policyDetails.policyPeriodDetail?.expirationDate);
        setRenewalReminder(
          policyDetails.policyPeriodDetail?.isRenewReminderSet
        );
        // setExclusion(policyDetails.exclusions);
        setExclusion(selectedexclusionOption);
        setTotalEstimatedAnnualPremium(
          policyDetails.policyPeriodDetail?.totalPremium
        );
        setPaymentFrequency(
          // policyDetails.policyPeriodDetail?.premiumPaymentFrequency
          selectedpremiumpaymentfrequencyOption
        );
        setCommisionRate(policyDetails.policyPeriodDetail?.commissionRate);
        setCommisionPaymentFrequency(
          // policyDetails.policyPeriodDetail?.commissionPaymentFrequency
          selectedcommistionfrequencyOption
        );
        setCharges(policyDetails.policyPeriodDetail?.charges);
        setTaxes(policyDetails.policyPeriodDetail?.taxes);
        setComment(policyDetails.comments);
        setExclusiontext(policyDetails?.exclusions);
        

        // if (policyDetails.servicingBroker?.id) {
        //   const brokerData = [
        //     {
        //       id: policyDetails.servicingBroker.id,
        //       memberName: policyDetails.servicingBroker.memberName,
        //     },
        //   ];
        //   setbrokerDetailsdata(brokerData);
        //   setbrokerDetailsid([policyDetails.servicingBroker?.id]);
        // }
      }
    };

    initializePolicyDetails();
  }, [
    props.policyDetails,
    props.page,
    
    countries,
    language,
    currencies,
    props.brokerforpolicy,
  ]);
  useEffect(() => {
    if (stateData.brokerforpolicyData && stateData?.clientMemberData && props.page ==="edit") {
      setBrokerForPolicy(stateData.brokerforpolicyData?.data?.brokersForPolicy?.nodes?.filter((item , key)=> item?.brokerType !== "Producing"));
      // const filteredArray2 = stateData?.membersadata?.filter(
      //   (obj2) =>
      //     !stateData.brokerforpolicyData?.data?.brokersForPolicy?.some(
      //       (obj1) => obj1.id === obj2.id
      //     )
      // );
      
      const filteredArray2 = stateData?.clientMemberData?.
      data?.brokersForClient?.nodes?.filter(
        (obj2) =>
          !stateData.brokerforpolicyData?.data?.brokersForPolicy?.nodes?.some(
            (obj1) => obj1.id === obj2.id
          )
      )?.filter((item , key)=> item?.brokerType !== "Producing");
      
      setMembersData(filteredArray2);
      
      setbrokerdata(stateData.brokerforpolicyData?.data?.brokersForPolicy?.nodes?.filter((item , key)=> item?.brokerType!=="Producing"))
      setbrokerid(stateData.brokerforpolicyData?.data?.brokersForPolicy?.nodes?.filter((item , key)=> item?.brokerType!=="Producing")?.map((item1 , key)=> item1?.id))
      // setMembersData(filteredArray2)
    }
  }, [stateData.brokerforpolicyData?.data?.brokersForPolicy , props.page , stateData?.clientMemberData]);
useEffect(()=> {
if(stateData?.documentsForPolicyData && props.page === "edit") {
  // setDocumentsDetailsData(stateData?.documentsForPolicyData)

  let data = stateData?.documentsForPolicyData?.data.documentsByPolicy.nodes?.map((item, key) => {
    return {
      ...item,
      id: item.documentId,
    };
  });

  setDocumentsDetailsData(data)

}
} , [stateData?.documentsForPolicyData , props.page])

  useEffect(() => {
    if (stateData.addpolicyData || stateData.updatedPolicyData) {
      // props.handleCloseNewPolicyModal();
      const coverageName = coverageTypes?.filter(
        (item, key) => item.coverageTypeId === selectedgCoverage?.value
      );
      const coverageTypeId = selectedgCoverage?.value
        ? selectedgCoverage?.value
        : "";
      const CoverageTypeName = coverageName?.[0]?.name;
      let policyId = props.page === "edit" ? props.policyDetails?.id :stateData?.addpolicyData?.data?.id;
      const value = mapAndConvertObject(
        formData,
        coverageTypeId,
        policyId,
        CoverageTypeName
      );
      if (selectedFiles?.[0]) {
        let payload = {
          documentTypeId: selecteddocumentType?.value,
          documentDisplayName: filename
            ? `${filename?.split(".").pop()}.${filetype}`
            : "",
          description: "",
          fileBase64: base64,
          fileFormat: filetype ? filetype : "",
          category: "Policy",
          categoryId: policyId,
          accessGroup: visibility ? visibility : "",
          accessGroupId: accessgroupid ? accessgroupid : "",
        };

      let fileName = filename?.split(".");
      fileName.pop();
      let filenameWithoutExtension = fileName.join(".")

        const formData = new FormData()
      formData.append("file" , selectedFiles?.[0])
      formData.append("documentTypeId" , selecteddocumentType?.value)
      formData.append("fileFormat" , filetype ? filetype : "")
      formData.append("documentDisplayName" , filename
        ? `${filenameWithoutExtension}.${filetype}`
        : "")
      formData.append("category" , "Policy")
      formData.append("categoryId" , policyId)
      formData.append("accessGroup" , visibility ? visibility : "")
      formData.append("accessGroupId" , accessgroupid ? accessgroupid : "")
      formData.append("description" , "")

     let producingBrokerId = props.from === "listing"
      ? props.clientDetail[0]?.producingBroker?.id
      : props.clientDetail?.producingBrokerId
      ? props.clientDetail?.producingBrokerId:""

      let headers = {
        userId: JSON.parse(localStorage.getItem("user"))?.sub,
        ProducingFirmId: producingBrokerId
      }
        
        dispatch(addDocuments(formData , headers));
      }
      if (props.page === "edit") {
      dispatch(updateFieldData(value))
      let updatedocuments =
       updateDocumentVisibility.filter(doc => !deletedDocumentData.includes(doc.documentId))
       
      if(updatedocuments?.length>0) {

        let user = JSON.parse(localStorage.getItem("user"));
        let clientid = props.from === "listing"
        ? props.clientDetail[0]?.id
        : props.clientDetail?.id
        ? props.clientDetail?.id:""

        let payload = {
          ClientId: clientid,
    
          UserId: user?.sub,
          ProducingFirmId: user?.extension_MemberFirmId,
        }

        let documentPayload = {
          category:"Policy",
          categoryId:props.policyDetails?.id,
          documentDetails:updatedocuments
        }
        dispatch(updateDocuemntsData(documentPayload , "Policy" , payload))
      }
      
      if(deletedDocumentData?.length>0) {
        let user = JSON.parse(localStorage.getItem("user"));
        let clientid = props.from === "listing"
        ? props.clientDetail[0]?.id
        : props.clientDetail?.id
        ? props.clientDetail?.id:""
    let payload = {
      ClientId: clientid,

      UserId: user?.sub,
      ProducingFirmId: user?.extension_MemberFirmId,
    };
      
        let documentsPayload = {
          documentIds: deletedDocumentData,
          category: "Policy",
          categoryId: props.policyDetails?.id
        }

        dispatch(deleteDocument(documentsPayload , "Policy" , payload , "edit"))
        
      }
      }
      else {
        
        if(value?.fieldsWithData?.length === 0) {
          value?.fieldsWithData.push({
            "coverageFieldId": coverageFields?.[0]?.coverageFieldId,
            "value": null
          })
        }

      dispatch(addFieldData(value));
      }

    }
  }, [stateData.addpolicyData , stateData.updatedPolicyData]);


  /***********************handle dynamic coverage field data********************************* */
  // const handleInputChange = (fieldId, value) => {
  //   setFormData({ ...formData, [fieldId]: value });
  // };

  const handleFormData = (coverageFieldId, value) => {
    setFieldData({
      ...fieldData,
      [coverageFieldId]: value,
    });
  };

  //update dynamic coverage fields data
  const handleInputChange = (fieldId, value, fieldName, code, fieldOptions) => {
    
    handleFormData(fieldId, value);
    setFormData((prevFormData) => {
      const updatedFormData = { ...prevFormData };
      if (typeof value === "boolean") {
        updatedFormData[fieldId] = value.toString();
        value.toString() === "true"
          ? (updatedFormData[`${fieldId}code`] = code)
          : delete updatedFormData[`${fieldId}code`];
      } else if (Array.isArray(value)) {
        if (value.length === 0) {
          let fieldcode = getCodeFromFieldOptions(fieldOptions, value);

          delete updatedFormData[fieldId];
          // delete updatedFormData[`${fieldId}code`];
        } else {
          let fieldcode = getCodeFromFieldOptions(fieldOptions, value);

          updatedFormData[fieldId] = value;
          // updatedFormData[`${fieldId}code`] = fieldcode
        }
      } else {
        if (value === "") {
          let fieldcode = getCodeFromFieldOptions(fieldOptions, value);

          delete updatedFormData[fieldId];
          delete updatedFormData[`${fieldId}code`];
        } else {
          if(code === "PST") {
            let formValues = Object.values(formData)
            if(formValues.includes("PS")) {
              
              for (const [key, value] of Object.entries(formData)) {
                
                if (value === "PS") {
                    
                    let keycode = key.split("code")[0]
                    
                    updatedFormData[keycode] = ""
                }
            }

            }
          }
          let fieldcode 
          if(fieldOptions?.length>0) {
          fieldcode =  getCodeFromFieldOptions(fieldOptions, value);
          }
          else {
            fieldcode = code
          }

          updatedFormData[fieldId] = value;
          updatedFormData[`${fieldId}code`] = fieldcode;
        }
      }

      return updatedFormData;
    });
  };

  const handleInputChangeWithValidation = (id, value, name, code) => {
    const isValidInput =
      /^(100(\.0{1,2})?|\d{1,2}(\.\d{1,2})?|0(\.\d{1,2})?)$/.test(value) ||
      value === "";

    if (isValidInput) {
      setnumberlimit(true);
      handleInputChange(id, value, name, code);
    } else {
      setnumberlimit(false);
    }
  };

  //Modified By: Anjali
  //Modified On: 01-12-2024
  //Modified On: 01-12-2024
  //Reason: get the code from the field options to hide/show fields

  //get the code from the field options to hide/show fields
  const getCodeFromFieldOptions = (fieldOptions, selectedValue) => {
    const selectedOption = fieldOptions?.find(
      (option) => option?.fieldOptionId == selectedValue
    );

    return selectedOption ? selectedOption.code : null;
  };

  /************************************************************************************************* */
  // Function to Reset Validation Error Field in Error object of state
  const resetFieldError = (field , name) =>
    setError((prevState) => ({
      ...prevState,
      [field]: name? validateInput(name) || "":"",
    }));

  //change coverage type and get coverage type fields
  const handleCoverageChange = (event) => {
    setselectedgCoverage(event);
    setFormData([]);
    // setCoverageFields([])
    dispatch(GetGetCoverageTypesById(event.value));
    resetFieldError("selectedgCoverage");
  };

  //change policy number
  const handlePolicyNumberChange = (event) => {
    setPolicyNumber(event.target.value);
    resetFieldError("policynumber" , event.target.value);
  };

  //change service broker
  const handleCoverageChange1 = (event) => {
    setselectedgCoverage1(event);
    resetFieldError("servicingbroker");
  };

  //change name insured
  const handleNameInsured = (event) => {
    setNameInsured(event.target.value);
    resetFieldError("nameinsure" , event.target.value);
  };

  const handleChange = (newValue) => {

    if(newValue?.__isNew__) {
      
      handleAddInsurer(newValue?.value)
      setSelectedValue(newValue)
      resetFieldError("insurer" , newValue?.value);
    }
    else {
    setSelectedValue(newValue);

    resetFieldError("insurer")

    }
    
    // resetFieldError("insurer");
  };
  const handlePolicyCountry = (event) => {
    // setPolicyCountry(event.target.value);
    setPolicyCountry(event);
    resetFieldError("country");
  };
  const handleLanguage = (event) => {
    setSelectedLanguage(event);
    resetFieldError("language");
  };
  //change policy type
  const handlePolicyType = (event) => {
    setSelectedPolicyType(event);
    resetFieldError("policytype");
  };
  const handleExclusion = (event) => {
    // setExclusion(event.target.value);
    setExclusion(event);
  };
  //change currency
  const handleCurrency = (event) => {
    setCurrency(event);
    resetFieldError("currency");
  };
  //change payment frequency
  const handlePaymentFrequency = (event) => {
    // setPaymentFrequency(event.target.value);
    setPaymentFrequency(event);
  };
  //change commision payment frequency
  const handleCommisionPaymentFrequency = (event) => {
    // setCommisionPaymentFrequency(event.target.value);
    setCommisionPaymentFrequency(event);
  };

  //change locally admitted coverage
  const handleLocallyAdmittedCoverage = () => {
    setLocallyAdmittedCoverage(!locallyAdmittedCoverage);
  };
  //chaneg renewal reminder
  const handleRenewalReminder = () => {
    setRenewalReminder(!renewalReminder);
  };
  const handleEvergreenRenewal = () => {
    setEverGreenRenewal(!evergreenRenewal);
  };

  //Modified By: Anjali
  //Modified On: 05-03-2024
  //JiraId: https://bosframework.atlassian.net/browse/WBN-117
  //Reason: should not allow to select expiration date before inception date

  const handleExpirationDate = (event) => {
    if (event) {
      setExpirationDate(new Date(event).toISOString());

      setEndDate(event);

      resetFieldError("expirationDate");
    }
  };
  const handleInspectionDate = (event) => {
    if (event) {
      if (new Date(event) > new Date(endDate)) {
        setExpirationDate("");
        setEndDate("");
      }
      setInspectionDate(new Date(event).toISOString());
      // setStartDate(event.target.value)
      let startdate = new Date(event);

      startdate.setDate(startdate.getDate() + 1);
      const nextDayString = startdate.toISOString().slice(0, 10);
      setInceptionStartDate(event);
      setStartDate(nextDayString);
      resetFieldError("inceptionDate");
    }
  };
  const handlePremiumPaid = (event) => {
    setPremiumPaid(new Date(event.target.value).toISOString());
  };
const validate = () => {

  const updatedErrors = {
    

    policynumber: policyNumber !== "" ? validateInput(policyNumber)?"": tab === 2? validateInput(policyNumber) || "":setPolicyNumber("") : "Please enter policy number",
    
    nameinsure: nameInsured !== "" ? validateInput(nameInsured)?"":tab === 2? validateInput(nameInsured) || "":setNameInsured("") : "",
    exclusion: exclusion !== "" ? validateInput(exclusion)?"": tab === 2? validateInput(exclusion) || "":setExclusion("") : "",
    comment: comment !== "" ? validateInput(comment)?"": tab === 5? validateInput(comment) || "":setComment("") : "",
  };

  setError(updatedErrors);
  return Object.values(updatedErrors).every((error) => error === "")

}

  //handle to save policy
  const handleSaveNewPolicy = (type) => {
    let insurereid = [];
    // selectedValue?.map((item , key)=> {
    //   insurereid.push(item.value)
    // })
    let valid = null;
    if (selectedFiles?.[0]) {
      if (isValid()) {
        if (confirmupload === true) {
          valid = true;
        } else {
          setConfirmationOpen(true);
        }
      }
    } else {
      setError(() => {
        return {
          ...errors,
          selecteddocumentType: "",
        };
      });
      valid = true;
    }
    if (valid) {
      const coverageName = coverageTypes?.filter(
        (item, key) => item.id === selectedgCoverage?.value
      );

      let producingBrokerId = props.from === "listing"
      ? props.clientDetail[0]?.producingBroker?.id
      : props.clientDetail?.producingBrokerId
      ? props.clientDetail?.producingBrokerId:""
      
      let country = countries?.find((item , key)=> item.id === selectedPolicyCountry?.value)

      let selectedpolicyTypeoption = policyType?.find((item , key)=> item.id === selectedPolicyType?.value)
      let selectedCurrencyoption = currencies?.find((item , key)=> item.id === selectedCurrency?.value)
      let selectedLangauageoption = language?.find((item , key)=> item.id === selectedLanguage?.value)

      let payload = {
        isDraft: type === "saveasdraft" ? true : false,
        ClientId:
          props.from === "listing"
            ? props.clientDetail[0]?.id
            : props.clientDetail?.id
            ? props.clientDetail?.id
            : "",
        CoverageTypeName: coverageName?.[0]?.name,
        PolicyNumber: policyNumber ? policyNumber : "",
        CreatedUserMemberFirmId:JSON.parse(localStorage.getItem("user"))?.extension_MemberFirmId
?JSON.parse(localStorage.getItem("user"))?.extension_MemberFirmId
:"",
        country:country?country:"",
        policyType:selectedpolicyTypeoption?selectedpolicyTypeoption:"",
        Currency:selectedCurrencyoption?selectedCurrencyoption:"",
        Language:selectedLangauageoption?selectedLangauageoption:"",
        // PolicyStatus: "Initial",
        PolicyTypeId: selectedPolicyType
          ? Number(selectedPolicyType?.value)
          : "",
        CountryId: selectedPolicyCountry?.value
          ? selectedPolicyCountry?.value
          : "",
        CoverageTypeId: selectedgCoverage?.value
          ? selectedgCoverage?.value
          : "",
        LanguageId: selectedLanguage ? selectedLanguage?.value : "",
        CurrencyId: selectedCurrency ? selectedCurrency?.value : "",
        // InsurerId: selectedValue?.value ? selectedValue?.value : "",
        InsurerId: selectedValue?.value ?selectedValue?.__isNew__?"":selectedValue?.value : "",
        IsLocallyAdmittedCoverage: locallyAdmittedCoverage
          ? locallyAdmittedCoverage
          : false,
        NamedInsured: nameInsured ? nameInsured : "",
        // ProducingBrokerId:  JSON.parse(localStorage.getItem("user"))?.extension_MemberFirmId?JSON.parse(localStorage.getItem("user"))?.extension_MemberFirmId:"",
        ProducingBrokerId:  producingBrokerId,
        IsAutoRenewal: evergreenRenewal ? evergreenRenewal : true,
        // Exclusions: selectedExclusion ? selectedExclusion : "",
        Exclusions: exclusion ? exclusion : "",
        Comments: comment ? comment : "",
        ActionPerformedBy: JSON.parse(localStorage.getItem("user"))?.sub?JSON.parse(localStorage.getItem("user"))?.sub:"",
        PolicyPeriod: {
          InceptionDate: selectedInspectionDate
            ? selectedInspectionDate
            : "",
          ExpirationDate: selectedExpirationDate
            ? selectedExpirationDate
            : "",
          // IsPolicyCancelled: false,
          IsRenewalBegun: false,
          TotalEstimatedAnnualPremiumExcludingChargesAndTaxes:
            totalEstimatedAnnualPremium ? totalEstimatedAnnualPremium : 0,
          PremiumPaymentFrequency: selectedPaymentFrequency?.value
            ? selectedPaymentFrequency?.value
            : 1,
          PremiumPaymentDate: "",
          CommissionRate: commisionRate ? commisionRate : 0,
          CommissionPaymentFrequency: selectedCommisionPaymentFrequency?.value
            ? selectedCommisionPaymentFrequency?.value
            : 1,
          TotalEstimatedAnnualCommission:
            Number(totalEstimatedAnnualPremium) * (parseFloat(commisionRate / 100)),
            
          Charges: charges ? charges : 0,
          Taxes: taxes ? taxes : 0,
          TotalPremium:
            Number(totalEstimatedAnnualPremium) +
            Number(taxes) +
            Number(charges)?Number(totalEstimatedAnnualPremium) +
            Number(taxes) +
            Number(charges):0,
          AdditionalBrokerFees: 50.0,
          isRenewReminderSet: renewalReminder?renewalReminder:false
        },
        servicingBrokers: brokerId
          ? brokerId
          : selectedgCoverage1?.value != ""
          ? [selectedgCoverage1?.value]
          : [...brokerId],
      };

      if (type === "saveasdraft") {
        let isvalid = validate()
        if(isvalid) {
        if (props.page === "add") {
          dispatch(AddNewPolicy(payload));
        } else {
          payload.id = props.policyDetails?.id;

          let removedServicingbrokers = [...deletedServicingbrokerData];
          // payload.removedServicingBrokers = removedServicingbrokers;
          payload.PolicyPeriod.id = props.policyDetails.policyPeriodDetail.id;
          payload.PolicyPeriod.renewalStatus = props.policyDetails?.policyPeriodDetail?.renewalStatus         ;
          payload.PolicyPeriod.renewalStatusId = props.policyDetails?.policyPeriodDetail?.renewalStatusId         ;
          
          payload.createdOn= props.policyDetails?.createdOn
          payload.createdBy= props.policyDetails?.createdBy
payload.CreatedUserMemberFirmId = props.policyDetails?.createdUserMemberFirmId

payload.userName = props.policyDetails?.userName
            payload.userEmail = props.policyDetails?.userEmail

          dispatch(updatePolicy(payload));
        }
      }
      else {
        if(tab !== 2) {
        errorToast("Policy number is required")
        }
      }
      } else {
        const isValid = validation();
        if (isValid) {
          if (props.page === "edit") {
            payload.id = props.policyDetails?.id;
            // dispatch(updatePolicy(payload))

            let removedServicingbrokers = [...deletedServicingbrokerData];
            // payload.removedServicingBrokers = removedServicingbrokers;

            payload.PolicyPeriod.id = props.policyDetails.policyPeriodDetail.id;
            payload.PolicyPeriod.renewalStatus = props.policyDetails?.policyPeriodDetail?.renewalStatus
            payload.PolicyPeriod.renewalStatusId = props.policyDetails?.policyPeriodDetail?.renewalStatusId

            payload.createdOn= props.policyDetails?.createdOn
            payload.createdBy= props.policyDetails?.createdBy

            payload.CreatedUserMemberFirmId = props.policyDetails?.createdUserMemberFirmId

            payload.userName = props.policyDetails?.userName
            payload.userEmail = props.policyDetails?.userEmail

            if(props.renewPolicy) {
              payload.PolicyPeriod.term = props.policyDetails?.policyPeriodDetail?.term&&props?.policyDetails?.policyPeriodDetail?.term + 1 
              dispatch(AddNewPolicy(payload))
            }
            else {
              payload.PolicyPeriod.term = props.policyDetails?.policyPeriodDetail?.term&&props?.policyDetails?.policyPeriodDetail?.term 
            dispatch(updatePolicy(payload));
            }
          } else {
            payload.PolicyPeriod.term = 1
            dispatch(AddNewPolicy(payload));
          }
        }
        else {
          errorToast("Please fill all required fields")
        }
      }
    }
  };

  const calculateMaxDate = () => {
    const maxDate = new Date();
    maxDate.setFullYear(maxDate.getFullYear() - 2);
    return maxDate.toISOString().split('T')[0];
  }

  const maxDate = calculateMaxDate()
  


  //upload document and convert it to base64
  const handleFileChange = (event) => {
    const files = event;
    let file = event[0];
    setSelectedFiles(files);

    let popfiletype = files?.[0]?.path?.split(".")?.pop();
    let filetype = popfiletype ? popfiletype.toLowerCase() : popfiletype;
    setFileType(`${filetype}`);
    setfilename(files?.[0]?.name);

    let fileSize = checkDocumentSize(files?.[0]?.size);
    setfilesize(fileSize);

    setShowProgress(true);
    getBase64(
      file,
      updateProgressbarpercentage,
      updatebase64,
      handleProgressbar
    );
  };
  const updateProgressbarpercentage = (e) => {
    setProgressPercentage(e);
  };
  const updatebase64 = (e) => {
    setBase64(e);
  };
  const handleProgressbar = (e) => {
    setShowProgress(e);
  };

  //handle to change visibility
  const handleChangeVisibility = (e) => {
    setvisibility(e.target.value);
    if (e.target.value == "Client") {
      const clientid =
        props.from === "listing"
          ? props.clientDetail[0]?.id
          : props.clientDetail?.id
          ? props.clientDetail?.id
          : "";
      
      const id = clientid;
      setaccessgroupid(id);
    } else if (e.target.value === "user") {
      //make it dynmaic later
      setaccessgroupid("89b258a0-a5f8-4057-9715-5893f1779a4f");
    } else if (e.target.value === "ProducingFirm") {
      // make it dynmaic later
      // setaccessgroupid("5919d327-2e41-4499-b91d-aa027ea6d500");
      const producingBrokerid =
        props.from === "listing"
          ? props.clientDetail[0]?.producingBroker?.id
          : props.clientDetail?.producingBrokerId
          ? props.clientDetail?.producingBrokerId
          : "";
      setaccessgroupid(producingBrokerid)
    }
  };

  //handle add broker
  const addbroker = () => {
    const finddata = members?.find(
      (item) => item.id === selectedgCoverage1?.value
    );

    const finddatainbrokerdata = brokerdats?.find(
      (item) => item.id === selectedgCoverage1?.value
    );
    

    setselectedgCoverage1("");
    
    if (!finddatainbrokerdata) {
      let brokers = [...brokerdats, finddata];
      setbrokerdata(brokers);
      let brokerIds = [...brokerId, selectedgCoverage1?.value];

      setbrokerid(brokerIds);
      setMembersData(members?.filter((item, key) => item.id !== finddata.id));
    }
  };

  //handle to remove broker
  const removebroker = (index) => {
    
    const filterdata = brokerdats?.filter((item) => item.id !== index);
    setbrokerdata(filterdata);
    const fileterbrokerid = brokerId?.filter((item) => item !== index);

    setbrokerid(fileterbrokerid);

    const brokerdata1 = brokerdats?.filter((item) => item.id === index);
    if (brokerdata1[0]) {
      setMembersData((prevMembers) => [...prevMembers, brokerdata1[0]]);
    }
  };

  
  
  //before updating tab check all the required fields are entered or not
  const handleTab = (tab1) => {
    if (tab1) {
      let isvalid = tabWiseValidation(tab1);

      if (isvalid) {
        setTab(tab1);
      }
    }
  };

  const handleFile = (event) => {
    setDocument(event);

    handleFileChange(event);
  };

  //cancel to upload the document
  const handleCancelUpload = () => {
    setConfirmationOpen(false);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const inputRef = useRef(null);
  const handleClose = () => {
    setAnchorEl(null);
  };

  //close PII confirmation modal and upload the document
  const handleConfirmUpload = () => {
    setConfirmationOpen(false);
    setconfirmupload(true);
    // setShowDocumentUpload(true)
  };

  //remove uploaded document
  const removeFile = (item, index) => {
    setDocument([]);
    setSelectedFiles([]);
    setBase64("");
  };

  

  const handleAddInsurer = (inputValue) => {

    let isvalid = validateInput(inputValue)? false: true
if(isvalid) {
    let payload = {
      
        // "id": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        "code": inputValue?inputValue:"",
        "description": "",
        "actionPerformedBy": JSON.parse(localStorage.getItem("user")).sub?JSON.parse(localStorage.getItem("user"))?.sub:""
      
    }

    dispatch(addInsurerData(payload))
  }
  }
  
  const formatCreateLabel = (inputValue) => {
    return (
  <>
  {inputValue}
    <Button 
    // onClick={()=> handleAddInsurer(inputValue)}
    
      className="add-tag-button"
            sx={{
            textTransform: "none",
            padding: "10px 0 5px 10px !important",
            display: "flex",
            justifyContent: "flex-start",
          }}
      >
        <i class="fa fa-plus"></i> Add insurer
      </Button>
  </>
    )
  }

  //change document type
  const handleChangedocumentType = (event) => {
    setSelectedDocumentType(event);
  };

  const handleVisibility = (e , item , index) => {
  let accessGroupid = ""
  if(e?.value === "Client") {
    const clientid =
        props.from === "listing"
          ? props.clientDetail[0]?.id
          : props.clientDetail?.id
          ? props.clientDetail?.id
          : "";
      const id = clientid;
      accessGroupid = id;
    } 
  
  else if(e?.value === "ProducingFirm") {
      // make it dynmaic later
      const producingBrokerid =
        props.from === "listing"
          ? props.clientDetail[0]?.producingBroker?.id
          : props.clientDetail?.producingBrokerId
          ? props.clientDetail?.producingBrokerId
          : "";
      accessGroupid = producingBrokerid;
    }
  
   let payload=  [
      {
        "documentId": item.id,
        "documentDisplayName": item.documentDisplayName,
        "description": item.description        ,
        "accessGroup": e?.value,
        "accessGroupId": accessGroupid,

      }
    ]
    
    updateVisibility[index] = e?.value
    let visibilitydocument = updateVisibilitydocument(item.id , e?.value)
    
    setDocumentsDetailsData(visibilitydocument)
    let findDocument = updateDocumentVisibility?.find((document , key)=> document.documentId === item.id)

    if(findDocument) {
      findDocument.accessGroup = e?.value
        setupdateDocumentVisibility([...updateDocumentVisibility])
    }
    else {
      setupdateDocumentVisibility([...updateDocumentVisibility , ...payload])
    }
  }

  const updateVisibilitydocument = (documentId, newVisibility) => {
    const updatedDocuments = documentsDetailsdata.map(doc => {
      if (doc.id === documentId) {
        return { ...doc,   accessGroup: newVisibility};
      }
      return doc;
    })
    return updatedDocuments
  }
  
  const handleDeleteDocument = (documentid) => {
    // setDeletedDocumentData()
    const filterdata = documentsDetailsdata?.filter((item) => item.id !== documentid);
    setDocumentsDetailsData(filterdata);
    if (deletedDocumentData.includes(documentid)) {
      return;
    } else {
      deletedDocumentData.push(documentid);
    }
  }
  const visibilityOption = [
    {
      label: "All associated with this client",
      value: "Client",
    },
    {
      // label:"Producing brokers of firm only",
      label:"producing broker and my firm only",
      value:"ProducingFirm",
    }
  ]


  const handleExclusionChange = (event) => {
    setExclusiontext(event.target.value);
    resetFieldError("exclusion" , event.target.value)
  };

  /*********************************************validation*************************** */
  //Modified By: Anjali
  //Modified On: 01-17-2024
  //Reason: show validation when navigating to other tab without entering the values in required fields

  //show validation when navigating to other tab without entering the values in required fields
  const tabWiseValidation = (clickedtab) => {
    if (tab === 2) {
      if (clickedtab === 3 || clickedtab === 4 || clickedtab === 5) {
        const updatedErrors = {
          selectedgCoverage: selectedgCoverage?.value
            ? ""
            : "Please select coverage type",

          policynumber: policyNumber !== "" ? validateInput(policyNumber) || "" : "Please enter policy number",

          country: selectedPolicyCountry?.value  ? "" : "Please select country",
          language: selectedLanguage?.value  ? "" : "Please select language",
          policytype:
            selectedPolicyType?.value  ? "" : "Please select policy type",
          // insurer: selectedValue?.value  ? "" : "Please select insurer",
          insurer: selectedValue?.value  ? selectedValue?.__isNew__?validateInput(selectedValue?.value) || "":"" : "Please select insurer",

          nameinsure: nameInsured !== "" ? validateInput(nameInsured) || "" : "Please enter name insured",
        };

        setError(updatedErrors);
        return Object.values(updatedErrors).every((error) => error === "");
      }
      if (clickedtab === 1) {
        setTab(clickedtab);
      }
    } else if (tab === 3) {
      if (clickedtab === 4 || clickedtab === 5) {
        const updatedErrors = {
          inceptionDate:
            selectedInspectionDate !== "" ? "" : "Please select inception date",
          expirationDate:
            selectedExpirationDate !== ""
              ? ""
              : "Please select expiration date",
              exclusion: exclusion === "" ? "" : validateInput(exclusion) || ""
        };

        setError(updatedErrors);
        return Object.values(updatedErrors).every((error) => error === "");
      }
      if (clickedtab === 1 || clickedtab === 2) {
        setTab(clickedtab);
      }
    } else if (tab === 4) {
      if (clickedtab === 5) {
        const updatedErrors = {
          currency: selectedCurrency?.value  ? "" : "Please select currency",

          //Temporarily commented because for now it's not a required fields
          // totalEstimatedAnnualPremium:totalEstimatedAnnualPremium!==""?"":"Please enter total estimated annual premium",
          // premiumPaymentFrequency:selectedPaymentFrequency!==""?"":"Please select premium payment frequency",
          // commisionRate:commisionRate!==""?"":"Please enter commision rate",
          // commissionPaymentFrequency:selectedCommisionPaymentFrequency!==""?"":"Please select commistion payment frequency",
          // charges:charges!==""?"":"Please enter charges",
          // taxes:taxes!==""?"":"Please enter taxes"
        };

        setError(updatedErrors);
        return Object.values(updatedErrors).every((error) => error === "");
      }
      if (clickedtab === 1 || clickedtab === 2 || clickedtab === 3) {
        setTab(clickedtab);
      }
    } else {
      if (clickedtab === 5 && tab !== 5) {
        if (confirmupload !== true) {
          setConfirmationOpen(true);
        }
      }
      setTab(clickedtab);
    }
  };

  const validateFile = (file) => {
    let validate = validateDocument(file);
    if (validate === true) {
      setFileError("");
      return true;
    } else {
      setFileError(validate);
      return false;
    }

    // setError("");

    // return true;
  };

  const isValid = () => {
    const updatedErrors = {
      selecteddocumentType:
        selecteddocumentType === "" || selecteddocumentType === undefined || selecteddocumentType?.value === ""
          ? "Please select document type"
          : "",
      filename: filename === "" ? "Please enter file name" : "",
    };

    setError(updatedErrors);
    return Object.values(updatedErrors).every((error) => error === "");
  };
  const validation = () => {
    const updatedErrors = {
      selectedgCoverage: selectedgCoverage?.value
        ? ""
        : "Please select coverage type",

      policynumber: policyNumber !== "" ? validateInput(policyNumber) || "" : "Please enter policy number",

      country: selectedPolicyCountry?.value  ? "" : "Please select country",
      language: selectedLanguage?.value  ? "" : "Please select language",
      policytype: selectedPolicyType?.value  ? "" : "Please select policy type",
      insurer: selectedValue?.value  ? selectedValue?.__isNew__?validateInput(selectedValue?.value) || "":"" : "Please select insurer",

      nameinsure: nameInsured !== "" ? validateInput(nameInsured) || "" : "Please enter name insured",
      inceptionDate:
        selectedInspectionDate !== "" ? "" : "Please select inception date",
      expirationDate:
        selectedExpirationDate !== "" ? "" : "Please select expiration date",
      currency: selectedCurrency?.value  ? "" : "Please select currency",
      comment: comment === "" ? "":validateInput(comment) || ""
    };

    setError(updatedErrors);
    return Object.values(updatedErrors).every((error) => error === "");
  };

{console.log(props.renewPolicy)}

  return (
    <Box className="create-new-modal">
      <TopSection
        tab={tab}
        handleSaveNewPolicy={handleSaveNewPolicy}
        handleCloseNewPolicyModal={props.handleCloseNewPolicyModal}
        handleTab={handleTab}
        renewPolicy={props.renewPolicy}
      />
      <Box class="border"></Box>
      <Box className="modal-body mt-3" sx={{ padding: "10px 300px" }}>
        <Box className="modal-policy-text">
          <Typography variant="span">{props.page === "edit"? "Update policy":"New policy"}</Typography>
        </Box>
        <Box className="modal-title mb-3">
          <Typography variant="h5" style={{ fontWeight: "bold" }}>
            Association
          </Typography>
        </Box>

        <NavTabs
          tabs={[
            { name: "1 Association", id: 1 },
            { name: "2 Details", id: 2 },
            { name: "3 Terms", id: 3 },
            { name: "4 Premium", id: 4 },
            { name: "5 Document", id: 5 },
          ]}
          activeTab={tab}
          onClick={handleTab}
          xs="12"
          md="2"
          lg="2"
        />
        {confirmationOpen && (
          <PIIConfirmationModal
            setConfirmationOpen={setConfirmationOpen}
            confirmationOpen={confirmationOpen}
            handleCancelUpload={handleCancelUpload}
            handleConfirmUpload={handleConfirmUpload}
          />
        )}
        <Box className="tab-content" id="pills-tabContent">
          <Tab1
            tab={tab}
            from={props.from}
            clientDetail={props.clientDetail}
            selectedgCoverage1={selectedgCoverage1}
            handleCoverageChange1={handleCoverageChange1}
            errors={errors}
            members={members}
            addbroker={addbroker}
            removebroker={removebroker}
            brokerdats={brokerdats}
            
            producingBrokerName={producingBrokerName}
            producingBrokerCountry={producingBrokerCountry}

            renewPolicy={props.renewPolicy}

          />
          <Tab2
            tab={tab}
            selectedgCoverage={selectedgCoverage}
            coverageTypes={coverageTypes}
            errors={errors}
            handleCoverageChange={handleCoverageChange}
            handlePolicyNumberChange={handlePolicyNumberChange}
            policyNumber={policyNumber}
            countries={countries}
            handlePolicyCountry={handlePolicyCountry}
            selectedPolicyCountry={selectedPolicyCountry}
            language={language}
            selectedLanguage={selectedLanguage}
            handleLanguage={handleLanguage}
            policyType={policyType}
            selectedPolicyType={selectedPolicyType}
            handlePolicyType={handlePolicyType}
            handleLocallyAdmittedCoverage={handleLocallyAdmittedCoverage}
            locallyAdmittedCoverage={locallyAdmittedCoverage}
            handleChange={handleChange}
            isInsure={isInsure}
            selectedValue={selectedValue}
            handleNameInsured={handleNameInsured}
            nameInsured={nameInsured}
            coverageFields={coverageFields}
            formData={formData}
            handleInputChange={handleInputChange}
            handleInputChangeWithValidation={handleInputChangeWithValidation}
            numberlimit={numberlimit}
            
            formatCreateLabel={formatCreateLabel}

            renewPolicy={props.renewPolicy}

          />
          <Tab3
            tab={tab}
            handleInspectionDate={handleInspectionDate}
            errors={errors}
            inceptionStartDate={inceptionStartDate}
            handleExpirationDate={handleExpirationDate}
            startDate={startDate}
            endDate={endDate}
            handleRenewalReminder={handleRenewalReminder}
            renewalReminder={renewalReminder}
            selectedExclusion={selectedExclusion}
            handleExclusion={handleExclusion}
            handleExclusionChange={handleExclusionChange}
            exclusion={exclusion}
            handleEvergreenRenewal={handleEvergreenRenewal}
            evergreenRenewal={evergreenRenewal}
            exclusionOption={[{label:"Other" , value:"Other"}]}
            maxDate={maxDate}

            renewPolicy={props.renewPolicy}

          />
          <Tab4
            tab={tab}
            selectedCurrency={selectedCurrency}
            handleCurrency={handleCurrency}
            errors={errors}
            currencies={currencies}
            totalEstimatedAnnualPremium={totalEstimatedAnnualPremium}
            limitDecimalValue={limitDecimalValue}
            handleTotalEstimatedAnnualPremium={setTotalEstimatedAnnualPremium}
            selectedPaymentFrequency={selectedPaymentFrequency}
            handlePaymentFrequency={handlePaymentFrequency}
            handlePremiumPaid={handlePremiumPaid}
            commisionRate={commisionRate}
            limitPercentageValue={limitPercentageValue}
            handleCommisionrate={setCommisionRate}
            selectedCommisionPaymentFrequency={
              selectedCommisionPaymentFrequency
            }
            handleCommisionPaymentFrequency={handleCommisionPaymentFrequency}
            charges={charges}
            handleCharges={setCharges}
            taxes={taxes}
            handleTaxes={setTaxes}
            premiumpaymentfrequencyOption={premiumpaymentfrequencyOption}

            renewPolicy={props.renewPolicy}

          />
          <Tab5
            tab={tab}
            handleChangedocumentType={(e) => handleChangedocumentType(e)}
            documentTypes={documentTypes}
            selecteddocumentType={selecteddocumentType}
            errors={errors}
            handleFile={handleFile}
            validateFile={validateFile}
            fileError={fileError}
            document={document}
            handleFilename={setfilename}
            inputRef={inputRef}
            selectedOption={selectedOption}
            showprogress={showprogress}
            removeFile={removeFile}
            handleClick={handleClick}
            handleClose={handleClose}
            handleSelectedoption={setSelectedOption}
            progressbarpercentage={progressbarpercentage}
            handleChangeVisibility={handleChangeVisibility}
            handleComment={setComment}
            resetFieldError={resetFieldError}
            filename={filename}
            filesize={filesize}
            anchorEl={anchorEl}
            documentsDetailsdata={documentsDetailsdata}
            page={props.page}
            handleVisibility={handleVisibility}
            visibilityOption={visibilityOption}
            handleDeleteDocument={handleDeleteDocument}

            renewPolicy={props.renewPolicy}
            

          />
        </Box>
      </Box>
    </Box>
  );
};

export default CreateNewPolicy;
