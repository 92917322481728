import React, { useEffect, useRef, useState } from "react";

import Select, { components } from "react-select";

import CreatableSelect from "react-select/creatable";

import { useDispatch, useSelector } from "react-redux";

import { MentionsInput, Mention } from "react-mentions";

//Material-UI components
import { Box, Grid, Typography } from "@mui/material";

//Custom MUI components
import GridContainer from "../../components/GridContainer";

import Button from "../../components/Button";

import { RenderTextInput } from "../../components/TextField";



import Switch from "../../components/Switch";

import {
  COLORS,
  OptionWithDelete,
  customFilterOption,
  dateFormat,
  dropDownOptions,
  getRandomColor,
  validateInput,
  handleSpecialcharactersValidationForMention,
  policiesOptions,
} from "../../constants";

import ConfirmationModal from "../../components/common/ConfirmationModal";

import { getClientPolicies, setClientPolicies } from "../../store/actions/ClientAction";

import {
  createTags,
  createTask,
  getTags,
  updateTask,
} from "../../store/actions/TasksAction";

//Custom styles for components
import { customStyles, tagStyles } from "../../components/common/CustomStyle";

import { defaultMentionStyle, defaultStyle } from "./MentionStyles";
import * as base from "../../store/actions/BaseActions"
import { TASKS_CONST } from "../../store/actions/actionTypes/TasksActionTypes";

const AddTaskModal = ({
  handleClose,
  clientList,
  taskDetails,
  page,
  userList,
  
}) => {
  //state variables declaration

  const [task, setTask] = useState("");
  const [description, setDescription] = useState("");

  const [dueDate, setDueDate] = useState("");
  const [reminder, setReminder] = useState(false);
  const [selectedreminder, setSelectedReminder] = useState("");

  const [selectedtags, setSelectedTags] = useState([]);
  const [selectedTagsValue, setSelectedTagsValue] = useState([]);

  const [assignedto, setAssignedTo] = useState("");
  const [selectedClient, setselectedClient] = useState("");
  const [selectedPolicy, setSelectedPolicy] = useState("");
  const [policies, setPolicies] = useState([]);
  const [tags, setTags] = useState([]);

  const [notes, setNotes] = useState([]);
  const [tagoption, setTagOption] = useState([]);
  const [tagsOption, setTagsOptions] = useState([]);
  const [remindersoption, setRemindersoption] = useState([]);
  const [createdTag, setCreatedTag] = useState("");
  const [usersOption, setUsersOption] = useState([]);
  const [mentionData, setMentionData] = useState([]);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [error, setError] = useState({});
  const [usersList, setUsersList] = useState([]);

  // const [inputTexts, setInputTexts] = useState([]);
  const [inputTexts, setInputTexts] = useState([
    { Description: "", error: "" },
  ]);

  //extract specific pieces of state from the Redux store
  const stateData = useSelector((state) => {
    return {
      countries: state?.countries?.Countries,

      policies: state?.fetchData?.clientPoliciesData,
      tagsData: state?.tasks?.tagsdata,
      createdtag: state?.tasks?.createdtagsdata,
      userList: state?.user?.usersList,
      reminderdata: state?.tasks?.reminderdata,
    };
  });

  const dispatch = useDispatch();
  useEffect(() => {
    if (tags) {
      let tagsOption = tags?.map((item, key) => {
        return {
          value: item.id,
          label: item.name,
          color: item.color,
        };
      });
      setTagsOptions(tagsOption);
    }
    if (usersList) {
      let user = usersList?.map((item, key) => {
        // Check if either givenName or surName is not null or undefined
        if (
          (item.givenName !== null && item.givenName !== undefined) ||
          (item.surName !== null && item.surName !== undefined)
        ) {
          let fullName = `${item.givenName ? item.givenName : ""} ${
            item.surName ? item.surName : ""
          }`;
          // let countryname = getCountryName(item.countryId);

          return {
            ...item,
            fullName: fullName?.trim(), // Trim to remove any leading or trailing spaces
            status: item.approvalStatus,

            access: item.access ? item.access : "",
            // country: countryname ? countryname : "",
            // countryId: item.countryId,
          };
        } else {
          // Return null for rows where both givenName and surName are null or undefined
          return null;
        }
      })
      .filter((item) => item !== null)
      let userOption = user?.map((item, key) => {
        return {
          value: item.id,
          label: item.fullName&&item?.memberFirmName!==null?`${item.fullName}/${item?.memberFirmName}`:item.fullName,
          email: item.email,
          profileImage: "",
        };
      });
      setUsersOption(userOption);

      //mentionData
  const mention = user?.map((user) => ({
    id: user.id,
    display:
    (user.givenName !== null && user.givenName !== undefined) ||
    (user.surName !== null && user.surName !== undefined)
        ? user.givenName + " " + user.surName
        : user.givenName
        ? user.givenName
        : "",
    avatar: user.memberFirmAvatar,
  }));
  setMentionData(mention)
    }
  }, [tags, usersList]);

  useEffect(()=> {

    return ()=> {
      dispatch(setClientPolicies(""))
    }
  } , [])
  
  //useEffect to dispatch action to get tags when new tag is created
  useEffect(() => {
    if (stateData?.createdtag?.data?.id) {
      dispatch(getTags());
      // setSelectedTags(selectedtags?.filter((item , key)=> item.name !== tagoption))
      setCreatedTag(stateData?.createdtag?.data?.id)
      dispatch(base.getSuccess(TASKS_CONST.CREATE_TAGS_SUCCESS, ""));
      
    }
  }, [stateData?.createdtag?.data?.id]);



  
  //useEffect to set initial state values when task details are provided
  useEffect(() => {
    if (taskDetails && page === "edit" && remindersoption) {
      let note = taskDetails?.notes?.map?.((item, key) => {
        return {
          id: item.id,
          Description: item.description
        };
      });
      let tag = taskDetails?.tags?.map((item, key) => {
        return {
          value: item.id,
          label: item.name,
          color: item.color,
        };
      });
      let selectedtag = taskDetails?.tags?.map((item, key) => {
        return {
          id: item.id,
          name: item.name,
          color: item.color,
        };
      });

      let userOption = userList?.map((item, key) => {
        return {
          value: item.id,
          label: `${item.givenName} ${item.surName}`,
        };
      });

      //Filter assignedTo options based on task details
      const filterIds = taskDetails?.assignedTo.map((obj) => obj.id);
      const assinedtooption = userOption.filter((obj) =>
        filterIds.includes(obj.value)
      );
      const reminderoption = reminderOptions?.filter(
        (item, key) => item.value === taskDetails?.reminder?.id
      );

      setAssignedTo(assinedtooption);
      setTask(taskDetails.title);
      setDescription(taskDetails?.description);
      
      let selectedclient = dropDownOptions(clientList, "id", "clientName")?.find((item , key)=> item.value === taskDetails?.clientId)
      setselectedClient(selectedclient)
      let event = {
        target: {
         value: taskDetails?.clientId
        }
      }
      
      
      
      let policyoption = policiesOptions(policies)?.find((item , key)=> taskDetails?.policyId)
      
      setSelectedPolicy(policyoption);
      setDueDate(dateFormat(taskDetails?.dueDate));
      setReminder(taskDetails?.isSetReminder);

      setSelectedReminder(reminderoption);
      if(note?.length>0) {
        setInputTexts(note);
      }
      setSelectedTagsValue(tag);
      setSelectedTags(selectedtag);
    }
  }, [taskDetails, userList, remindersoption]);

  useEffect(()=> {
    if(policies && page === "edit") {
      setSelectedPolicy("")
      let policyoption = policiesOptions(policies)?.find((item , key)=> item.value === taskDetails?.policyId)
      
      setSelectedPolicy(policyoption);
    }
      } , [policies , page])

  useEffect(() => {
    if (taskDetails && page === "copy") {
      
      setTask(taskDetails?.task)
      setDescription(taskDetails?.description)
      setDueDate(taskDetails?.dueDate)
      
      let selectedclient = dropDownOptions(clientList, "id", "clientName")?.find((item , key)=> item.value === taskDetails?.clientId)
      setselectedClient(selectedclient)
      
    }
  }, [taskDetails]);

  useEffect(() => {
    if (JSON.parse(localStorage.getItem("user"))?.sub && usersList && (page === "add" || page ==="copy")) {
      let user = usersOption?.find(
        (item, key) => item.value === JSON.parse(localStorage.getItem("user"))?.sub
      );

      setAssignedTo([user]);
    }
  }, [usersOption]);

  //useEffect to set users list when available in Redux store
  useEffect(() => {
    if (stateData?.userList) {
      setUsersList(stateData?.userList?.data);
    }
  }, [stateData?.userList]);

  //useEffect to set policies when available in Redux store
  useEffect(() => {
    if (stateData?.policies) {
      setPolicies(stateData?.policies?.data?.policiesForClient?.nodes);
    }
  }, [stateData?.policies]);

  //useEffect to set tags when available in Redux store
  useEffect(() => {
    if (stateData?.tagsData?.data?.tags?.nodes) {
      
      setTags(stateData?.tagsData?.data?.tags?.nodes);
      
    }
  }, [stateData?.tagsData?.data?.tags?.nodes]);

  //useEffect to set remindersoptions when available in Redux store
  useEffect(() => {
    if (stateData?.reminderdata?.data?.reminderOptions) {
      setRemindersoption(stateData?.reminderdata?.data?.reminderOptions);
    }
  }, [stateData?.reminderdata?.data?.reminderOptions]);

  useEffect(()=> {
    if(selectedClient?.value) {

      let client = clientList?.filter((item , key)=> item.id === selectedClient?.value)
      let ClientProducingBroker = client?.[0]?.producingBroker
                ?.id
          
                let payload = {
                  isSort: false,
                  sortName: "",
                  sortASC: "",
                  isDraft:false,
                }

      dispatch(getClientPolicies(selectedClient?.value, ClientProducingBroker , true , payload))


    }
    else if (selectedClient?.value === "") {
      setSelectedPolicy("")
      setPolicies([])
      dispatch(setClientPolicies(""))
    }
  } , [selectedClient?.value])

  let reminderOptions = remindersoption?.map((item, key) => {
    return {
      value: item.id,
      label: item.option,
    };
  });

  const handleSelectClient = (event) => {

    setselectedClient(event);

    setSelectedPolicy("")
    
  };

  //Function to handle adding notes
  const handleAddNote = () => {
    let hasEmptyInput = false;
    for (const input of inputTexts) {
      if (input.Description.trim() === "") {
        input.error = "Please fill in this note.";
        hasEmptyInput = true;
      }
    }

    if (!hasEmptyInput) {
      setInputTexts([...inputTexts, { Description: "", error: "" }]);
    } else {
      setInputTexts([...inputTexts]);
    }
  };

  //Function to handle deleting notes
  const handleDeleteNote = (input, index) => {
    const newInputs = [...inputTexts];
    newInputs.splice(index, 1);
    setInputTexts(newInputs);
  };

  //Function to handle input change in notes
  const handleInputChange = (index, e) => {
    const newInputs = [...inputTexts];
    const newInput = { ...newInputs[index] };
    newInput.Description = e.target.value;
    newInputs[index] = newInput;

    if (e.target.value.trim() === "" && index !== 0) {
      newInput.error = "Please fill in this note.";
    } else {
      if(e.target.value && index === 0) {
        {console.log(inputTexts , e.target.value)}
        
        let validatetext = handleSpecialcharactersValidationForMention(e.target.value)
        
        
        if(validatetext === "") {
          newInput.error = ""
        }
        else {
          
          newInput.error = validatetext
        }
      }
      else {
        newInput.error = ""
      }
      
    }

    setInputTexts(newInputs);
    
  };

  //Function to handle selecting tags
  const handleSelect = (e) => {
    

    // const isNew = e.some(option => option.__isNew__);
    const existingOptions = e.filter(option => !option.__isNew__)
    const newgOptions = e.filter(option => option.__isNew__)
  // If any option is new, don't update the state
  
  if (newgOptions?.length>0) {
    addTag(newgOptions?.[0]?.label)
  }
  else {
  // const existingOptions = e.filter(option => !option.__isNew__)
    setSelectedTagsValue(existingOptions);
    

    const transformedArray = existingOptions?.map((item) => ({
      id: item.value,
      name: item.label,
      color: item.color,
    }));
    setSelectedTags(transformedArray);
  }
  };

  //Function to handle deleting tags
  const handleDelete = (optionToDelete) => {
    const updatedOptions = tagsOption.filter(
      (option) => option.value !== optionToDelete.value
    );
    setTagsOptions(updatedOptions);
  };

  //Function to add new tag
  const addTag = (tagoption) => {
    let isvalid = validateInput(tagoption) ? false : true
    if(isvalid) {
    let randomcolor = getRandomColor(COLORS);

    if (tagoption?.length > 0 && randomcolor) {
      let payload = {
        name: tagoption ? tagoption?.trim() : "",
        ActionPerformedBy: JSON.parse(localStorage.getItem("user"))?.sub
          ? JSON.parse(localStorage.getItem("user"))?.sub
          : "",
        color: randomcolor,
      };

      dispatch(createTags(payload));
    }

  }

  };

  //Function to reset field errors
  const resetFieldError = (field , name) =>
    setError((prevState) => ({
      ...prevState,
      [field]: name? validateInput(name) || "":"",
    }));

  //Function for form validation
  const validation = () => {
    const updatedErrors = {
      task: task?.trim() !== "" ? validateInput(task) || ""  : "Please enter task",
      description:description?.trim()!==""?validateInput(description) || "":""
    };

    setError(updatedErrors);
    return Object.values(updatedErrors).every((error) => error === "");
  };

  //Function to find email by user ID
  function findEmailById(userId) {
    const user = userList.find((user) => user.id === userId);

    return user ? user.mail : null;
  }
  //Function to find user
  function findUser(userId) {
    const user = userList.find((user) => user.id === userId);

    return user ? user : null;
  }

  //Function to extract mentions from note descriptions
  function extractMentions(data) {
    if (!data || (data.length === 1 && !data[0]?.Description)) {
      return [];
    }

    const mentionsArray = data?.map((item) => {
      const mentions = [];
      let note = {
        Description: item.Description,
        mentions,
      };

      const mentionRegex = /@\[(.*?)\]\((.*?)\)/g;
      let match;

      while ((match = mentionRegex.exec(item.Description)) !== null) {
        const name = match[1];
        const id = match[2];

        const email = findEmailById(match[2]);
        mentions.push({ name, id, email });
        
      }

      // return mentions;
      return note;
    });

    return mentionsArray;
  }

  //Function to handle form submission
  const handleSubmit = (type) => {

    const updatedInputs = inputTexts.map((input) => {
      const updatedInput = { ...input };
      if (input.Description.trim() === "") {
        updatedInput.error = "";
      } 
      
      else if (input.Description && handleSpecialcharactersValidationForMention(input.Description)) {
        
        updatedInput.error = handleSpecialcharactersValidationForMention(input.Description);
      } else {
        updatedInput.error = "";
      }
      return updatedInput;
    });
  
    setInputTexts(updatedInputs)

    const hasErrors = updatedInputs.some(input => input.error !== "");
    let validate = validation()
  if (hasErrors || !validate) {
    return; 
  }

    let clientName = clientList?.filter(
      (item, key) => item.id === selectedClient?.value
    );
    let reminderArray = remindersoption?.filter(
      (item, key) => item.id === selectedreminder?.value
    );

    let policyName = policies?.filter(
      (item, key) => item.id === selectedPolicy?.value
    );

    let note = [...inputTexts];
    note.forEach((item, key) => delete item.error);

    let data = extractMentions(note);

    let assignedtovalue =
      assignedto?.length > 0
        ? assignedto?.map((item, key) => {
            let mail = findEmailById(item.value);
            let user = findUser(item.value)
            return {
              id: item.value,
              name: item.label,
              email: mail,
              profileImage: "",
              memberFirmId: user?.memberFirmId?user.memberFirmId:"",
              memberFirmName: user?.memberFirmName?user?.memberFirmName:"",
              countryId: user?.countryId
            };
          })
        : [];

    let userId = JSON.parse(localStorage.getItem("user"));

    let payload = {
      title: task ? task : "",
      description: description ? description : "",
      clientId: selectedClient?.value ? selectedClient?.value : "",
      clientName: clientName?.[0]?.clientName
        ? clientName?.[0]?.clientName
        : "",
      policyId: selectedPolicy?.value ? selectedPolicy?.value : "",
      policyName: policyName?.[0]?.policyNumber,
      dueDate: dueDate ? new Date(dueDate).toISOString() : "",
      isSetReminder: reminder,

      reminder: reminderArray?.[0] ? reminderArray?.[0] : "", //1 day before, 2 day before, 5 day before, 1 month before, 2 month before

      IsDraft: type === "save" ? false : true,
      IsCompleted: false,
      CreatedBy: userId?.sub,
      Tags: [...selectedtags],
      Notes: [...data],
      AssignedTo: [...assignedtovalue],
    };

    if (page === "edit") {
      payload.id = taskDetails?.id;
      let userId = JSON.parse(localStorage.getItem("user"));

      payload.ModifiedBy = userId.sub;
      const valid = validation()
      if(valid){
      dispatch(updateTask(payload));
      }
    } else {
      const valid = validation();
      let hasEmptyInput = false;
      for (const input of inputTexts) {
        if (input.Description.trim() === "" && inputTexts.length > 1) {
          input.error = "Please fill in this note.";
          hasEmptyInput = true;
        }
      }

      if (valid && !hasEmptyInput) {
        dispatch(createTask(payload));
      }
    }
  };

  
  

  //Function to handle adding mentions
  const handleAddMention = (index, props) => {
    // Assuming props has the selected suggestion ID
    const selectedSuggestionId = props.id; // or however you retrieve the selected suggestion ID

    // Now, call handleInputChange passing the selected suggestion ID
    handleInputChange(index, { target: { value: selectedSuggestionId } });
    
  };
const handleTagInput = (e) => {
  
  setTagOption(e)
  resetFieldError("tag" , e)

}

  const formatCreateLabel = (inputValue) => (
    <Button
      className="add-tag-button"
      onClick={() => addTag(tagoption)}
      sx={{
        textTransform: "none",
        padding: "10px 0 5px 10px !important",
        display: "flex",
        justifyContent: "flex-start",
      }}
    >
      <i class="fa fa-plus"></i> Add tag
    </Button>
  );

  //Function to handle adding notes
  const handleAddNoteSubmit = () => {
    const newNote = inputTexts[inputTexts.length - 1];
    setNotes([...notes, newNote]);
  };

  return (
    <Box style={{ padding: "12px 20px" }}>
      {showConfirmation && (
        <ConfirmationModal
          showConfirmation={showConfirmation}
          handleCloseConfirmation={() => setShowConfirmation(false)}
          handleConfirm={() => {
            setShowConfirmation(false);
            handleClose();
          }}
          title={"Task"}
          subTitle={"discard this task"}
          buttonname={"Discard"}
        />
      )}
      <Box>
        <Box
          className="sidepanel-header add-contact"
          style={{
            display: "flex",
            alignItems: "center",
            borderBottom: "1px solid var(--grey-200)",
            
            padding: "12px 5px",
          }}
        >
          <a
            href="javascript:void(0)"
            className="btn-close"
            onClick={() => handleClose()}
            style={{ marginRight: "5px", padding: "9px" }}
          ></a>

          <Button
            className="btn btn-default add-policy btn-md mr-8"
            style={{ backgroundColor: "grey", color: "#FFF" }}
            variant={"contained"}
            color={"grey"}
            onClick={() => handleSubmit("save")}
          >
            Save
          </Button>

          <span style={{ marginLeft: "10px" }}>
            <Button
              className="btn btn-default add-policy btn-md revoke-cancellation-btn"
              variant={"contained"}
              color={"grey"}
              onClick={() => handleSubmit("saveasdraft")}
            >
              Save as draft
            </Button>
          </span>
          <span style={{ marginLeft: "10px" }}>
            <Button
              className="btn btn-default add-policy btn-md revoke-cancellation-btn"
              variant={"outlined"}
              color={"white"}
              onClick={() => setShowConfirmation(true)}
            >
              Discard
            </Button>
          </span>
        </Box>
        <Box className="sidepanel-body add-contact">
          <GridContainer sx={{ marginTop: "10px" }}>
            <Grid xs="12" md="12" lg="12">
              <Typography variant="h6" style={{ fontWeight: "bold" }}>
                {page === "edit" ? "Update Task" : "Create Task"}
              </Typography>
            </Grid>

            <Grid xs="12" md="12" lg="12">
              <Box class="mb-3">
                <label for="exampleFormControlInput1" class="form-label">
                  Task*
                </label>
                <RenderTextInput
                  value={task}
                  onChange={(e) => {
                    setTask(e.target.value);
                    resetFieldError("task" , e.target.value);
                  }}
                  placeholder="Task"
                  errors={error.task}
                  block
                  required
                />
              </Box>
            </Grid>
            <Grid xs="12" md="12" lg="12">
              <Box class="mb-3">
                <label for="exampleFormControlInput1" class="form-label">
                  Description
                </label>
                <RenderTextInput
                  value={description}
                  onChange={(e) => {setDescription(e.target.value); resetFieldError("description" , e.target.value)}}
                  placeholder="Description"
                  block
                  required
                  errors={error.description}
                />
              </Box>
            </Grid>
            <Grid xs="12" md="12" lg="12">
              {inputTexts?.length > 0 && (
                <Box className="client-text">Notes</Box>
              )}

              {inputTexts.map((inputText, index) => (
                <div key={index} className="w-100 mt-3 mb-3 position-relative">
                  

                  <MentionsInput
                    type="text"
                    value={inputText.Description}
                    onChange={(e) => handleInputChange(index, e)}
                    onKeyDown={handleAddNoteSubmit}
                    className={
                      inputText.error === "" || inputText.error === undefined
                        ? `addNote-input`
                        : "is-invalid addNote-input"
                    }
                    

                    singleLine
                    style={defaultStyle}
                  >
                    <Mention
                      trigger="@"
                      data={mentionData}
                      className="mentions__mention"
                      renderSuggestion={(props) => (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: "10px",
                            width: "230px",
                          }}
                          onClick={() => handleAddMention(index, props)}
                        >
                          <span
                            style={{
                              padding: "5px",
                              borderRadius: "4px",
                              marginRight: 8,
                              backgroundColor: "#feb273",
                              marginLeft: "10px",
                            }}
                          >
                            {props.display?.split(" ")?.[0]?.[0]}{" "}
                            {props.display?.split(" ")?.[1]?.[0]}
                          </span>
                          <span style={{ fontWeight: "500" }}>
                            {props.display}
                          </span>
                        </div>
                      )}
                      displayTransform={(id, display) => `@${display}`}
                      appendSpaceOnAdd={true}
                      style={defaultMentionStyle}
                    />
                  </MentionsInput>
                  {index > 0 && (
                    <span className="addnote-close">
                      <Box onClick={() => handleDeleteNote(inputText, index)}>
                        {" "}
                        <i class="fas fa-solid fa-close fa-x"></i>{" "}
                      </Box>
                    </span>
                  )}

                  {inputText.error && (
                    <Typography
                      className="error-msge"
                      style={{ color: "#dc3545", fontSize: "14px" }}
                    >
                      {inputText.error}
                    </Typography>
                  )}
                </div>
              ))}
            </Grid>
            

            <Grid xs="12" md="12" lg="12">
              <Box class="mb-3">
                <label for="exampleFormControlInput1" class="form-label">
                  Client name(if applicable)
                </label>

                

                <Select
                  options={dropDownOptions(clientList, "id", "clientName")}
                  placeholder="Client"
                  onChange={handleSelectClient}
                  value={selectedClient}
                  styles={customStyles() }
                  className="users-country-select"
                  maxMenuHeight={300}
                />

              </Box>
            </Grid>
            <Grid xs="12" md="12" lg="12">
              <Box class="mb-3">
                <label for="exampleFormControlInput1" class="form-label">
                  Select policy(if applicable)
                </label>

                
                <Select
                  
                  aria-label="Default select example"
                  placeholder="Select Policy"
                  onChange={(e) => {
                    setSelectedPolicy(e);
                    
                  }}
                  value={selectedPolicy}
                  options={policiesOptions(policies)}
                  styles={customStyles()}
                  filterOption={customFilterOption}
                />

              </Box>
            </Grid>
            <Grid xs="12" md="12" lg="12">
              <Box class="mb-3">
                <label for="exampleFormControlInput1" class="form-label">
                  Due Date
                </label>
                <RenderTextInput
                  value={dueDate}
                  type="Date"
                  onChange={(e) => setDueDate(e.target.value)}
                  placeholder="Due Date"
                  block
                  required
                />
              </Box>
            </Grid>
            <Grid xs="12" md="6" lg="6">
              <Typography variant="span" className="client-text">
                Set reminder
              </Typography>
            </Grid>
            <Grid
              xs="12"
              md="6"
              lg="6"
              sx={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Switch
                onChange={() => setReminder(!reminder)}
                fullWidth
                checked={reminder}
              />
            </Grid>
            {reminder === true && (
              <Grid xs="12" md="12" lg="12">
                <Box class="mb-3 add-note ">
                  <label
                    for="exampleFormControlInput1"
                    className=" add-notetitle client-text mt-1"
                  >
                    Add reminder
                  </label>
                  <Select
                    class="form-select"
                    aria-label="Default select example"
                    onChange={(e) => setSelectedReminder(e)}
                    styles={customStyles()}
                    
                    value={selectedreminder}
                    options={reminderOptions}
                  />
                </Box>
              </Grid>
            )}
            <Grid xs="12" md="12" lg="12">
              <Box class="mb-3">
                <label for="exampleFormControlInput1" class="form-label">
                  Tags
                </label>

                <CreatableSelect
                  options={tagsOption}
                  menuPlacement="auto"
                  isClearable
                  formatCreateLabel={formatCreateLabel}
                  components={{
                    Option: (props) => (
                      <OptionWithDelete
                        {...props}
                        onSelect={props.selectOption}
                        isSelected={props.isSelected}
                        onDelete={handleDelete}
                        backgroundColor={props.data.color}
                      />
                    ),
                    Menu: (props) => (
                      <components.Menu {...props} maxMenuHeight={30}>
                        {props.children}
                      </components.Menu>
                    ),
                  }}
                  onChange={handleSelect}
                  isMulti
                  
                  onInputChange={(e) => handleTagInput(e)}
                  
                  menuPosition="auto"
                  
                  value={selectedTagsValue}
                  styles={{ ...customStyles(error.tag), ...tagStyles(selectedtags) }}
                  
                />
              </Box>
            </Grid>

            {error.tag && (
                      <Box
                        className="error-msge mb-3"
                        style={{ color: "#dc3545", fontSize: "14px" }}
                      >
                        {error.tag}
                      </Box>
                    )}

            <hr />

            <Grid xs="12" md="12" lg="12">
              <Box class="mb-3">
                <label for="exampleFormControlInput1" class="form-label">
                  Assigned to
                </label>

                <Select
                  aria-label="Default select example"
                  styles={customStyles()}
                  
                  onChange={(e) => setAssignedTo(e)}
                  isMulti
                  value={assignedto}
                  options={usersOption}
                  menuPlacement="auto"
                />
              </Box>
            </Grid>
          </GridContainer>
        </Box>
      </Box>
    </Box>
  );
};

export default AddTaskModal;
