import React from "react";

//Material-UI components
import { Box, Typography } from "@mui/material";
import Select from "react-select";


//Custom styles for components
import {
  customStyles,
  usersCountryDropdown,
} from "../../components/common/CustomStyle";
import {
  countriesOptions,
  customFilterOption,
  dropDownOptions,
} from "../../constants"

const FilterSection = ({
  countries,
  clientList,
  handleSelectClient,
  // handleSelectMember,
  // selectedMember,

  selectedClient,
  selectedCountry,
  handleCountryChange,
  selectedTab,

  handleTab,
  memberFirms,
}) => {
  return (
    <Box
      sx={{ width: "100%" }}
      display={"flex"}
      alignItems={"center"}
      height={30}
    >
      <Typography
        variant="span"
        className="view-text me-2 filter-by"
        style={{
          paddingLeft: "0px !important",
          paddingRight: "0px !important",
        }}
      >
        Filter by
      </Typography>
      <Box className="d-flex align-items-center filter-data">
        <Box
          className={selectedTab === "All" ? `active` : ""}
          onClick={() => handleTab("All")}
        >
          All
        </Box>

        {/* <select
          class="form-select map-select users-select"
          aria-label="Default select example"
          value={selectedClient}
          // value={selectedMember}
          onChange={handleSelectClient}
          style={{
            width: "90px",
            maxWidth: "90px",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            paddingRight: "25px",
          }}
        >
          <option value="" selected disabled>
            Client
          </option>
          {clientList?.map((item, key) => {
            return (
              <option value={item.id} style={{ fontWeight: "400" }}>
                {item.clientName}
              </option>
            );
          })}
        </select> */}

        <Typography
          variant="span"
          sx={{ width: "140px", zIndex: "100 !important" }}
        >
          <Select
            options={dropDownOptions(clientList?.length>0?clientList:[], "id", "clientName")}
            placeholder="Client"
            onChange={handleSelectClient}
            value={selectedClient}
            styles={{ ...customStyles(), ...usersCountryDropdown() }}
            className="users-country-select"
            maxMenuHeight={300}
          />
        </Typography>
        <Typography
          variant="span"
          sx={{ width: "140px", zIndex: "100 !important" }}
        >
          <Select
            className="users-country-select"
            options={countriesOptions(countries)}
            placeholder="Country"
            filterOption={customFilterOption}
            onChange={handleCountryChange}
            value={selectedCountry}
            styles={{ ...customStyles(), ...usersCountryDropdown() }}
            dropdownPosition="auto"
            maxMenuHeight={300}
          />
        </Typography>
      </Box>
    </Box>
  );
};
export default FilterSection;
