import React from 'react'
import { Box } from '@mui/material';


function Calendar_Policy({policy,index,left,width,topValue}) {

  const BoxStyle = {
    position: 'absolute !important',
    top: topValue + ` !important`,
    left: `${left}%` + ` !important`,
    // right: `${right}px`,
    width: `${width}%` + ` !important`,
    //backgroundColor:'red',
    // transform: `translateX(${transform}px)`,
  };
  const title = `#${policy.policyNumber}, ${policy.coverageType}, ${policy.startExpiryDate}`
  let policyStatus = policy.policyStatus
  let statusColor = policy.statusColor
  return (
    
      <Box className="subscription-div" sx={BoxStyle} title={title}>
            <Box class="pt-2 d-flex">
            <Box>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                <path d="M3 12.6666L3 3.33328M5.99995 7.99999H14.6666M14.6666 7.99999L10.6666 4M14.6666 7.99999L10.6666 12" stroke="#12B76A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
            </Box>
            <Box class="order-id">#{policy.policyNumber}</Box>
            <Box class="coverage-text ellipsis-text">{policy.coverageType}</Box>
            <Box class="year ellipsis-text">{policy.startExpiryDate}</Box>

            { policy.isActive ? (
                <div className="active" style={{ backgroundColor: statusColor, borderRadius: "30px", padding: "3px 4px" }}>
                  {policyStatus}
                </div>
              ) : (
                <div className="inactive" style={{ backgroundColor: 'grey', borderRadius: "30px", padding: "3px 4px" }}>
                  In-Active
                </div>
              )
            }

            </Box>
            <Box class="end-icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                <path d="M2 7.99999H10.6667M10.6667 7.99999L6.66669 4M10.6667 7.99999L6.66669 12M14 12.6666V3.33328" stroke="#F04438" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
            </Box>
      </Box>
    
  )
}

export default Calendar_Policy