

import {ACTION_TYPES} from "../actions/ActionTypes"

const Reducer = (state = null, action) => {
    switch (action.type) {
        case ACTION_TYPES.SET_CLIENT_DATA:
            return {
                ...state,
                clientdata:action.payload
            }
        case ACTION_TYPES.SET_CLIENT_DATA_FAILED:
            return {
                ...state,
                clientError:action.payload
            }
        case ACTION_TYPES.SET_CLIENT_LIST:
            return {
                ...state,
                clientListData:action.payload
            }
        case ACTION_TYPES.SET_CLIENT_DETAILS:
            // return action.payload
        
            return {
                ...state,
                clientDetailsData:action.payload
            }
        case ACTION_TYPES.ADD_NEW_POLICY:
            return {
                ...state,
                addpolicyData:action.payload
            }
        case ACTION_TYPES.GET_MEMBERS_DATA:
            // return action.payload
            return {
                ...state,
                memberData:action.payload?.data
            }
        case ACTION_TYPES.SET_COVERAGE_TYPES:
            // return action.payload
            return {
                ...state,
                coverageTypes:action.payload
            }
        case ACTION_TYPES.SET_CURRENCIES_DATA:
            // return action.payload
            return {
                ...state,
                currenciesData:action.payload.data
            }
        case ACTION_TYPES.SET_LANGUAGES_DATA:
            // return action.payload
            return {
                ...state,
                laguagesData:action.payload
            }
        case ACTION_TYPES.SET_POLICY_TYPES_DATA:
            // return action.payload
            return {
                ...state,
                policyTypesData:action.payload
            }
        case ACTION_TYPES.SET_INSURE_DATA:
            // return action.payload
            return {
                ...state,
                insureData:action.payload
            }
        case ACTION_TYPES.SET_COVERAGE_DATA:
            // return action.payload
            return {
                ...state,
                coverageData:action.payload
            }
        case ACTION_TYPES.SET_COVERAGE_TYPE_BY_ID_DATA:
            // return action.payload
            return {
                ...state,
                coverageTypeById:action.payload
            }
        case ACTION_TYPES.SET_CANCEL_POLICY_DATA:
            
            return {
                ...state,
                cancelPolicyData:action.payload
            }
        case ACTION_TYPES.SET_RENEWAL_POLICY_DATA:
            
            return {
                ...state,
                renewalPolicyData:action.payload
            }
        case ACTION_TYPES.SET_REVOKE_CANCELLATION_DATA:
            
            return {
                ...state,
                revokeCancellationData:action.payload
            }
        case ACTION_TYPES.SET_FIELD_DATA:
            
            return {
                ...state,
                addedfielddata:action.payload
            }
        case ACTION_TYPES.SET_COVERAGE_DETAILS_DATA:
            
            return {
                ...state,
                coverageDetailsData:action.payload
            }
        case ACTION_TYPES.SET_POLICY_DETAILS:
            
            return {
                ...state,
                policyDetailsData:action.payload
            }
        case ACTION_TYPES.GET_CLIENT_LIST:
            
            return {
                ...state,
                clientListData:action.payload
            }
        case ACTION_TYPES.GET_CLIENT_LOCATION:
            
            return {
                ...state,
                clientLocationData:action.payload
            }
        case ACTION_TYPES.GET_CLIENT_LOCATION_DETAILS:
            
            return {
                ...state,
                clientLocationdetailsData:action.payload
            }
        case ACTION_TYPES.SET_ADD_LOCATION:
            
            return {
                ...state,
                addlocationData:action.payload
            }
        case ACTION_TYPES.SET_DELETED_LOCATION:
            
            return {
                ...state,
                deletelocationData:action.payload
            }
        case ACTION_TYPES.SET_UPDATE_LOCATION:
            
            return {
                ...state,
                updatelocationData:action.payload
            }
        case ACTION_TYPES.GET_CLIENT_LOCATIONS:
            
            return {
                ...state,
                clientLocationsData:action.payload
            }
        
        case ACTION_TYPES.SET_CLIENT_POLICIES:
            
            return {
                ...state,
                clientPoliciesData:action.payload
            }
        case ACTION_TYPES.SET_MEMBER_FIRM_CLIENT:
            
            return {
                ...state,
                memberfirmclient:action.payload
            }
            case ACTION_TYPES.GET_CLIENT_MEMBER:
            
            return {
                ...state,
                clientMemberData:action.payload
            }
        case ACTION_TYPES.GET_CLIENT_MEMBER:
            
            return {
                ...state,
                clientMemberData:action.payload
            }
        case ACTION_TYPES.UPDATED_POLICY_DATA:
            
            return {
                ...state,
                updatedPolicyData:action.payload
            }
        case ACTION_TYPES.GET_CLIENT_DETAILS_BY_ID:
            
            return {
                ...state,
                clientDetailsByid:action.payload
            }
        case ACTION_TYPES.GET_POLICY_DETAILS_BY_ID:
            
            return {
                ...state,
                policyDetailsByid:action.payload
            }
        case ACTION_TYPES.UPDATE_CLIENT_SUCCESS:
            return {
                ...state,
                updatedClientData:action.payload
            }
        
            case ACTION_TYPES.DELETE_DOCUMENT_SUCCESS:

            return {
                ...state,
                deletedDocument:action.payload
            }
            case ACTION_TYPES.DELETE_POLICY_SUCCESS:

            return {
                ...state,
                deletedPolicyData:action.payload
            }
            case ACTION_TYPES.ARCHIVE_MULTIPLE_CLIENTS:

            return {
                ...state,
                archivedMutipleClientsData:action.payload
            }
            case ACTION_TYPES.SET_BROKER_FOR_POLICY:

            return {
                ...state,
                brokerforpolicyData:action.payload
            }
            case ACTION_TYPES.SET_UPDATE_FIELD_DATA:

            return {
                ...state,
                updateCoverageFiledData:action.payload
            }
            case ACTION_TYPES.SET_DOCUMENTS_FOR_POLICY:

            return {
                ...state,
                documentsForPolicyData:action.payload
            }
            case ACTION_TYPES.SET_CLIENT_MAP_DATA:

            return {
                ...state,
                clientMapData:action.payload
            }
            case ACTION_TYPES.SET_CLIENT_CALENDAR_DATA:

            return {
                ...state,
                clientCalendarData:action.payload
            }
            case ACTION_TYPES.SET_CLIENT_TOTAL_COST_DATA:

            return {
                ...state,
                clientTotalCostData:action.payload
            }
            case ACTION_TYPES.SET_CLIENT_RENEWAL_STATUS_DATA:

            return {
                ...state,
                clientRenewalStatusData:action.payload
            }
            case ACTION_TYPES.SET_ADD_INSURER_DATA:

            return {
                ...state,
                addInsurerData:action.payload
            }
            case ACTION_TYPES.SET_ADD_EXCLUSION_DATA:

            return {
                ...state,
                addExclusionData:action.payload
            }
            case ACTION_TYPES.SET_POLICY_EXCLUSION_DATA:

            return {
                ...state,
                policyExclusionData:action.payload
            }
            case ACTION_TYPES.SET_SEARCH_CLIENTS_DATA:

            return {
                ...state,
                searchClientsData:action.payload
            }
            
        default: return state
    }
}
export default Reducer