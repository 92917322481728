import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

//Material-UI components
import { Box, Grid } from "@mui/material";

//Custom MUI components
import PageTitle from "../../components/PageTitle";

import Table from "../../components/Table";
import GridContainer from "../../components/GridContainer";
import ButtonComponent from "../../components/Button";

import DrawerComponent from "../../components/Drawer";

import FilterSection from "./FilterTasks";
import AddTaskModal from "./AddTask";
import ViewTask from "./ViewTask";

import { TASKS_COLUMNS, dateFormat } from "../../constants";

import ConfirmationModal from "../../components/common/ConfirmationModal";

//actions
import { getCountries } from "../../store/actions/CountriesAction";
import { getClientList } from "../../store/actions/ClientAction";

import {
  DeleteMultipleTasks,
  
  TaskComplete,
  TaskDelete,
  getReminder,
  getTags,
  getTaskByid,
  getTasksList,
} from "../../store/actions/TasksAction";
import { getUserByFilter, getUserList  , getUserListInTasks} from "../../store/actions/UserAction";
import { useLocation, useNavigate } from "react-router-dom";

const TasksList = () => {
  //state variables declaration
  const [selectedCountry, setSelectedCountry] = useState([]);

  const [countries, setCountries] = useState([]);
  const [clientList, setClientList] = useState([]);

  const [tasksList, setTasksList] = useState([]);
  const [selectedClient, setselectedClient] = useState("");
  const [selectedtag, setSelectedtag] = useState("");

  const [usersList, setUsersList] = useState([]);
  const [taskDetails, settaskDetails] = useState("");

  const [showAddTask, setShowAddTask] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [taskid, settaskId] = useState([]);
  const [showViewTask, setShowViewTask] = useState(false);
  const [tags, setTags] = useState([]);
  const [loggedInUserDetails, setLoggedInUserDetails] = useState("");
  const [activetab, setActivetab] = useState("All");
  const [showEditTask, setShowEditTask] = useState(false);
  const [page, setPage] = useState("");
  const [allChecked, setAllChecked] = useState(false);
  const [disableCheckbox, setDisableCheckbox] = useState(true)
  const [sortASC, setSortASC] = useState(false)
  const [sortName, setSortName] = useState("")
  const [taskCopyDetals, setTaskCopyDetails] = useState("");

  //extract specific pieces of state from the Redux store
  const statedata = useSelector((state) => {
    return {
      countries: state?.countries?.Countries,
      clientListData: state?.fetchData?.clientListData,
      tasksListData: state?.tasks?.tasksList,
      createtask: state?.tasks?.createdtask,
      taskbyiddata: state?.tasks?.taskByiddata,
      taskmarkcompleted: state?.tasks?.taskCompletedata,
      updatedtaskdata: state?.tasks?.updatedtasksdata,
      deletedtask: state?.tasks?.taskdeletedata,
      userList: state?.user?.usersList,
      tagsData: state?.tasks?.tagsdata,
      deletedMultipleTasksdata: state?.tasks?.deletedMultipleTasksdata,
      tasksFiltereddata: state?.tasks?.tasksFiltereddata,
    };
  });

  const dispatch = useDispatch();

  const location = useLocation();
  const navigate = useNavigate()
  const showModal = location.state && location.state.showModal
  const taskId = location.state && location.state.taskId

  // Fetch data from Redux store on component mount

  useEffect(()=> {
    if(showModal) {
      setShowAddTask(true)
      navigate({ pathname: location.pathname })
    }
  } , [showModal])
  
  useEffect(()=> {
    if(taskId && location.state.from === "tasks-dashboard") {
      handleTaskDetails(taskId)

      navigate({ pathname: location.pathname })
      
    }
  } , [taskId])
  useEffect(() => {
    // dispatch(getTasksList());
    let payload = {
      isCompleted: false,
      isSort:false,
      sortName:"",
      sortBy:"",
      filterName:"All",
      tagId:"",
      clientId:"",

    }

    let clientpayload = {
      filterName:"activeOnly",
      isDraft:false,

    }
    
    dispatch(getTasksList(payload));
    dispatch(getCountries());
    dispatch(getClientList(clientpayload));

    

    

    
    dispatch(getUserListInTasks())
    dispatch(getTags());
    dispatch(getReminder());
    setLoggedInUserDetails(JSON.parse(localStorage.getItem("user")));
  }, [dispatch]);

  //update tasksList
  useEffect(() => {
    if (statedata?.tasksListData?.data?.dashboardTasks?.nodes) {
      let tasks = statedata?.tasksListData?.data?.dashboardTasks?.nodes
        ?.map((item, key) => {
          // if (!item.isCompleted) {
            return {
              ...item,
              dueDate: dateFormat(item.dueDate),
            };
          // }
          return null; // Return null for items that don't meet the condition
        })
        .filter((item) => item !== null) // Filter out the null values
      setTasksList(tasks)
    }
  }, [statedata?.tasksListData?.data?.dashboardTasks?.nodes]);

  useEffect(() => {
    if (statedata?.tagsData?.data?.tags?.nodes) {
      setTags(statedata?.tagsData?.data?.tags?.nodes);
    }
  }, [statedata?.tagsData?.data?.tags?.nodes]);

  useEffect(() => {
    if (statedata?.tasksFiltereddata?.data?.dashboardTasks?.nodes) {
      setDisableCheckbox(false)
      let tasks = statedata?.tasksFiltereddata?.data?.dashboardTasks?.nodes
        ?.map((item, key) => {
          return {
            ...item,
            dueDate: dateFormat(item.dueDate),
          };
        })
        .filter((item) => item !== null);
      setTasksList(tasks);
    }
  }, [statedata?.tasksFiltereddata?.data?.dashboardTasks?.nodes]);

  const handleFilterChange = (type) => {

    
    
    const defaultPayload = {
      isSort: false,
      sortName: "",
      sortBy: "",
      // clientId: selectedClient?.value,
      // tagId: selectedtag?.value,

    };
  
    let payload = { ...defaultPayload };
  
    let clientId = type === "All" ? "": selectedClient?.value !== "" ? selectedClient?.value :""
    let tagId = type === "All" ? "": selectedtag?.value!==""? selectedtag?.value:""

    handleAction(payload , type , clientId , tagId)
  
    setDisableCheckbox(true);
    setAllChecked(false);
    setSortASC(false);
    setSortName("");
    settaskId([]);
    setTasksList([]);
    
    if(type === "All") {
      setselectedClient("")
      setSelectedtag("")
      
    }

  }

  useEffect(() => {
    if (statedata?.userList) {
      setUsersList(statedata?.userList?.data);
    }
  }, [statedata?.userList]);

  //Effect to update clientsList state when clientListData changes in Redux store

  useEffect(() => {
    if (statedata?.clientListData?.data?.clients_List?.nodes) {
      setClientList(statedata?.clientListData?.data?.clients_List?.nodes);
    }
  }, [statedata?.clientListData?.data?.clients_List?.nodes]);

  //Effect to update taskdetails state when taskbyiddata changes in Redux store

  useEffect(() => {
    if (statedata?.taskbyiddata?.data) {
      settaskDetails(statedata?.taskbyiddata?.data);
    }
  }, [statedata?.taskbyiddata?.data]);

  useEffect(() => {
    if (statedata?.taskmarkcompleted === "success") {
      setAllChecked(false)
      settaskId([]);
      setShowViewTask(false);
      // dispatch(getTasksList());
      if(activetab !== "MyCreatedTask") {
        let payload = {
          isCompleted: false,
          isSort:false,
          sortName:"",
          sortBy:"",
          filterName:"All",
          tagId:"",
          clientId:"",

        }
        dispatch(getTasksList(payload));
      // dispatch(FilterTaskByisComplete(false))

      
      setActivetab("All");
      }
      else {
        let payload = {
          isCreatedBy: true,
          isSort:false,
          sortName:"",
          sortBy:"",
          filterName:"MyCreatedTask",
          tagId:"",
          clientId:"",

        }

        dispatch(getTasksList(payload))
      }

      setselectedClient("");
      setSelectedtag("");
    }
  }, [statedata?.taskmarkcompleted]);

  useEffect(() => {
    if (statedata?.updatedtaskdata === "success") {
      setAllChecked(false)
      setDisableCheckbox(true)
      setShowEditTask(false);
      // dispatch(getTasksList());
      let payload = {
        isCompleted: false,
        isSort:false,
        sortName:"",
        sortBy:"",
        filterName:"All",
        tagId:"",
        clientId:"",

      }
      dispatch(getTasksList(payload));
      // dispatch(FilterTaskByisComplete(false))
      setActivetab("All");

      setselectedClient("");
      setSelectedtag("");
    }
  }, [statedata?.updatedtaskdata]);

  useEffect(() => {
    if (statedata?.deletedtask === "success") {
      setAllChecked(false)
      setDisableCheckbox(true)
      setShowViewTask(false);
      setShowConfirmation(false);
      settaskId([]);
      // dispatch(getTasksList());
      let payload = {
        isCompleted: false,
        isSort:false,
        sortName:"",
        sortBy:"",
        filterName:"All",
        tagId:"",
        clientId:"",

      }
      dispatch(getTasksList(payload));
      // dispatch(FilterTaskByisComplete(false))
      if(activetab !== "MyCreatedTask") {
      setActivetab("All");
      }

      setselectedClient("");
      setSelectedtag("");
    }
  }, [statedata?.deletedtask]);
  useEffect(() => {
    if (statedata?.deletedMultipleTasksdata === "success") {
      setAllChecked(false)
      setDisableCheckbox(true)
      setShowConfirmation(false);
      settaskId([]);
      // dispatch(getTasksList());
      let payload = {
        isCompleted: false,
        isSort:false,
        sortName:"",
        sortBy:"",
        filterName:"All",
        tagId:"",
        clientId:"",

      }
      dispatch(getTasksList(payload));
      // dispatch(FilterTaskByisComplete(false))
      if(activetab!=="MyCreatedTask") {
      setActivetab("All");
      }

      setselectedClient("");
      setSelectedtag("");
    }
  }, [statedata?.deletedMultipleTasksdata]);

  useEffect(() => {
    if (statedata?.createtask === "success") {
      setAllChecked(false)
      setDisableCheckbox(true)
      setShowAddTask(false);

      // dispatch(getTasksList());
      let payload = {
        isCompleted: false,
        isSort:false,
        sortName:"",
        sortBy:"",
        filterName:"All",
        tagId:"",
        clientId:"",

      }
      dispatch(getTasksList(payload));
      // dispatch(FilterTaskByisComplete(false))
      setActivetab("All");
      setselectedClient("");
      setSelectedtag("");
      settaskId([])
    }
  }, [statedata?.createtask]);

  //change client
  const handleSelectClient = (event) => {
    setAllChecked(false)
    setDisableCheckbox(true)
    setDisableCheckbox(true)
    
    // setselectedClient(event.target.value);
    setselectedClient(event);
    
    setTasksList([]);
    
    let payload = {
      isCompleted: false,
        isSort:false,
        sortName:"",
        sortBy:"",
        filterName:"AllAndClients",
        // id:event.target.value
        clientId:event?.value?event?.value:"",
        tagId:selectedtag?.value,
        filterName: activetab,
        
        

    }
    dispatch(getTasksList(payload));
  
  };

  const handleTag = (event) => {
    setAllChecked(false)
    setDisableCheckbox(true)
    
    // setSelectedtag(event.target.value);
    setSelectedtag(event);
    // setActivetab("All")
    
    setTasksList([]);

    
    let payload = {
      
      // isCompleted: false,
        isSort:false,
        sortName:"",
        sortBy:"",
        
        tagId:event?.value?event?.value:"",
        clientId:selectedClient?.value,
        filterName: activetab,
        


    }
    dispatch(getTasksList(payload));
  
    
  };

  const handleCheckboxChange = (event) => {
    const elementId = event.id;

    // Check if the ID already exists in the array
    if (taskid.includes(elementId)) {
      // If it exists, remove it
      let filteredIds = taskid.filter((existingId) => existingId !== elementId);
      settaskId(filteredIds);
    } else {
      // If it doesn't exist, add it to the array
      let updatedIds = [...taskid, elementId];
      settaskId(updatedIds);
    }
  };

  const handleCloseConfirmation = () => {
    setShowConfirmation(false);
  };

  const handleConfirm = () => {
    let payload = {
      Ids: taskid ? taskid : [],
      ActionPerformedBy: loggedInUserDetails?.sub
        ? loggedInUserDetails?.sub
        : "",
    };

    dispatch(DeleteMultipleTasks(payload));
  };

  const handleDelete = () => {
    setShowConfirmation(true);
  };

  const handlemark = () => {
    let checkcompleted = tasksList?.find?.(
      (item, key) => item.id === taskid?.[0]
    );
    let completed = checkcompleted?.isCompleted;

    let payload = {
      Id: taskid?.[0] ? taskid?.[0] : "",
      IsCompleted: completed === true ? false : true,
      ActionPerformedBy: loggedInUserDetails?.sub
        ? loggedInUserDetails?.sub
        : "",
    };
    dispatch(TaskComplete(payload));
  };

  const checkIsdraft = (id) => {
    let task = tasksList?.find?.((item, key) => item.id === id?.[0]);

    if (task && task.isDraft === false) {
      return true;
    } else {
      return false;
    }
  };
  //get task details
  const handleTaskDetails = (id) => {
    settaskDetails("");

    setShowViewTask(true);

    dispatch(getTaskByid({ id: id }));
  };

  const handleCopytask = () => {
    setTaskCopyDetails("");
    let taskscopy =
      tasksList?.filter(
        (item, key) => item.id === taskid?.[0]
      );

    let taskdetails = taskscopy?.map((item, key) => {
      return {
        task: item.title,
        description: item.description,
        dueDate: item.dueDate ? dateFormat(item.dueDate) : "",
        clientId: item.clientId,
      };
    });

    setTaskCopyDetails(taskdetails?.[0]);

    setPage("copy");

    setShowAddTask(true);
  };
  const handleDuplicateTask = (taskdetails) => {
    setTaskCopyDetails("");
    taskdetails.task = taskdetails.title

    

    setTaskCopyDetails(taskdetails);

    setPage("copy");
    setShowViewTask(false)
    setShowAddTask(true);
  };

  useEffect(()=> {

    const allTaskIds = tasksList?.map((item) => item.id) || []

    if (!allChecked) {
      settaskId([]);
    } else {

      settaskId(allTaskIds);
      
    }
  } , [allChecked])

  
  useEffect(() => {
    if (sortName) {
      const payload = {
        isSort: true,
        sortBy: sortASC ? "ASC" : "DESC",
        sortName: sortName
      };

      let clientId = activetab === "All" ? "": selectedClient?.value!==""? selectedClient?.value:""
      let tagId = activetab === "All" ? "": selectedtag?.value!==""?  selectedtag?.value:""

    handleAction(payload , activetab , clientId , tagId)
      
    }
  }, [sortASC , sortName])

  const handleSort = (e) => {
    if(e===sortName){
    setSortASC(!sortASC)
    setSortName(e)
    }
    else {
      setSortASC(true)
      setSortName(e)
    }
  }

  const handleAction = (payload , tab , clientId , tagId) => {
    switch (tab) {
      case "All":
        payload.isCompleted= false
        payload.filterName="All"
        payload.clientId=clientId
        payload.tagId=tagId
        dispatch(getTasksList(payload));
        break;
      case "Completed":
        payload.isCompleted = true
        payload.filterName="Completed"
        payload.clientId=clientId
        payload.tagId=tagId
        dispatch(getTasksList(payload));
        break;
      case "Draft":
        payload.isDraft = true
        payload.filterName="Draft"
        payload.clientId=clientId
        payload.tagId=tagId
        dispatch(getTasksList(payload));
        break;
      case "Upcoming":
        payload.isUpcoming = true
        payload.filterName="Upcoming"
        payload.clientId=clientId
        payload.tagId=tagId
        dispatch(getTasksList(payload));
        break;
      case "MyCreatedTask":
        payload.isCreatedBy = true
        payload.filterName="MyCreatedTask"
        payload.clientId=clientId
        payload.tagId=tagId
        dispatch(getTasksList(payload));
        break;
      case "AssignedToMe":
        payload.isCompleted = false
        payload.filterName="AssignedToMe"
        payload.clientId=clientId
        payload.tagId=tagId
        dispatch(getTasksList(payload));
        break;
      default:
        break;
    }
  }
  const handleCheckAllTasks = () => {
    setAllChecked(!allChecked)
    

  }

  const renderButton = () => {
    let task = tasksList?.find?.((item, key) => item.id === taskid?.[0]);
    return task.isCompleted ? "Unmark Complete" : "Mark Complete";
  };

  return (
    <Box id="main" className="main">
      {showConfirmation && (
        <ConfirmationModal
          showConfirmation={showConfirmation}
          handleCloseConfirmation={handleCloseConfirmation}
          handleConfirm={handleConfirm}
          title={"Task"}
          subTitle={
            taskid?.length === 1 ? "delete this task" : "delete selected tasks"
          }
          buttonname="Delete"
        />
      )}
      <GridContainer sx={{ marginBottom: "30px !important" }}>
        <Grid xs={12} md={11} lg={11}>
          <PageTitle title={"Tasks"} />
        </Grid>
        <Grid xs={12} md={1} lg={1}>
          <ButtonComponent
            variant={"contained"}
            color={"grey"}
            onClick={() => {
              setShowAddTask(true);
              setPage("add");
            }}
            sx={{ float: "right" }}
          >
            New task
          </ButtonComponent>
        </Grid>

        {/* Add task */}
        {showAddTask && (
          <DrawerComponent
            isOpen={showAddTask}
            handleClose={() => setShowAddTask(false)}
            width={350}
            body={
              <AddTaskModal
                showAddTask={showAddTask}
                handleClose={() => setShowAddTask(false)}
                clientList={clientList}
                userList={usersList}
                page={page}
                loggedInUserDetails={loggedInUserDetails}
                taskDetails={taskCopyDetals}
              />
            }
          />
        )}

        {/* View  task */}
        {showViewTask && (
          <DrawerComponent
            isOpen={showViewTask}
            handleClose={() => setShowViewTask(false)}
            width={350}
            body={
              <ViewTask
                showAddTask={showViewTask}
                handleClose={() => setShowViewTask(false)}
                handleEdit={() => {
                  setShowViewTask(false);
                  setShowEditTask(true);
                  setPage("edit");
                }}
                taskDetails={taskDetails}
                userList={usersList}
                loggedInUserDetails={loggedInUserDetails}
                handleDuplicate={handleDuplicateTask}
              />
            }
          />
        )}

        {/*  Edit task */}
        {showEditTask && (
          <DrawerComponent
            isOpen={showEditTask}
            handleClose={() => setShowEditTask(false)}
            width={350}
            body={
              <AddTaskModal
                showAddTask={showEditTask}
                handleClose={() => setShowEditTask(false)}
                clientList={clientList}
                taskDetails={taskDetails}
                page={page}
                userList={usersList}
                loggedInUserDetails={loggedInUserDetails}
              />
            }
          />
        )}
      </GridContainer>

      {/* Filter and search section */}
      <Box className="section client-dashboard">
        <GridContainer>
          <Grid xs={12} md={12} lg={12}>
            <Box className="dashboard-content">
              <Box className="dashboard-main-head row ms-0 me-0">
                <GridContainer
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Grid
                    xs={12}
                    md={12}
                    lg={12}
                    className=" d-flex align-items-center"
                  >
                    <FilterSection
                      clientList={clientList}
                      handleSelectClient={handleSelectClient}
                      selectedClient={selectedClient}
                      // handleCompleted={handleCompleted}
                      handleChange={handleFilterChange}
                      tags={tags}
                      activetab={activetab}
                      handleactivetab={(e) => setActivetab(e) }
                      handleTag={handleTag}
                      selectedtag={selectedtag}
                    />
                  </Grid>
                </GridContainer>
              </Box>
            </Box>
          </Grid>
        </GridContainer>
      </Box>

      <Table
        columns={TASKS_COLUMNS}
        rows={tasksList}
        handleRow={(e) => handleTaskDetails(e)}
        from="tasks"
        handleChange={handleCheckboxChange}
        checkedId={taskid}
        handleCheckAllCheckbox={handleCheckAllTasks}
        allChecked={allChecked}
        disableCheckbox={disableCheckbox}
        handleSort={(e)=> handleSort(e)}
      />

      {taskid?.length > 0 && (
        <Box class="group-float">
          <img
            src="/wwwroot/assets/images/trash-2.png"
            className="img-fluid mr-10"
            alt="help circle"
            onClick={handleDelete}
            style={{ cursor: "pointer" }}
          />

          <img
            src="/wwwroot/assets/images/copy.png"
            className="img-fluid mr-10"
            alt="help circle"
            style={{ cursor: taskid?.length > 1 ? "" : "pointer" }}
            onClick={() => (taskid?.length === 1 ? handleCopytask() : {})}
          />
          {checkIsdraft(taskid) && (
            <ButtonComponent
              variant={taskid?.length > 1 ? "outlined" : "contained"}
              color={taskid?.length > 1 ? "white" : "grey"}
              disabled={taskid?.length > 1}
              type="buttom"
              class="btn btn-default greycontained"
              onClick={() => handlemark()}
            >
              {/* Mark complete */}
              {renderButton()}
            </ButtonComponent>
          )}
        </Box>
      )}
    </Box>
  );
};
export default TasksList;
