import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import ClientDashboard from "./pages/client/ClientDashboard";

import Map from "./pages/map/Map";
import Calendar from "./pages/calendar/Calendar";
import { Paths } from "./constants";
import UsersList from "./pages/users";
import Header from "./components/layout/Header";
import SideBar from "./components/layout/SideBar";
import TasksList from "./pages/tasks"
import MemberFirmList from "./pages/memberFirm";
import Dasboard from "./pages/dasboard";
// import Reports from "./pages/reports";
import HealthCheck from "./healthCheck";

function RoutesComponent() {

  const [loggedInUserDetails , setLoggedInUserDetails] = useState("")

  useEffect(()=> {
    setLoggedInUserDetails(JSON.parse(localStorage.getItem("user")))
  } , [])

  return (
    <Router>
      {window.location.pathname?.substring(1)!== "healthz"?
      <>
      <Header />
      <SideBar />
      </>
      :""}
      <>
        <Routes>
          <Route path={Paths.CLIENT_DASHBOARD} element={<ClientDashboard />} />
          <Route path={Paths.MAP} element={<Map />} />
          <Route path={Paths.CALENDAR} element={<Calendar />} />
          {/* <Route path={Paths.USERS} element={<UsersList />} /> */}
          {loggedInUserDetails?.extension_Role !== "MemberContributor" && (
            <Route path={Paths.USERS} element={<UsersList />} />
          )}

          <Route path={Paths.TASKS} element={<TasksList />} />

          <Route path={Paths.MEMBER_FIRM} element={<MemberFirmList />} />
          
          <Route path={Paths.HOME} element={<Dasboard />} />
          {/* <Route path={Paths.REPORTS} element={<Reports />} /> */}
          <Route path={Paths.HEALTHCHECK} element={<HealthCheck />} />

          <Route path={Paths.CLIENT_DASHBOARD} />
          <Route path="*" element={<Navigate to={Paths.HOME} />} />
        </Routes>
      </>
    </Router>
  );
}

export default RoutesComponent;
