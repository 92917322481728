import React, { useEffect, useState } from "react";

//Material-UI components
import { Box, Typography } from "@mui/material";
import { dropDownOptions } from "../../constants";

import Select from "react-select";
import {
  customStyles,
  usersCountryDropdown,
} from "../../components/common/CustomStyle"

const FilterSection = ({
  countries,
  clientList,
  handleSelectClient,
  selectedClient,
  selectedCountry,
  handleCountryChange,
  handleCompleted,
  handleChange,
  tags,
  handleactivetab,
  activetab,
  handleTag,
  selectedtag,

  


}) => {
  const [active , setActive] = useState("")

  useEffect(()=> {
      setActive(activetab)
  } , [activetab])
  
  
  return (
    <Box
      sx={{ width: "100%" }}
      display={"flex"}
      alignItems={"center"}
      height={30}
    >
      <Typography
        variant="span"
        className="view-text me-2 filter-by"
        style={{
          paddingLeft: "0px !important",
          paddingRight: "0px !important",
        }}
      >
        Filter by
      </Typography>
      <Box
        className="d-flex align-items-center filter-data"
        sx={{ width: "93%", maxWidth: "fit-content" }}
      >
        <Box className={active === "All"?`active tasks-texts`:"tasks-texts"} sx={{ padding: "4px 10px !important" }} onClick={()=> {handleChange("All") ; handleactivetab("All")}}>
          All
        </Box>
        <Box className={active === "MyCreatedTask"?"active tasks-texts" : `tasks-texts`} sx={{ padding: "4px 10px !important" }} onClick={()=> {handleChange("MyCreatedTask") ; handleactivetab("MyCreatedTask")}}>Created by me</Box>
        <Box className={active === "AssignedToMe"?"active tasks-texts" : `tasks-texts`} sx={{ padding: "4px 10px !important" }} onClick={()=> {handleChange("AssignedToMe") ; handleactivetab("AssignedToMe")}}>Assigned to me</Box>
        {/* <select
          class="form-select map-select users-select ms-0"
          aria-label="Default select example"
          value={selectedClient}
          onChange={handleSelectClient}
          style={{
            width: "90px",
            maxWidth: "90px",
            overflow: "hidden !important",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            paddingRight: "25px",
          }}
          maxMenuHeight={10}
        >
          <option value="" selected disabled>Client</option>
          {clientList?.map((item, key) => {
            return <option value={item.id} style={{fontWeight:'400'}}>{item.clientName}</option>;
          })}
        </select> */}

        <Typography
          variant="span"
          sx={{ width: "140px", zIndex: "100 !important" }}
        >
          <Select
            options={dropDownOptions(clientList, "id", "clientName")}
            placeholder="Client"
            onChange={handleSelectClient}
            value={selectedClient}
            styles={{ ...customStyles(), ...usersCountryDropdown() }}
            className="users-country-select"
            maxMenuHeight={300}
          />
        </Typography>

        {/* <select
          class="form-select map-select users-select ms-0"
          aria-label="Default select example"
          value={selectedtag}
          onChange={handleTag}
          style={{
            width: "110px",
            maxWidth: "110px",
            overflow: "hidden !important",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            paddingRight: "25px",
          }}
          maxMenuHeight={10}
        >
          <option value="" selected disabled>Tags</option>
          {tags?.map((item, key) => {
            return <option value={item.id} style={{fontWeight:'400'}}>{item.name}</option>;
          })}
        </select> */}

        <Typography
          variant="span"
          sx={{ width: "140px", zIndex: "100 !important" }}
        >
          <Select
            options={dropDownOptions(tags, "id", "name")}
            placeholder="Tags"
            onChange={handleTag}
            value={selectedtag}
            styles={{ ...customStyles(), ...usersCountryDropdown() }}
            className="users-country-select"
            maxMenuHeight={300}
          />
        </Typography>

        <Box className= {active === "Upcoming" ? "active tasks-texts" : "tasks-texts"} sx={{ padding: "4px 10px !important" }} onClick={()=> {handleChange("Upcoming") ; handleactivetab("Upcoming")}}>Upcoming</Box>

        <Box className= {active === "Draft" ? "active tasks-texts" : "tasks-texts"} sx={{ padding: "4px 10px !important" }} onClick={()=> {handleChange("Draft") ; handleactivetab("Draft")}}>Drafts</Box>

        {/* <Box className="tasks-texts">Hide</Box> */}
        <Box className={active === "Completed"?"active tasks-texts" : `tasks-texts`} sx={{ padding: "4px 10px !important" }} onClick={()=> {handleChange("Completed") ; handleactivetab("Completed")}}>Completed</Box>
        
      </Box>
    </Box>
  );
};
export default FilterSection;
