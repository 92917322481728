import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import PageTitle from "../../components/PageTitle";
import GridContainer from "../../components/GridContainer";
import QuickLinks from "./QuickLinks";
import RecentContacts from "./RecentContacts";
import ClientsCollaborations from "./ClientsCollaborations";
import Tasks from "./Tasks";
import HomeDialog from "./HomeDialog";
import Dialog from "../../components/Dialog";
import SwipeableTextMobileStepper from "./HomeCarousel";
import { useDispatch, useSelector } from "react-redux";
import { changeFirstLogin } from "../../store/actions/UserAction";
import { recentContactData } from "../../store/actions/ContactsAction";
import { getActiveClientsList, getFrequentCollaboratorsList } from "../../store/actions/Dashboard";
import { getCountries } from "../../store/actions/CountriesAction";
import { Paths, clearSession } from "../../constants";
import { useNavigate } from "react-router-dom";
import PermissionModal from "./PermissionModal";
import { useMsal } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser"

const Dasboard = () => {
  const [showHomeModal, setShowHomeModal] = useState(false);
  const [recentContactsData, setRecentContactsData] = useState([]);
  const [frequentCollaborators, setFrequentCollaborators] = useState([]);
  const [activeClients, setActiveClients] = useState([]);
  const [countries, setCountries] = useState([]);
  const [showMessagePopup , setShowMessagePopup] = useState(false)

  const { instance , inProgress } = useMsal()

  const stateData = useSelector((state) => {

    return {
      changeFirstLogin: state?.user?.changeFirstLogin,
      recentcontactData: state?.contact?.recentcontactData,
      frequentCollaboratorsList: state?.dasboard?.frequentCollaboratorsList,
      countries: state?.countries?.Countries,
      activeClientsList: state?.dasboard?.activeClientsList,

    };
  });
  const dispatch = useDispatch();

  useEffect(() => {
    let userDetails = JSON.parse(localStorage.getItem("user"));

    let firstLogin = localStorage.getItem("firstLogin");

    if(userDetails?.extension_Role) {
      if(firstLogin) {
        if(firstLogin !=="true" && userDetails?.extension_Role) {
          setShowHomeModal(true)
        }
      }
      else {
        if (
          (userDetails?.extension_FirstLogin === true ||
          userDetails?.newUser === true ) && (userDetails?.extension_Role)
        ) {
          setShowHomeModal(true);
        }
      }
    }
    else {
      setShowMessagePopup(true)
    }

  }, []);

  useEffect(() => {
    dispatch(recentContactData());
    dispatch(getFrequentCollaboratorsList());
    dispatch(getActiveClientsList())
    dispatch(getCountries());
  }, []);
  useEffect(() => {
    if (stateData?.changeFirstLogin) {
      localStorage.setItem("firstLogin", true);
      setTimeout(()=> window.location.reload() , 100)
    }
  }, [stateData?.changeFirstLogin]);
  useEffect(() => {
    if (stateData?.recentcontactData?.data?.recentContacts) {
      let recentcontactsdata = stateData?.recentcontactData?.data?.recentContacts?.map((item , key)=> {
        return {
          ...item,
          country:item?.country?.country,
        }
      })
      // setRecentContactsData(stateData?.recentcontactData?.data?.recentContacts);
      setRecentContactsData(recentcontactsdata);
    }
  }, [stateData?.recentcontactData?.data?.recentContacts]);
  useEffect(() => {
    if (stateData?.countries) {
      setCountries(stateData?.countries);
    }
  }, [stateData?.countries]);
  useEffect(() => {
    if (stateData?.frequentCollaboratorsList?.data?.recentCollaborators) {
      let frequentCollaborator =
        stateData?.frequentCollaboratorsList?.data?.recentCollaborators?.nodes?.map(
          (item, key) => {
            let countryname = getCountry(item.countryId);

            return {
              name: item.name,
              memberFirmName: item?.memberFirmName,
              country: countryname,
              countryId: item.countryId,

            };
          }
        );
      setFrequentCollaborators(frequentCollaborator);
    }
  }, [stateData?.frequentCollaboratorsList?.data?.recentCollaborators]);
  useEffect(() => {
    if (stateData?.activeClientsList?.data?.activeClient?.nodes) {
      let frequentCollaborator =
        stateData?.activeClientsList?.data?.activeClient?.nodes?.map(
          (item, key) => {
            

            return {
              clientName: item.clientName,
              nextExpiringPolicyDate: item?.nextExpiringPolicyDate,
              country: item.country,
              countryId: item.countryId,
              id: item.id,
              
              
            };
          }
        );
      setActiveClients(frequentCollaborator);
    }
  }, [stateData?.activeClientsList?.data?.activeClient?.nodes]);

  const handleFirstLogin = () => {
    let loggedInUserDetails = JSON.parse(localStorage.getItem("user"));

    if (loggedInUserDetails?.sub) {
      dispatch(changeFirstLogin({ id: loggedInUserDetails?.sub }));
    }
  };

  const navigate = useNavigate()
  const handleRecentContacts = (id , contactId) => {
    
    navigate(Paths.CLIENT_DASHBOARD , {state: {clientId : id , contactId:contactId  , from:"recentContacts-dashboard"}})
  }
  
  const getCountry = (id) => {
    let country = countries?.filter((item, key) => item.id === id);
    return country?.[0]?.country;
  };

  const handlePermissionModal = () => {
    if(instance && InteractionStatus.None === inProgress) {
      clearSession()
    }
  }

  
  return (
    <Box id="main">
      {/* <SwipeableTextMobileStepper/> */}
      {showMessagePopup&&
        <PermissionModal showMessagePopup={showMessagePopup} handleClose={()=> handlePermissionModal()} />
      }
      {showHomeModal && (
        <Dialog
          maxWidth="xs"
          height="150px"
          open={showHomeModal}
          onClose={() => {
            setShowHomeModal(false);
            handleFirstLogin();
          }}
          showCloseIcon="true"
          body={
            <HomeDialog
              show={showHomeModal}
              handleClose={() => {
                setShowHomeModal(false);
                handleFirstLogin();
              }}
            />
          }
        />
      )}

      <PageTitle title={"Home"} />
      <QuickLinks />
      <RecentContacts recentContactsData={recentContactsData} handleRecentContacts={handleRecentContacts} />
      <ClientsCollaborations frequentCollaborators={frequentCollaborators} activeClients={activeClients} />
      <Tasks />
    </Box>
  );
};
export default Dasboard;
