import Close from "@mui/icons-material/Close";
import { Button, IconButton } from "@mui/material";
import React from "react";

const CloseButton = ({onClick}) => {
    return (
        <Button
                  className="btn-close"
                  sx={{zIndex:"0 !important", m: 0}}
                  onClick={() => onClick()}
                >
                 
                </Button>
    )
}
export default CloseButton