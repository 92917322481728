import { useState } from "react";
import Table from "../../components/Table";
import { REPORTS_POLICY_TABLE } from "../../constants";

const handleReportsPolicyDetails = () => {

}

const reportsPolicyList = [{ policyNumber: "Company name, Company name", coverageType: 'Coverage Type' , id: 1 }
]



const CreateReportPolicyTable = () => {
    const [policyCheck , setPolicyCheck] = useState([]);
    const handlerow = (e) => {
        setPolicyCheck(e.id);
        
    }
    
    return (
        <Table
            columns={REPORTS_POLICY_TABLE}
            rows={reportsPolicyList}
            handleRow={(e) => handleReportsPolicyDetails(e)}
            from="reportsPolicy"
            checkedId={policyCheck}
            handleChange = {(e) => handlerow(e)}
        />
    )
}

export default CreateReportPolicyTable;