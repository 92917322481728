import React, { useEffect, useState } from "react";

import Select from "react-select";

import { useDispatch, useSelector } from "react-redux";

//Material-UI components
import { Avatar, Box, Grid, Typography } from "@mui/material";

//Custom MUI components
import GridContainer from "../../components/GridContainer";

import Button from "../../components/Button";

import { RenderTextInput } from "../../components/TextField";

import {
  countriesOptions,
  customFilterOption,
  
  dropDownOptions,
  
  
  
} from "../../constants";

//Custom styles for components
import { customStyles } from "../../components/common/CustomStyle";

const AddUserModal = ({ handleClose, userDetails }) => {
  //state variables declaration

  const [prefixes, setPrefixes] = useState([]);

  const [prefix, setPrefix] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [suffixes, setsuffixes] = useState([]);
  const [selectedSuffix, setSelectedSuffix] = useState("");
  const [pronouns, setPronouns] = useState([]);
  const [selectedPronoun, setSelectedPronoun] = useState("");
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [jobtitle, setjobtitle] = useState("");
  const [permission, setPermission] = useState("");

  //extract specific pieces of state from the Redux store
  const stateData = useSelector((state) => {
    return {
      countries: state?.countries?.Countries,
      prefixes: state?.contact?.contactsPrefixes,
      suffix: state?.contact?.contactsSuffix,
      pronoun: state?.contact?.contactspronoun,
    };
  });

  const dispatch = useDispatch();

  useEffect(() => {
    if (stateData?.prefixes?.data?.prefixes) {
      setPrefixes(stateData.prefixes?.data?.prefixes);
    }
  }, [stateData.prefixes?.data?.prefixes]);

  //Effect to update countries state when countries changes in Redux store
  useEffect(() => {
    if (stateData?.countries) {
      setCountries(stateData.countries);
    }
  }, [stateData.countries]);

  //Effect to update suffixes state when suffixes changes in Redux store
  useEffect(() => {
    if (stateData?.suffix?.data?.suffixes) {
      setsuffixes(stateData.suffix?.data?.suffixes);
    }
  }, [stateData.suffix?.data?.suffixes]);

  useEffect(() => {
    if (userDetails && prefixes && suffixes && countries) {
      setFirstName(userDetails?.givenName);

      let selectedprefixoption = dropDownOptions(prefixes , "prefixId" , "name")?.find(
        (item, key) => item.value === userDetails?.prefix
      );
      let selectedsuffixoption = dropDownOptions(suffixes , "suffixId" , "name")?.find(
        (item, key) => item.value === userDetails?.suffix
      );
      let selectedpronounoption = dropDownOptions(pronouns , "pronounId" , "name")?.find(
        (item, key) => item.value === userDetails?.pronoun
      );
      let selectedcountryoption = countriesOptions(countries)?.find(
        (item, key) => item.value === userDetails?.country
      );

      setPrefix(selectedprefixoption);

      setSelectedSuffix(selectedsuffixoption);

      setSelectedPronoun(selectedpronounoption);

      setSelectedCountry(selectedcountryoption);
      setjobtitle(userDetails?.[0]?.jobtitle);
      setPermission(userDetails?.access);
    }
  }, [userDetails, prefixes, suffixes , countries]);

  //Effect to update pronouns state when pronouns changes in Redux store
  useEffect(() => {
    if (stateData?.pronoun?.data?.pronouns) {
      setPronouns(stateData.pronoun?.data?.pronouns);
    }
  }, [stateData.pronoun?.data?.pronouns]);

  let permissionoption = [
    {
      id: "Admin",
      value: "Admin",
    },
    {
      id: "MemberContributor",
      value: "Contributor",
    },
  ];

  return (
    <Box style={{ padding: "12px 20px" }}>
      <Box>
        <Box
          className="sidepanel-header add-contact"
          style={{
            display: "flex",
            alignItems: "center",
            borderBottom: "1px solid var(--grey-200)",
            padding: "12px 20px",
          }}
        >
          <a
            href="javascript:void(0)"
            className="btn-close"
            onClick={() => handleClose()}
            style={{ marginRight: "5px", padding: "9px" }}
          ></a>

          <Button
            className="btn btn-default add-policy btn-md mr-8"
            style={{ backgroundColor: "grey", color: "#FFF" }}
            variant={"contained"}
            color={"grey"}
          >
            Save
          </Button>
          <span style={{ marginLeft: "10px" }}>
            <Button
              className="btn btn-default add-policy btn-md revoke-cancellation-btn"
              variant={"outlined"}
              color={"white"}
            >
              Discard
            </Button>
          </span>
        </Box>
        <Box className="sidepanel-body add-contact">
          <Box class="sidepanel-body add-contact">
            <GridContainer sx={{ marginTop: "10px" }}>
              <Grid xs="12" md="6" lg="6">
                <Typography variant="h6" style={{ fontWeight: "bold" }}>
                  Mary May
                </Typography>
              </Grid>
              <Grid xs="12" md="6" lg="6">
                {/* <Select
                  aria-label="Default select example"
                  onChange={(e) => setPermission(e)}
                  value={permission}
                  options={permissionoption}
                  styles={customStyles()}
                  // filterOption={customFilterOption}
                /> */}
                 <select
          class="form-select permission-dropdown-arrow" style={{height:'30px', padding:'0 0 0 10px', color:'#6941c6' }}
          aria-label="Default select example"
          value={permission}
          onChange={(e) => setPermission(e.target.value)}
         // styles={customStyles()}
          maxMenuHeight={10}
        >
          {/* <option value="">Select Client</option> */}
          {permissionoption?.map((item, key) => {
            return <option value={item.id} style={{color:'#000'}}>{item.value}</option>;
          })}
        </select>
              </Grid>
              <Grid xs="12" md="12" lg="12">
                <Box className="mb-3 client-text">Avatar</Box>
                <Avatar variant="square" sx={{ borderRadius: "10px" }}></Avatar>
              </Grid>

              <Grid xs="12" md="12" lg="12">
                <Box className="mb-3 client-text">Member firm</Box>
                <Box
                  sx={{
                    border: "1px solid #EAECF0",
                    borderRadius: "10px",
                    padding: "5px",
                  }}
                >
                  <GridContainer>
                    <Grid xs="2" md="2" lg="2">
                      <Avatar sx={{ borderRadius: "10px" }} variant="square">
                        <img src="wwwroot/assets/images/avatar.png" />
                      </Avatar>
                    </Grid>
                    <Grid xs="6" md="6" lg="6">
                      <Box className="client-text">Firm Broker ltd</Box>
                      <Box className="form-label">United states</Box>
                    </Grid>
                  </GridContainer>
                </Box>
              </Grid>
              <GridContainer
                sx={{
                  marginTop: "10px",
                  marginBottom: "10px",
                  borderBottom: "1px solid var(--grey-200)",
                }}
              ></GridContainer>
              <Box className="client-text">Personal information</Box>

              <Grid xs="12" md="12" lg="12">
                <Box class="mb-3">
                  <label for="exampleFormControlInput1" class="form-label">
                    Prefix
                  </label>
                  <Select
                    // className="form-select"
                    aria-label="Default select example"
                    onChange={(e) => setPrefix(e)}
                    value={prefix}
                    
                    options={dropDownOptions(prefixes , "prefixId" , "name")}
                    styles={customStyles()}
                    filterOption={customFilterOption}
                  />
                </Box>
              </Grid>
              <Grid xs="12" md="12" lg="12">
                <Box class="mb-3">
                  <label for="exampleFormControlInput1" class="form-label">
                    First/Given name
                  </label>
                  <RenderTextInput
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    placeholder="First/Given name"
                    block
                    required
                  />
                </Box>
              </Grid>
              <Grid xs="12" md="12" lg="12">
                <Box class="mb-3">
                  <label for="exampleFormControlInput1" class="form-label">
                    Suffix
                  </label>
                  <Select
                    class="form-select"
                    aria-label="Default select example"
                    onChange={(e) => setSelectedSuffix(e)}
                    styles={customStyles()}
                    filterOption={customFilterOption}
                    value={selectedSuffix}
                    
                    options={dropDownOptions(suffixes , "suffixId" , "name")}
                  />
                </Box>
              </Grid>
              <Grid xs="12" md="12" lg="12">
                <Box class="mb-3">
                  <label for="exampleFormControlInput1" class="form-label">
                    Pronoun
                  </label>
                  <Select
                    class="form-select"
                    aria-label="Default select example"
                    onChange={(e) => setSelectedPronoun(e)}
                    value={selectedPronoun}
                    styles={customStyles()}
                    filterOption={customFilterOption}
                    
                    options={dropDownOptions(pronouns , "pronounId" , "name")}
                  />
                </Box>
              </Grid>
              <hr />

              <Grid xs="12" md="12" lg="12">
                <Box class="mb-3">
                  <label for="exampleFormControlInput1" class="form-label">
                    Job title
                  </label>
                  <RenderTextInput
                    type="text"
                    id="exampleFormControlInput1"
                    placeholder="Job title"
                    required
                  />
                </Box>
              </Grid>
              <Grid xs="12" md="12" lg="12">
                <Box class="mb-3">
                  <label for="exampleFormControlInput1" class="form-label">
                    Country
                  </label>

                  <Select
                    aria-label="Default select example"
                    styles={customStyles()}
                    filterOption={customFilterOption}
                    onChange={(e) => setSelectedCountry(e.target.value)}
                    value={selectedCountry}
                    options={countriesOptions(countries)}
                  />
                </Box>
              </Grid>
            </GridContainer>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default AddUserModal;
