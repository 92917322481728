
import { REPORTS_CONST } from "../actions/actionTypes/ReportsActionsTypes";


let initialState = {
    reports: "",
    addedreports:"",
    templates:"",
    
};
const Reducer = (state = initialState, action) => {
  switch (action.type) {
    case REPORTS_CONST.GET_REPORTS_REQUEST:
      return {
        ...state,
        reports: "",
        isLoading: true,
      };
    case REPORTS_CONST.GET_REPORTS_SUCCESS:
      {console.log(action.payload)}
      return {
        ...state,
        reports: action.payload,
        isLoading: false,
        error: "",
      };
    case REPORTS_CONST.GET_REPORTS_FAILURE:
      return {
        ...state,
        reports: "",
        isLoading: false,
        error: action.error,
      };
    case REPORTS_CONST.CREATE_REPORTS_REQUEST:
      return {
        ...state,
        addedreports: "",
        isLoading: true,
      };
    case REPORTS_CONST.CREATE_REPORTS_SUCCESS:
      {console.log(action.payload)}
      return {
        ...state,
        addedreports: action.payload,
        isLoading: false,
        error: "",
      };
    case REPORTS_CONST.CREATE_REPORTS_FAILURE:
      return {
        ...state,
        addedreports: "",
        isLoading: false,
        error: action.error,
      };
    case REPORTS_CONST.DELETE_REPORTS_REQUEST:
      return {
        ...state,
        deletedreports: "",
        isLoading: true,
      };
    case REPORTS_CONST.DELETE_REPORTS_SUCCESS:
      {console.log(action.payload)}
      return {
        ...state,
        deletedreports: action.payload,
        isLoading: false,
        error: "",
      };
    case REPORTS_CONST.DELETE_REPORTS_FAILURE:
      return {
        ...state,
        deletedreports: "",
        isLoading: false,
        error: action.error,
      };
    case REPORTS_CONST.GET_TEMPLATES_REQUEST:
      return {
        ...state,
        templates: "",
        isLoading: true,
      };
    case REPORTS_CONST.GET_TEMPLATES_SUCCESS:
      {console.log(action.payload)}
      return {
        ...state,
        templates: action.payload,
        isLoading: false,
        error: "",
      };
    case REPORTS_CONST.GET_TEMPLATES_FAILURE:
      return {
        ...state,
        templates: "",
        isLoading: false,
        error: action.error,
      };
    case REPORTS_CONST.GET_GENERATED_REPORT_REQUEST:
      return {
        ...state,
        getGeneratedReport: "",
        isLoading: true,
      };
    case REPORTS_CONST.GET_GENERATED_REPORT_SUCCESS:
      {console.log(action.payload)}
      return {
        ...state,
        getGeneratedReport: action.payload,
        isLoading: false,
        error: "",
      };
    case REPORTS_CONST.GET_GENERATED_REPORT_FAILURE:
      return {
        ...state,
        getGeneratedReport: "",
        isLoading: false,
        error: action.error,
      };
    
    

    default:
      return state;
  }
};
export default Reducer;
