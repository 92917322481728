import * as React from 'react';

import Container from '@mui/material/Container';

export default function ContainerComponent({children}) {
  return (
    <React.Fragment>
      
      <Container >
        {children}
      </Container>
    </React.Fragment>
  );
}
