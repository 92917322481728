import { Box, Typography, Grid, Button, Avatar } from "@mui/material";
import CustomizedRadios from "./Radio";
// import PhoneInput from "react-phone-input-2";
import { useEffect, useState } from "react";
// import "react-phone-input-2/lib/bootstrap.css";
import Switch from "../../components/Switch";

import Select from "react-select";
import { countriesOptions, dropDownOptions, validateInput } from "../../constants";
import { useDispatch, useSelector } from "react-redux";
import { customStyles } from "../common/CustomStyle";
import { getCountries } from "../../store/actions/CountriesAction";
import { GetCurrencies } from "../../store/actions/ClientAction";
import {
  ContactsPrefixes,
  ContactsPronoun,
  ContactsSuffix,
} from "../../store/actions/ContactsAction";
import { RenderTextInput } from "../TextField";
import { getUserById, updateUser } from "../../store/actions/UserAction";
import ButtonComponent from "../Button";

import ChangePassword from "./ChangePassword";
import { changePassword, getTimeZones } from "../../store/actions/ProfileAction";
import * as base from "../../store/actions/BaseActions";
import { PROFILE_CONST } from "../../store/actions/actionTypes/ProfileActionTypes";

const ProfileDialog = ({ handleClose }) => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [prefixes, setPrefixes] = useState([]);

  const [suffix, setsuffix] = useState([]);
  const [pronoun, setPronoun] = useState([]);
  const [selectedPrefix, setSelectedPrefix] = useState("");
  const [selectedSuffix, setSelectedSuffix] = useState("");
  const [selectedPronoun, setSelectedPronoun] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedCurrency, setSelectedCurrency] = useState("");
  const [currencies, setcurrencies] = useState([]);
  const [jobTitle, setJobTitle] = useState("");
  const [lastName, setLastName] = useState("");
  const [firstName, setFistName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [userRole, setUserRole] = useState("");
  const [error, setError] = useState({});
  const [approvalStatus, setApprovalStatus] = useState("");
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [timezones, setTimezones] = useState([]);
  const [selectedTimezone, setSelectedTimezone] = useState("");
  const [userDetails, setUserDetails] = useState("");

  const [countries, setCountries] = useState([]);

  const stateData = useSelector((state) => {
    return {
      countries: state?.countries?.Countries,
      prefixes: state?.contact?.contactsPrefixes,
      suffix: state?.contact?.contactsSuffix,
      pronoun: state?.contact?.contactspronoun,
      currencies: state?.fetchData?.currenciesData,
      userById: state?.user?.userByid,
      updatedUser: state?.user?.updateduser,
      changePassword: state?.profile?.changePassword,
      timezones: state?.profile?.timezones,

    };
  });

  const dispatch = useDispatch();

  useEffect(() => {
    let loggedInUser = JSON.parse(localStorage.getItem("user"));
    {
      console.log(loggedInUser);
    }
    if (loggedInUser?.sub) {
      dispatch(getUserById({ Id: loggedInUser?.sub }));
    }

    dispatch(getCountries());

    dispatch(GetCurrencies());

    dispatch(ContactsPrefixes());
    dispatch(ContactsSuffix());
    dispatch(ContactsPronoun());

    dispatch(getTimeZones())

  }, []);
  useEffect(() => {
    if (stateData?.prefixes?.data?.prefixes) {
      setPrefixes(stateData.prefixes?.data?.prefixes);
    }
  }, [stateData.prefixes?.data?.prefixes]);

  useEffect(() => {
    if (stateData?.suffix?.data?.suffixes) {
      setsuffix(stateData.suffix?.data?.suffixes);
    }
  }, [stateData.suffix?.data?.suffixes]);
  useEffect(() => {
    if (stateData?.pronoun?.data?.pronouns) {
      setPronoun(stateData.pronoun?.data?.pronouns);
    }
  }, [stateData.pronoun?.data?.pronouns]);

  useEffect(() => {
    if (stateData.countries) {
      setCountries(stateData.countries);
    }
  }, [stateData.countries]);
  useEffect(() => {
    if (stateData.timezones?.data) {
      setTimezones(stateData.timezones?.data);
    }
  }, [stateData.timezones?.data]);
  
  useEffect(() => {
    if (stateData.currencies) {
      setcurrencies(stateData.currencies);
    }
  }, [stateData.currencies]);

  useEffect(() => {
    if (stateData?.userById && countries) {
      setFistName(stateData?.userById?.givenName);
      setLastName(stateData?.userById?.surName);
      setJobTitle(stateData?.userById?.jobTitle);
      setEmail(stateData?.userById?.mail);
      setUserRole(stateData?.userById?.access);

      let userApprovalStatus =
        stateData?.userById?.approvalStatus === "Active"
          ? "Approved"
          : stateData?.userById?.approvalStatus === "Inactive"
          ? "Unapproved"
          : "Pending";
      setApprovalStatus(userApprovalStatus);

      let selectedprefix = dropDownOptions(prefixes, "prefixId", "name")?.find(
        (item, key) => item?.value === stateData?.userById?.prefixId
      );
      let selectedpronoun = dropDownOptions(pronoun, "pronounId", "name")?.find(
        (item, key) => item?.value === stateData?.userById?.pronounId
      );
      let selectedsuffix = dropDownOptions(suffix, "suffixId", "name")?.find(
        (item, key) => item?.value === stateData?.userById?.suffixId
      );
      
      let selectedcurrency = dropDownOptions(
        currencies,
        "id",
        "currency"
      )?.find((item, key) => item?.value === stateData?.userById?.currencyId);
      let selectedtimezone = dropDownOptions(
        timezones,
        "id",
        "name"
      )?.find((item, key) => item?.value === stateData?.userById?.timeZoneId);
      setSelectedPrefix(selectedprefix);
      setSelectedPronoun(selectedpronoun);
      setSelectedSuffix(selectedsuffix);
      
      setSelectedCurrency(selectedcurrency);

      setPhoneNumber(stateData?.userById?.phoneNumber)
      
      setSelectedTimezone(selectedtimezone)

      setUserDetails(stateData?.userById);
      
    }
  }, [stateData?.userById  , countries]);

  useEffect(()=> {
if(stateData?.userById && stateData.countries) {
  let selectedcountry = countriesOptions(stateData.countries)?.find(
    (item, key) => item?.value === stateData?.userById?.countryId
  );

  setSelectedCountry(selectedcountry);

}
  } , [stateData?.userById ,  stateData?.countries])
  const handlePhoneChange = (event) => {
    setPhoneNumber(event);
    // const phonenumberRegex = /^\+?[0-9\s-]+$/;
    const phonenumberRegex = /^\+?[1-9]\d{1,14}$/;

    const isValid = phonenumberRegex.test(event);

    

    setError((prevState) => ({
      ...prevState,
      phoneNumber: event===""?"": !isValid ? "Please enter valid phone number" : "",
    }));
  }
  const handleEmail = (event) => {
    setEmail(event)

    let emailregex = /^[\w+._%-]+@[\w.-]+\.[a-zA-Z]{2,}$/
    const isValid = emailregex.test(event)

    setError((prevState) => ({
      ...prevState,
      email:  !isValid ? "Please enter valid email" : "",
    }));

  }
  const validation = () => {

    let emailRegex = /^[\w+._%-]+@[\w.-]+\.[a-zA-Z]{2,}$/
    const updatedErrors = {
        firstName:firstName?.trim()!==""?validateInput(firstName) || "":"Please Enter First / Given name",
        lastName:lastName?.trim()!==""?validateInput(lastName) || "":"Please Enter Last / Surname",
        country:selectedCountry?.value!==""?"":"Please Select Country",
        email:email!==""?emailRegex.test(email)?"":"Please Enter Valid Email":"Please Enter Email",
        jobTitle:jobTitle!==""?validateInput(jobTitle) || "":"Please Enter Job title",
      };
  
      setError(updatedErrors);
      return Object.values(updatedErrors).every((error) => error === "")

  }
  const handleSave = () => {
    let payload = {
      GivenName: firstName,
      Surname: lastName,
      Mail: email,
      PrefixId: selectedPrefix?.value ? selectedPrefix?.value : null,
      SuffixId: selectedSuffix?.value ? selectedSuffix?.value : null,
      PronounId: selectedPronoun?.value ? selectedPronoun?.value : null,

      // MemberRole: userRole?userRole:"",
      userRole: userRole ? userRole : "",

      ApprovalStatus: approvalStatus ? approvalStatus : "",

      JobTitle: jobTitle ? jobTitle : "",
      CountryId: selectedCountry !== "" ? selectedCountry?.value : "",
      id: userDetails?.id,
      timeZoneId: selectedTimezone?.value?selectedTimezone?.value:null,

phoneNumber: phoneNumber?phoneNumber:"",
currencyId: selectedCurrency?.value?selectedCurrency?.value:"",
memberFirmId: stateData?.userById?.memberFirmId,

    };
    // dispatch(updateUser())

    let isValid = validation()

    if(isValid) {

    dispatch(updateUser(payload));

    }

  };

  useEffect(() => {
    if (stateData?.changePassword) {
      setShowChangePassword(false);
      dispatch(base.getSuccess(PROFILE_CONST.CHANGE_PASSWORD_SUCCESS, ""));
    }
  }, [stateData?.changePassword]);

  const resetFieldError = (field , name) => {

    if(name) {
      let isValid = validateInput(name)
      setError((prevState) => ({
        ...prevState,
        [field]: isValid ? isValid:"",
      }));
    }
    else {
          setError((prevState) => ({
            ...prevState,
            [field]: "",
          }));
    }
  }

  const handleConfirm = (payload) => {
    // setShowChangePassword(false)

    // let payload = {}
    dispatch(changePassword(payload));
  };

  return (
    <Box className="outer-header-modal">
      {showChangePassword && (
        <ChangePassword
          showConfirmation={showChangePassword}
          handleCloseConfirmation={() => setShowChangePassword(false)}
          handleConfirm={handleConfirm}
          
        />
      )}

      <Box
        className="settings-header"
        style={{
          display: "flex",
          alignItems: "center",
          borderBottom: "1px solid var(--grey-200)",
          paddingBottom: "10px",
          //padding: "12px 20px",
        }}
      >
        <a
          href="javascript:void(0)"
          className="btn-close"
          // onClick={() => closeNav1()}
          onClick={() => handleClose()}
          style={{ marginRight: "5px", padding: "9px" }}
        ></a>

        <ButtonComponent
          className="btn btn-default add-policy btn-md mr-8"
          style={{ backgroundColor: "grey", color: "#FFF" }}
          variant={"contained"}
          color={"grey"}
          onClick={() => handleSave("save")}
          sx={{ marginLeft: "10px" }}
        >
          Save
        </ButtonComponent>
      </Box>

      <Box className="settings-header">
        <Typography className="main-text">Profile</Typography>
        <Typography className="help-support">Personal information</Typography>
        <Box>
          <Grid container spacing={2} sx={{ marginBottom: "16px" }}>
            <Grid item xs={6} md={6} lg={6}>
              <Typography className="head-text">Avatar</Typography>
            </Grid>
            <Grid item xs={6} md={6} lg={6}>
              {userDetails?.givenName || userDetails?.surNam ? (
                <Box className="initials">
                  {userDetails?.givenName?.split("")[0]}
                  {userDetails?.surName?.split("")[0]}
                </Box>
              ) : (
                <Avatar
                  sx={{
                    backgroundColor:
                      "var(--color-bg-fill-special-grey) !important",
                    width: "40px !important",
                    height: "40px !important",
                    color: "var(--color-text-special) !important",
                  }}
                />
              )}
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ marginBottom: "16px" }}>
            <Grid item xs={6} md={6} lg={6}>
              <Typography className="head-text">Prefix</Typography>
            </Grid>
            <Grid item xs={6} md={6} lg={6}>
              <Select
                // className="form-select"
                aria-label="Default select example"
                onChange={(e) => setSelectedPrefix(e)}
                value={selectedPrefix}
                options={dropDownOptions(prefixes, "prefixId", "name")}
                styles={customStyles()}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ marginBottom: "16px" }}>
            <Grid item xs={6} md={6} lg={6}>
              <Typography className="head-text">First / Given name*</Typography>
            </Grid>
            <Grid item xs={6} md={6} lg={6}>
              

              <RenderTextInput
                placeholder="First / Given name"
                fullWidth
                onChange={(e) => {setFistName(e.target.value); resetFieldError("firstName" , e.target.value)}}
                value={firstName}
                errors={error.firstName}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ marginBottom: "16px" }}>
            <Grid item xs={6} md={6} lg={6}>
              <Typography className="head-text">Last / Surname*</Typography>
            </Grid>
            <Grid item xs={6} md={6} lg={6}>
              

              <RenderTextInput
                placeholder="Last / Surname"
                fullWidth
                onChange={(e) => {setLastName(e.target.value) ; resetFieldError("lastName" , e.target.value)}}
                value={lastName}
                errors={error.lastName}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ marginBottom: "16px" }}>
            <Grid item xs={6} md={6} lg={6}>
              <Typography className="head-text">Suffix</Typography>
            </Grid>
            <Grid item xs={6} md={6} lg={6}>
              <Select
                class="form-select"
                aria-label="Default select example"
                onChange={(e) => setSelectedSuffix(e)}
                styles={customStyles()}
                value={selectedSuffix}
                options={dropDownOptions(suffix, "suffixId", "name")}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ marginBottom: "16px" }}>
            <Grid item xs={6} md={6} lg={6}>
              <Typography className="head-text">Pronouns</Typography>
            </Grid>
            <Grid item xs={6} md={6} lg={6}>
              <Select
                class="form-select"
                aria-label="Default select example"
                onChange={(e) => setSelectedPronoun(e)}
                value={selectedPronoun}
                styles={customStyles()}
                options={dropDownOptions(pronoun, "pronounId", "name")}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ marginBottom: "16px" }}>
            <Grid item xs={6} md={6} lg={6}>
              <Typography className="head-text">Job title</Typography>
            </Grid>
            <Grid item xs={6} md={6} lg={6}>
              <RenderTextInput
                placeholder="Job title"
                fullWidth
                onChange={(e) => {setJobTitle(e.target.value); resetFieldError("jobTitle" , e.target.value)}}
                value={jobTitle}
                errors={error.jobTitle}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ marginBottom: "16px" }}>
            <Grid item xs={6} md={6} lg={6}>
              <Typography className="head-text">Country</Typography>
            </Grid>
            <Grid item xs={6} md={6} lg={6}>
              <Select
                // className="form-select"
                aria-label="Default select example"
                onChange={(e) => setSelectedCountry(e)}
                value={selectedCountry}
                options={countriesOptions(countries)}
                styles={customStyles(error.country)}
              />

{error.country && (
                  <Typography
                    className="error-msge"
                    style={{ color: "#dc3545", fontSize: "14px" }}
                  >
                    {error.country}
                  </Typography>
                )}

            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ marginBottom: "16px" }}>
            <Grid item xs={6} md={6} lg={6}>
              <Typography className="head-text">Time Zone</Typography>
            </Grid>
            <Grid item xs={6} md={6} lg={6}>
              
            <Select
                // className="form-select"
                aria-label="Default select example"
                onChange={(e) => setSelectedTimezone(e)}
                value={selectedTimezone}
                options={dropDownOptions(timezones , "id" , "name")}
                styles={customStyles()}
              />

            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ marginBottom: "16px" }}>
            <Grid item xs={6} md={6} lg={6}>
              <Typography className="head-text">Currency</Typography>
            </Grid>
            <Grid item xs={6} md={6} lg={6}>
              <Box className="position-relative">
                

                <Select
                  // className="form-select"
                  aria-label="Default select example"
                  onChange={(e) => setSelectedCurrency(e)}
                  value={selectedCurrency}
                  options={dropDownOptions(currencies, "id", "currency")}
                  styles={customStyles()}
                />

                
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ marginBottom: "16px" }}>
            <Grid item xs={6} md={6} lg={6}>
              <Typography className="head-text">Phone number</Typography>
            </Grid>
            <Grid item xs={6} md={6} lg={6}>
              <Box className="position-relative">
                <RenderTextInput
                  placeholder="Phone number"
                  fullWidth
                  onChange={(e) => handlePhoneChange(e.target.value)}
                  value={phoneNumber}
                  errors={error.phoneNumber}
                />
              </Box>
            </Grid>
          </Grid>
          
          <Grid container spacing={2} sx={{ marginBottom: "16px" }}>
            <Grid item xs={6} md={6} lg={6}>
              <Typography className="head-text">Email</Typography>
            </Grid>
            <Grid item xs={6} md={6} lg={6}>
              

              <RenderTextInput
                placeholder="Email"
                fullWidth
                onChange={(e) => handleEmail(e.target.value)}
                value={email}
                errors={error.email}

              />
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ marginBottom: "16px" }}>
            <Grid item xs={6} md={6} lg={6}>
              {/* <Typography className="head-text">Password</Typography> */}
            </Grid>
            <Grid item xs={6} md={6} lg={6}>
              

              {/* <RenderTextInput
                placeholder="Password"
                fullWidth
                onChange={(e) => setPassword(e.target.value)}
                value={password}
              /> */}

              <Box
                className="change-password-text mt-2"
                onClick={() => setShowChangePassword(true)}
              >
                Change password <i className="fa fa-angle-right"></i>
              </Box>
            </Grid>
          </Grid>
        </Box>
        {/* <hr /> */}
        {/* <Box>
                    <Typography className="help-support mt-4">Security</Typography>
                    <Grid container spacing={2} sx={{ marginBottom: '16px' }}>
                        <Grid item xs={6} md={6} lg={6}>
                            <Typography className="head-subtext">Two factor authentication is mandatory. Please select your preferred destination for a code to be sent when logging in.</Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} sx={{ marginBottom: '16px' }}>
                        <Grid item xs={6} md={6} lg={6}>
                            <Typography className="head-text">Send code to</Typography>
                        </Grid>
                        <Grid item xs={6} md={6} lg={6}>
                            <CustomizedRadios />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} sx={{ marginBottom: '16px' }}>
                        <Grid item xs={6} md={6} lg={6}>
                            <Typography className="head-text">Send a test to</Typography>
                        </Grid>
                        <Grid item xs={6} md={6} lg={6}>
                            <Button className="send-test-btn">Send test</Button>
                        </Grid>
                    </Grid>
                </Box>
                <hr /> */}
        {/* <Box>
                    <Typography className="help-support mt-4">Notifications</Typography>
                    <Typography className="notification-email mb-3">Email Notification</Typography>
                    <Grid container spacing={2} sx={{ marginBottom: '16px' }}>
                        <Grid item xs={6} md={6} lg={6}>
                            <Typography className="head-text">Tasks</Typography>
                        </Grid>
                        <Grid item xs={6} md={6} lg={6}>
                            <Switch
                                id="checkboxinp"
                                type="checkbox"
                                checked={true}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} sx={{ marginBottom: '16px' }}>
                        <Grid item xs={6} md={6} lg={6}>
                            <Typography className="head-text">Feature updates</Typography>
                        </Grid>
                        <Grid item xs={6} md={6} lg={6}>
                            <Switch
                                id="checkboxinp"
                                type="checkbox"
                                checked={true}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} sx={{ marginBottom: '16px' }}>
                        <Grid item xs={6} md={6} lg={6}>
                            <Typography className="head-text">Marketing</Typography>
                        </Grid>
                        <Grid item xs={6} md={6} lg={6}>
                            <Switch
                                id="checkboxinp"
                                type="checkbox"
                                checked={true}
                            />
                        </Grid>
                    </Grid>
                </Box>
                <hr /> */}
        {/* <Box>
                    <Typography className="help-support mt-4">Account</Typography>
                    <Grid container spacing={2} sx={{ marginBottom: '16px' }}>
                        <Grid item xs={6} md={6} lg={6}>
                            <Typography className="head-text">Account</Typography>
                            <Typography className="head-subtext">We will be notified that your account is to be delete, your account will be held for 6 months unless told otherwise.</Typography>
                        </Grid>
                        <Grid item xs={6} md={6} lg={6}>
                            <Typography className="delete-account">Delete account</Typography>
                        </Grid>
                    </Grid>
                </Box> */}
      </Box>
    </Box>
  );
};
export default ProfileDialog;
