
export const ACTION_TYPES = {
FETCHED_DATA:"FETCHED_DATA",
SET_CLIENT_DATA:"SET_CLIENT_DATA",
SET_CLIENT_DETAILS:"SET_CLIENT_DETAILS",
ADD_NEW_POLICY:"ADD_NEW_POLICY",
GET_COUNTRY_DATA:"GET_COUNTRY_DATA",
GET_MEMBERS_DATA:"GET_MEMBERS_DATA",
SET_COVERAGE_TYPES:"SET_COVERAGE_TYPES",
SET_CURRENCIES_DATA:"SET_CURRENCIES_DATA",
SET_LANGUAGES_DATA:"SET_LANGUAGES_DATA",
SET_POLICY_TYPES_DATA:"SET_POLICY_TYPES_DATA",
SET_INSURE_DATA:"SET_INSURE_DATA",
SET_COVERAGE_DATA:"SET_COVERAGE_DATA",
SET_CLIENT_LIST:"SET_CLIENT_LIST",
SET_CLIENT_DATA_FAILED:"SET_CLIENT_DATA_FAILED",
SET_COVERAGE_TYPE_BY_ID_DATA:"SET_COVERAGE_TYPE_BY_ID_DATA",
SET_FIELD_DATA:"SET_FIELD_DATA",
SET_CANCEL_POLICY_DATA:"SET_CANCEL_POLICY_DATA",
SET_RENEWAL_POLICY_DATA:"SET_RENEWAL_POLICY_DATA",
SET_REVOKE_CANCELLATION_DATA:"SET_REVOKE_CANCELLATION_DATA",

SET_CONTACT_DATA:"SET_CONTACT_DATA",
SET_CONTACTS_PREFIXES:"SET_CONTACTS_PREFIXES",
SET_CONTACTS_SUFFIX:"SET_CONTACTS_SUFFIX",
SET_CONTACTS_PRONOUN:"SET_CONTACTS_PRONOUN",
SET_CONTACT_TYPE_DATA:"SET_CONTACT_TYPE_DATA",
SET_CONTACT_LIST_DATA:"SET_CONTACT_LIST_DATA",
SET_ADDED_CONTACT_DATA:"SET_ADDED_CONTACT_DATA",
SET_CONTACT_DETAILS_DATA:"SET_CONTACT_DETAILS_DATA",
SET_DELETED_CONTACT_DATA:"SET_DELETED_CONTACT_DATA",
SET_ADD_MULTIPLE_CONTACT_DATA:"SET_ADD_MULTIPLE_CONTACT_DATA",
SET_COVERAGE_DETAILS_DATA:"SET_COVERAGE_DETAILS_DATA",
SET_POLICY_DETAILS:"SET_POLICY_DETAILS",

GET_CLIENT_LIST:"GET_CLIENT_LIST",
GET_CLIENT_LOCATION:"GET_CLIENT_LOCATION",

GET_DOCUMENTS_TYPE:"GET_DOCUMENTS_TYPE",
UPLOAD_DOCUMENT:"UPLOAD_DOCUMENT",
GET_DOCUMENTS_DATA:"GET_DOCUMENTS_DATA",
ADD_DOCUMENT:"ADD_DOCUMENT",
GET_CLIENT_LOCATION_DETAILS:"GET_CLIENT_LOCATION_DETAILS",
SET_ADD_LOCATION:"SET_ADD_LOCATION",
SET_DELETED_LOCATION:"SET_DELETED_LOCATION",
SET_UPDATE_LOCATION:"SET_UPDATE_LOCATION",
GET_CLIENT_LOCATIONS:"GET_CLIENT_LOCATIONS",
GET_CLIENT_POLICIES:"GET_CLIENT_POLICIES",
GET_CLIENT_MEMBER:"GET_CLIENT_MEMBER",
SET_UPDATED_CONTACT_DATA:"SET_UPDATED_CONTACT_DATA",
UPDATED_POLICY_DATA:"UPDATED_POLICY_DATA",
CREATE_USER_SUCCESS:"CREATE_USER_SUCCESS",
GET_USER_LIST_SUCCESS:"GET_USER_LIST_SUCCESS",
GET_USER_BY_ID_SUCCESS:"GET_USER_BY_ID_SUCCESS",
UPDATE_USER_SUCCESS:"UPDATE_USER_SUCCESS",
GET_CLIENT_DETAILS_BY_ID:"GET_CLIENT_DETAILS_BY_ID",
GET_POLICY_DETAILS_BY_ID:"GET_POLICY_DETAILS_BY_ID",
UPDATE_CLIENT_SUCCESS:"UPDATE_CLIENT_SUCCESS",
DELETE_USER_SUCCESS:"DELETE_USER_SUCCESS",
SET_CLIENT_POLICIES:"SET_CLIENT_POLICIES",
GET_MEMBER_SUCCESS:"GET_MEMBER_SUCCESS",
GET_MEMBER_BY_ID_SUCCESS:"GET_MEMBER_BY_ID_SUCCESS",
CREATE_MEMBER_FIRM_SUCCESS:"CREATE_MEMBER_FIRM_SUCCESS",
UPDATE_MEMBER_FIRM_SUCCESS:"UPDATE_MEMBER_FIRM_SUCCESS",
DELETE_MEMBER_FIRM_SUCCESS:"DELETE_MEMBER_FIRM_SUCCESS",
GET_USER_BY_MEMBER_FIRM_ID_SUCCESS:"GET_USER_BY_MEMBER_FIRM_ID_SUCCESS",
SET_MEMBER_FIRM_CLIENT:"SET_MEMBER_FIRM_CLIENT",
SET_CHANGE_FIRST_LOGIN:"SET_CHANGE_FIRST_LOGIN",
SET_SEARCH_CLIENT:"SET_SEARCH_CLIENT",

DELETE_DOCUMENT_SUCCESS:"DELETE_DOCUMENT_SUCCESS",
DELETE_POLICY_SUCCESS:"DELETE_POLICY_SUCCESS",
ARCHIVE_MULTIPLE_CLIENTS:"ARCHIVE_MULTIPLE_CLIENTS",
SET_BROKER_FOR_POLICY:"SET_BROKER_FOR_POLICY",
GET_MEMBER_FIRM_LIST_SUCCESS:"GET_MEMBER_FIRM_LIST_SUCCESS",
SET_UPDATE_FIELD_DATA:"SET_UPDATE_FIELD_DATA",
SET_DOCUMENTS_FOR_POLICY:"SET_DOCUMENTS_FOR_POLICY",
GET_MEMBER_FIRM_BY_NAME_SUCCESS:"GET_MEMBER_FIRM_BY_NAME_SUCCESS",
SET_CLIENT_MAP_DATA:"SET_CLIENT_MAP_DATA",
SET_CLIENT_CALENDAR_DATA:"SET_CLIENT_CALENDAR_DATA",
SET_USER_BY_FILTER_DATA:"SET_USER_BY_FILTER_DATA",
GET_MEMBER_FIRM_BY_CLIENT_SUCCESS:"GET_MEMBER_FIRM_BY_CLIENT_SUCCESS",
SET_CLIENT_TOTAL_COST_DATA:"SET_CLIENT_TOTAL_COST_DATA",
SET_CLIENT_RENEWAL_STATUS_DATA:"SET_CLIENT_RENEWAL_STATUS_DATA",
GET_USER_LIST_FAILURE:"GET_USER_LIST_FAILURE",
SET_REGION_DATA:"SET_REGION_DATA",
SET_REGION_BY_COUNTRY_DATA:"SET_REGION_BY_COUNTRY_DATA",
SET_ADD_INSURER_DATA:"SET_ADD_INSURER_DATA",
GET_SORTED_MEMBER_FIRM_SUCCESS:"GET_SORTED_MEMBER_FIRM_SUCCESS",
SET_SEARCH_CLIENTS_DATA:"SET_SEARCH_CLIENTS_DATA",
GET_UPDATE_DOCUMENTS_DATA:"GET_UPDATE_DOCUMENTS_DATA",


}





