import { Box, Grid } from "@mui/material";
import React from "react";
import GridContainer from "../../../components/GridContainer";
import Select from "react-select";

import CreatableSelect from "react-select/creatable";

import { RenderTextInput } from "../../../components/TextField";
import {
  countriesOptions,
  
  customFilterOption,
  dropDownOptions,
  
  
  
} from "../../../constants";
import { customStyles } from "../../../components/common/CustomStyle";
import Switch from "../../../components/Switch";
import renderField from "../renderCoverageFields";

const Tab2 = ({
  tab,
  selectedgCoverage,
  coverageTypes,
  errors,
  handleCoverageChange,
  handlePolicyNumberChange,
  policyNumber,
  countries,
  handlePolicyCountry,
  selectedPolicyCountry,
  language,
  selectedLanguage,
  handleLanguage,
  policyType,
  selectedPolicyType,
  handlePolicyType,
  handleLocallyAdmittedCoverage,
  locallyAdmittedCoverage,
  handleChange,
  isInsure,
  selectedValue,
  handleNameInsured,
  nameInsured,
  coverageFields,
  formData,
  handleInputChange,
  handleInputChangeWithValidation,
  numberlimit,
  
  formatCreateLabel,
  
  renewPolicy,

  
}) => {
  return (
    <Box
      className={`tab-pane fade show ${tab === 2 ? "active" : ""}`}
      id="pills-details"
      role="tabpanel"
      aria-labelledby="pills-details-tab"
      tabindex="0"
    >
      <GridContainer className="row">
        <Grid xs="12" md="6" lg="6">
          <Box className="client-text">Coverage Type*</Box>
          <Box className="client-subtext">
            Details of chosen policy will appear below after selection
          </Box>
        </Grid>
        <Grid xs="12" md="6" lg="6">
          <Select
            aria-label="Default select example"
            value={selectedgCoverage}
            
            options={dropDownOptions(coverageTypes , "id" , "name")}
            styles={customStyles(errors.selectedgCoverage)}
            onChange={handleCoverageChange}
          />

          {errors.selectedgCoverage && (
            <Box
              className="error-msge"
              style={{ color: "#dc3545", fontSize: "14px" }}
            >
              {errors.selectedgCoverage}
            </Box>
          )}
        </Grid>
      </GridContainer>
      <hr />
      <GridContainer className="row">
        <Grid xs="12" md="6" lg="6">
          <Box className="client-text">Policy Number*</Box>
          <Box className="client-subtext">As seen on the document</Box>
        </Grid>
        <Grid xs="12" md="6" lg="6">
          <RenderTextInput
            type="email"
            id="exampleFormControlInput1"
            fullWidth
            onChange={handlePolicyNumberChange}
            value={policyNumber}
            errors={errors.policynumber}
            
            disabled={renewPolicy}

          />
        </Grid>
      </GridContainer>
      <hr />
      <GridContainer className="row">
        <Grid xs="12" md="6" lg="6">
          <Box className="client-text">Policy Country*</Box>
          <Box className="client-subtext">
            The country that the policy is covering
          </Box>
        </Grid>
        <Grid xs="12" md="6" lg="6">
          <Box className="country-flag">
            <Select
              // options={countriesoption}
              options={countriesOptions(countries)}
              placeholder="Select a country"
              // styles={customStyles1}
              onChange={handlePolicyCountry}
              filterOption={customFilterOption}
              value={selectedPolicyCountry}
              styles={customStyles(errors.country)}
            />
          </Box>
          {errors.country && (
            <Box
              className="error-msge"
              style={{ color: "#dc3545", fontSize: "14px" }}
            >
              {errors.country}
            </Box>
          )}
        </Grid>
      </GridContainer>
      <hr />
      <GridContainer className="row">
        <Grid xs="12" md="6" lg="6">
          <Box className="client-text">Language*</Box>
          <Box className="client-subtext">
            Language in which the document is issued
          </Box>
        </Grid>
        <Grid xs="12" md="6" lg="6">
          <Select
            
            options={dropDownOptions(language , "id" , "language")}
            value={selectedLanguage}
            styles={customStyles(errors.language)}
            onChange={handleLanguage}
          />

          {errors.language && (
            <Box
              className="error-msge"
              style={{ color: "#dc3545", fontSize: "14px" }}
            >
              {errors.language}
            </Box>
          )}
        </Grid>
      </GridContainer>
      <hr />
      <GridContainer className="row">
        <Grid xs="12" md="6" lg="6">
          <Box className="client-text">Policy Type/Class*</Box>
        </Grid>
        <Grid xs="12" md="6" lg="6">
          <Select
            
            options={dropDownOptions(policyType , "id" , "policyType")}
            value={selectedPolicyType}
            styles={customStyles(errors.policytype)}
            onChange={handlePolicyType}
          />
          {errors.policytype && (
            <Box
              className="error-msge"
              style={{ color: "#dc3545", fontSize: "14px" }}
            >
              {errors.policytype}
            </Box>
          )}
        </Grid>
      </GridContainer>
      <hr />
      <GridContainer className="row">
        <Grid xs="12" md="6" lg="6">
          <Box className="client-text">Locally Admitted Coverage</Box>
        </Grid>
        <Grid xs="12" md="6" lg="6" className=" ">
          <Switch
            label="No"
            id="checkboxinp"
            type="checkbox"
            onChange={handleLocallyAdmittedCoverage}
            fullWidth
            checked={locallyAdmittedCoverage}
          />
        </Grid>
      </GridContainer>
      <hr />
      <GridContainer className="row">
        <Grid xs="12" md="6" lg="6">
          <Box className="client-text">Insurer / Service Provider*</Box>
        </Grid>
        <Grid xs="12" md="6" lg="6">
          {/* <CreatableSelect */}
          {/* <Select */}
          <CreatableSelect
            onChange={handleChange}
            
            options={dropDownOptions(isInsure , "id" , "code")}
            value={selectedValue}
            styles={customStyles(errors.insurer)}
            
            
            formatCreateLabel={formatCreateLabel}
            isClearable
            
          />
          {errors.insurer && (
            <Box
              className="error-msge"
              style={{ color: "#dc3545", fontSize: "14px" }}
            >
              {errors.insurer}
            </Box>
          )}
        </Grid>
      </GridContainer>
      <hr />
      <GridContainer className="row">
        <Grid xs="12" md="6" lg="6">
          <Box className="client-text">Name Insured*</Box>
          <Box className="client-subtext">As seen on the policy document</Box>
        </Grid>
        <Grid xs="12" md="6" lg="6">
          <RenderTextInput
            placeholder="Type name"
            fullWidth
            onChange={handleNameInsured}
            value={nameInsured}
            errors={errors.nameinsure}
          />
        </Grid>
      </GridContainer>
      <hr />
      <GridContainer className="row">
        <Grid xs="12" md="6" lg="6">
          <Box className="client-text">Coverage Specific</Box>
          <Box className="client-subtext">
            Determined from the coverage type selected above
          </Box>
        </Grid>

        <Grid xs="12" md="6" lg="6">
          {coverageFields?.map((field) =>
            renderField(
              field,
              formData,
              handleInputChange,
              handleInputChangeWithValidation,
              numberlimit,
              false
            )
          )}
        </Grid>
      </GridContainer>
    </Box>
  );
};
export default Tab2;
