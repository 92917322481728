import React, { useEffect, useState, useLayoutEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../components/layout/Header";
import SideBar from "../../components/layout/SideBar";
import Calendar_Policy from "./Calendar_Policy";
import GridContainer from "../../components/GridContainer";
import { Box, Grid, Typography } from "@mui/material";
import Container from "../../components/Container";
import PageTitle from "../../components/PageTitle";
import ButtonComponent from "../../components/Button";
import FilterSection from "../client/FilterSection";
import { CLIENT_API_URL, dropDownOptions } from "../../constants";
import ClientDetails from "../client/ClientDetails";
import DrawerComponent from "../../components/Drawer";
import {
  getClientActiveInactiveData,
  getClientCalendarByPastDueData,
  getClientCalendarData,
  getClientDetails,
  getClientExpirationDateData,
  getClientList,
  setCalendarByClientData,
} from "../../store/actions/ClientAction";
import Select from "react-select";
import { customStyles } from "../../components/common/CustomStyle";

function Calendar() {
  const API_URL = `${CLIENT_API_URL}/graphql/`;

  const [clients, setClients] = useState([]);
  const [clientCalendarData, setClientCalendarData] = useState([]);
  const [selectedClient, setselectedClient] = useState(null);
  const [showClientView, setShowClientView] = useState(false);
  const [clientDetail, setClientDetail] = useState("");
  const [filterTab, setFilterTab] = useState("");
  const [viewtype, setviewType] = useState("");
  const [showref, setShowref] = useState(false);
  const [year, setYear] = useState(new Date().getFullYear());
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

  const stateData = useSelector((state) => {
    return {
      clientData: state?.fetchData?.clientdata,
      clientMapData: state?.fetchData?.clientmapdata,
      clientDetailsData: state?.fetchData?.clientDetailsData,
      clientsListData: state?.fetchData?.clientListData,
      clientCalendarData: state?.fetchData?.clientCalendarData,
      clientCalendarPastDueData: state?.fetchData?.clientCalendarPastDueData,
      clientActiveInactiveData: state?.fetchData?.clientActiveInactiveData,
      clientExpirationDateData: state?.fetchData?.clientExpirationDateData,
    };
  });

  const locationRef = useRef(null);

  const dispatch = useDispatch();

  function getMonthFromDateString(dateString) {
    const dateObject = new Date(dateString);
    return dateObject.getMonth() * 8.33333;
  }

  function getExpirationMonthFromDateString(dateString) {
    const dateObject = new Date(dateString);
    return (11 - dateObject.getMonth()) * 8.33333;
  }

  function getYearFromDate(dateString) {
    const dateObject = new Date(dateString);
    return dateObject.getFullYear();
  }

  let months = [
    "jan",
    "feb",
    "mar",
    "apr",
    "may",
    "jun",
    "jul",
    "aug",
    "sep",
    "oct",
    "nov",
    "dec",
  ];

  function getInceptionDayRange(dateString) {
    const monthsArray = [
      { month: 1, TotalDays: 31, BlockPercentage: 0.2688 },
      { month: 2, TotalDays: 29, BlockPercentage: 0.2873 },
      { month: 3, TotalDays: 31, BlockPercentage: 0.2688 },
      { month: 4, TotalDays: 30, BlockPercentage: 0.2777 },
      { month: 5, TotalDays: 31, BlockPercentage: 0.2688 },
      { month: 6, TotalDays: 30, BlockPercentage: 0.2777 },
      { month: 7, TotalDays: 31, BlockPercentage: 0.2688 },
      { month: 8, TotalDays: 31, BlockPercentage: 0.2688 },
      { month: 9, TotalDays: 30, BlockPercentage: 0.2777 },
      { month: 10, TotalDays: 31, BlockPercentage: 0.2688 },
      { month: 11, TotalDays: 30, BlockPercentage: 0.2777 },
      { month: 12, TotalDays: 31, BlockPercentage: 0.2688 },
    ];

    const dateObject = new Date(dateString);
    const day = dateObject.getDate();
    const monthNumber = dateObject.getMonth() + 1;

    const monthInfo = monthsArray.find(
      (info) => info && info.month === monthNumber
    );

    const totaldays = monthInfo.TotalDays;
    const blockPercentage = monthInfo.BlockPercentage;

    return day * blockPercentage;
  }

  const renderDays = (year, monthIndex, month) => {
    const numDays = new Date(year, monthIndex + 1, 0).getDate();

    const daysArray = [];
    for (let i = 0; i < numDays; i++) {
      daysArray.push(
        <div
          key={i}
          className="day sub-column"
          id={`${month}-${i + 1}`}
          style={{ width: "3.5%" }}
        ></div>
      );
    }
    return daysArray;
  };

  function getExpirationDay(dateString) {
    const monthsArray = [
      { month: 1, TotalDays: 31, BlockPercentage: 0.2688 },
      { month: 2, TotalDays: 29, BlockPercentage: 0.2873 },
      { month: 3, TotalDays: 31, BlockPercentage: 0.2688 },
      { month: 4, TotalDays: 30, BlockPercentage: 0.2777 },
      { month: 5, TotalDays: 31, BlockPercentage: 0.2688 },
      { month: 6, TotalDays: 30, BlockPercentage: 0.2777 },
      { month: 7, TotalDays: 31, BlockPercentage: 0.2688 },
      { month: 8, TotalDays: 31, BlockPercentage: 0.2688 },
      { month: 9, TotalDays: 30, BlockPercentage: 0.2777 },
      { month: 10, TotalDays: 31, BlockPercentage: 0.2688 },
      { month: 11, TotalDays: 30, BlockPercentage: 0.2777 },
      { month: 12, TotalDays: 31, BlockPercentage: 0.2688 },
    ];

    const dateObject = new Date(dateString);
    const day = dateObject.getDate();
    const monthNumber = dateObject.getMonth() + 1;

    const monthInfo = monthsArray.find(
      (info) => info && info.month === monthNumber
    );

    const totaldays = monthInfo.TotalDays;
    const blockPercentage = monthInfo.BlockPercentage;

    return (totaldays - day) * blockPercentage;
  }

  function getFormattedTodayDate() {
    const months = [
      "jan",
      "feb",
      "mar",
      "apr",
      "may",
      "jun",
      "jul",
      "aug",
      "sep",
      "oct",
      "nov",
      "dec",
    ];
    const today = new Date();

    const month = months[today.getMonth()];
    const day = today.getDate();

    const formattedDate = `${month}-${day}`;

    return formattedDate;
  }

  function getFormattedTodayTitle() {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const today = new Date();

    const monthName = months[today.getMonth()];
    const day = today.getDate();
    const year = today.getFullYear();

    const formattedDate = `Today - ${monthName} ${day}, ${year}`;

    return formattedDate;
  }
  // Method to go to the previous year
  const prevYear = () => {
    setYear((year) => year - 1);
    addClassIfCurrentYear();
  };

  // Method to go to the next year
  const nextYear = () => {
    setYear((year) => year + 1);
    addClassIfCurrentYear();
  };

  function addClassIfCurrentYear() {
    if (currentYear == year) {
      const today = getFormattedTodayDate();
      const element = document.getElementById(today);
      if (element) {
        element.classList.remove('date', 'owl-carousel', 'owl-theme');
        element.classList.add("date", "owl-carousel", "owl-theme");
        element.title = getFormattedTodayTitle();
      }
    } else {
      removeClassFromElement();
    }
  }
  
  function removeClassFromElement() {
    const today = getFormattedTodayDate();
    const element = document.getElementById(today);
  
    if (element) {
      element.classList.remove('date', 'owl-carousel', 'owl-theme');
    }
  }

  useEffect(() => {

    addClassIfCurrentYear();

    let payload = {
      filterName: "calendar",
      isDraft: false,
    };

    dispatch(getClientList(payload));
  }, []);

  useEffect(() => {
    return () => {
      dispatch(setCalendarByClientData([]));
    };
  }, []);
  useEffect(() => {
    if (stateData.clientsListData?.data?.clients_List?.nodes) {
      setClients(stateData?.clientsListData?.data?.clients_List?.nodes);
    }
  }, [stateData.clientsListData?.data?.clients_List?.nodes]);

  useEffect(() => {
    if (stateData.clientCalendarData?.data?.clients_Calendar) {
      setClientCalendarData([]);
      setClientCalendarData(
        stateData?.clientCalendarData?.data?.clients_Calendar
      );
    }
  }, [stateData.clientCalendarData?.data?.clients_Calendar]);

  //Modified By: Anjali
  //Modified On: 15-03-2024
  //JiraId: https://bosframework.atlassian.net/browse/WBN-233
  //Reason: the Calendar retains the data of the previously selected client

  const fetchClientCalendarData = async (clientId) => {
    dispatch(getClientCalendarData(clientId));
  };

  useEffect(() => {
    if (stateData.clientDetailsData) {
      setClientDetail(stateData.clientDetailsData?.data?.clientsDetails[0]);
    }
  }, [stateData.clientDetailsData]);

  const handleSelectClient = (event) => {
    setYear(new Date().getFullYear());

    //  setselectedClient(event.target.value);
    setselectedClient(event);
    if (event?.value !== "") {
      setFilterTab("All");
      fetchClientCalendarData(event.value);
    } else {
      setClientCalendarData([]);
      setFilterTab("");
    }
  };

  const handleFilter = (e) => {
    if (selectedClient?.value !== "") {
      setFilterTab(e);

      if (e === "All") {
        fetchClientCalendarData(selectedClient?.value);
      } else if (e === "Policy exp. date") {
        dispatch(getClientExpirationDateData(selectedClient?.value));
      } else if (e === "Active") {
        dispatch(getClientActiveInactiveData(selectedClient?.value, true));
      } else if (e === "Inactive") {
        dispatch(getClientActiveInactiveData(selectedClient?.value, false));
      } else if (e === "Past due") {
        dispatch(getClientCalendarByPastDueData(selectedClient?.value));
      }
    }
  };

  const handleClientView = () => {
    setShowClientView(true);
    dispatch(getClientDetails(selectedClient?.value));
  };

  return (
    <>
      {showClientView && (
        <DrawerComponent
          isOpen={showClientView}
          handleClose={() => {
            setShowClientView(false);
            setShowref(false);
          }}
          // width={1000}
          width={"80vw"}
          body={
            <ClientDetails
              clientDetails={clientDetail}
              locationRef={locationRef}
              // contactsList={contactsList}
              handleClose={() => {
                setShowClientView(false);
                setShowref(false);
              }}
              showref={showref}
              handlesetShowref={() => setShowref(false)}
            />
          }
        />
      )}

      {/* <Box> */}
      <Box id="main" class="main">
        {/* client header  */}
        {/* <Container class="container-fluid heading ps-0 pe-0"> */}
        <GridContainer sx={{ marginBottom: "32px" }}>
          <Grid xs="12" md="12" lg="10" className=" d-flex align-items-center">
            <PageTitle title={"Calendar"} sx={{ marginBottom: "10px" }} />
            {/* <select
                        class="form-select map-select"
                        aria-label="Default select example"
                        value={selectedClient}
                        onChange={handleSelectClient}
                      >
                        <option value="" disabled selected>Select Client</option>
                        {
                        clients?.map((item, key) => {
                          return (
                            <option value={item.id}>{item.clientName}</option>
                          )
                        })}
                      </select> */}

            <Select
              options={dropDownOptions(
                clients?.length > 0 ? clients : [],
                "id",
                "clientName"
              )}
              placeholder="Client"
              onChange={handleSelectClient}
              value={selectedClient}
              menuPortalTarget={document.body}
              styles={{
                ...customStyles(),
                menuPortal: (base) => ({ ...base, zIndex: 99999 }),
              }}
              // className="users-country-select"
              maxMenuHeight={300}
              className="map-select"
            />
          </Grid>
          <Grid xs="2" md="2" lg="2" sx={{ textAlign: "end" }}>
            <ButtonComponent
              type="button"
              class="btn btn-default add-client btn-md"
              variant={
                !selectedClient || selectedClient?.value === ""
                  ? "outlined"
                  : "contained"
              }
              color={
                !selectedClient || selectedClient?.value === ""
                  ? "white"
                  : "grey"
              }
              onClick={() => handleClientView()}
              disabled={!selectedClient || selectedClient?.value === ""}
            >
              View Client
            </ButtonComponent>
          </Grid>
        </GridContainer>
        <GridContainer sx={{ marginBottom: "5px" }}>
          <Grid
            xs="12"
            md="12"
            lg="9"
            className=" d-flex align-items-center"
          ></Grid>
          <Grid
            xs="12"
            md="4"
            lg="3"
            className=" head-btn d-flex align-items-center justify-content-end ps-0 pe-0"
          >
            <Typography sx={{ marginRight: "5px" }}>
              <ButtonComponent
                type="button"
                class="btn btn-default add-client btn-md"
                variant={
                  !selectedClient || selectedClient?.value === ""
                    ? "outlined"
                    : "contained"
                }
                color={
                  !selectedClient || selectedClient?.value === ""
                    ? "white"
                    : "grey"
                }
                onClick={() => prevYear()}
                disabled={!selectedClient || selectedClient?.value === ""}
              >
                Prev
              </ButtonComponent>
            </Typography>
            <span>
              <b> {year}</b>
            </span>
            <Typography sx={{ marginLeft: "5px" }}>
              <ButtonComponent
                type="button"
                class="btn btn-default add-client btn-md"
                variant={
                  !selectedClient || selectedClient?.value === ""
                    ? "outlined"
                    : "contained"
                }
                color={
                  !selectedClient || selectedClient?.value === ""
                    ? "white"
                    : "grey"
                }
                onClick={() => nextYear()}
                disabled={!selectedClient || selectedClient?.value === ""}
              >
                Next
              </ButtonComponent>
            </Typography>
          </Grid>
        </GridContainer>

        <Box class="section client-dashboard container-fluid ps-0 pe-0">
          <GridContainer>
            <Grid xs="12" md="12" lg="12">
              <Box class="dashboard-content">
                <Box class="dashboard-main-head row ms-0 me-0">
                  {/* Client filter section */}

                  <Grid
                    xs="12"
                    md="6"
                    lg="6"
                    className=" d-flex align-items-center"
                  >
                    <FilterSection
                      filterby={[
                        "All",
                        "Policy exp. date",
                        "Active",
                        "Inactive",
                        "Past due",
                      ]}
                      handleView={setviewType}
                      viewType={viewtype}
                      handleFilter={(e) => selectedClient && handleFilter(e)}
                      filterTab={filterTab}
                      showViewBy={false}
                    />
                  </Grid>

                  <Box class="tab-content" id="myTabContent">
                    <Box
                      class="tab-pane fade show active"
                      id="list-tab-pane"
                      role="tabpanel"
                      aria-labelledby="list-tab"
                      tabindex="0"
                    >
                      <section class="section dashboard">
                        <Box class="container-fluid position-relative">
                          <Box class="row calendar-head">
                            <Box class="col-1">Jan</Box>
                            <Box class="col-1">Feb</Box>
                            <Box class="col-1">Mar</Box>
                            <Box class="col-1">Apr</Box>
                            <Box class="col-1">May</Box>
                            <Box class="col-1">Jun</Box>
                            <Box class="col-1">Jul</Box>
                            <Box class="col-1">Aug</Box>
                            <Box class="col-1">Sep</Box>
                            <Box class="col-1">Oct</Box>
                            <Box class="col-1">Nov</Box>
                            <Box class="col-1">Dec</Box>
                          </Box>
                          <Box class="row calendar-row">
                            {months?.map((month, index) => {
                              return (
                                <Box className="col-1 month-col">
                                  {/* <Box className="month-col sub-column" 
                                  // id={`${month}-${index + 1}`}
                                   sx={{width:"100%"}}> */}
                                  {renderDays(
                                    new Date().getFullYear(),
                                    index,
                                    month
                                  )}
                                  {/* </Box> */}
                                </Box>
                              );
                            })}
                          </Box>
                          <Box className="sub-main-box">
                          {clientCalendarData?.length > 0 ? (
  
                                clientCalendarData.reduce((acc, client, clientIndex) => {
                                  client.policies.forEach((policy, policyIndex) => {
                                    const policyId = policy.policyId;
                                    const policyNumber = policy.policyNumber;

                                    const inceptionYear = getYearFromDate(policy.inceptionDate);
                                    const expirationYear = getYearFromDate(policy.expirationDate);

                                    if (inceptionYear <= year && expirationYear >= year) {
                                      const monthFromInception = getMonthFromDateString(policy.inceptionDate);
                                      const inceptionDayRange = getInceptionDayRange(policy.inceptionDate);
                                      let inceptionLeft = monthFromInception + inceptionDayRange;

                                      const expirationMonth = getExpirationMonthFromDateString(policy.expirationDate);
                                      const expirationDay = getExpirationDay(policy.expirationDate);
                                      let width = 100 - monthFromInception - expirationMonth - expirationDay - inceptionDayRange;

                                      if (inceptionYear < year && expirationYear > year) {
                                        inceptionLeft = 0;
                                        width = 100;
                                      } else if (inceptionYear === year && expirationYear > year) {
                                        width = 100;
                                      } else if (inceptionYear < year && expirationYear === year) {
                                        inceptionLeft = 0;
                                        width = 100 - expirationMonth - expirationDay;
                                      }

                                      
                                      if (!acc[policyNumber]) {
                                        
                                        const index = Object.keys(acc).length; 
                                        acc[policyNumber] = `calc(14px * (var(--4) + ${(index * 3) - 3}))`;
                                      }

                                      return acc.push(
                                        <Calendar_Policy
                                          key={policyId}
                                          policy={policy}
                                          left={inceptionLeft}
                                          width={width}
                                          topValue={acc[policyNumber]} 
                                        />
                                      );
                                    }
                                  });
                                  return acc;
                                }, [])
                              ) : (
                                <></>
                              )}
                          </Box>
                        </Box>
                      </section>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </GridContainer>

          {/* </Container> */}
        </Box>
        {/* </Container> */}
      </Box>
      {/* </Box> */}
    </>
  );
}

export default Calendar;
