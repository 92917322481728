import { Box, Typography, Grid, Button, Avatar } from "@mui/material";
import NavTabs from "../../components/NavTabs";
import { useEffect, useState } from "react";
import CreateReportClient from "./CreateReportClient";
import CreateReportPolicy from "./CreateReportPolicy";
import CreateReportDetails from "./CreateReportDetails";
import CreateReportGenerate from "./CreateReportGenerate";
import { dropDownOptions, policiesOptions, validateInput } from "../../constants";
import { customStyles } from "../../components/common/CustomStyle";
import { getClientList, getClientPolicies, setClientPolicies } from "../../store/actions/ClientAction";
import GridContainer from "../../components/GridContainer"
import Select from "react-select"
import { useDispatch, useSelector } from "react-redux";
import { RenderTextInput } from "../../components/TextField";
import ButtonComponent from "../../components/Button";
import { createReport } from "../../store/actions/ReportsActions";


const CreateReport = ({ handleClose }) => {

    const [tab, setTab] = useState(1); 
    const [selectedClient, setSelectedClient] = useState(""); 
    const [clientList, setClientList] = useState([]); 
    const [selectedPolicy, setSelectedPolicy] = useState(""); 
    const [policies, setPolicies] = useState([]); 
    const [reportName, setReportName] = useState(""); 
    const [templates, setTemplates] = useState([]); 
    const [selectedTemplate, setSelectedTemplate] = useState(""); 
    const [error, setError] = useState({
      template:"",
      client:"",
      policy:"",
      
      reportName:"",

    }); 

    const statedata = useSelector((state) => {
        return {
          

          clientListData: state?.fetchData?.clientListData,
          
          policies: state?.fetchData?.clientPoliciesData,

          templates: state?.report?.templates,
          

        }})
        
    const handleTab = (tab1) => {
        setTab(tab1);
    };

    const dispatch = useDispatch()
    useEffect(()=> {

        let payload = {
            filterName:"activeOnly",
            isDraft:false,
      
          }
          
          
          dispatch(getClientList(payload))

    } , [])

    useEffect(()=> {
      if(selectedClient?.value) {
  
        let client = clientList?.filter((item , key)=> item.id === selectedClient?.value)
        let ClientProducingBroker = client?.[0]?.producingBroker
                  ?.id
            
                  let payload = {
                    isSort: false,
                    sortName: "",
                    sortASC: "",
                    isDraft:false,
                  }
  
        dispatch(getClientPolicies(selectedClient?.value, ClientProducingBroker , true , payload))
  
  
      }
      else if (selectedClient?.value === "") {
        setSelectedPolicy("")
        setPolicies([])
        dispatch(setClientPolicies(""))
      }
    } , [selectedClient?.value])

    
    useEffect(() => {
      if (statedata?.policies) {
        setPolicies(statedata?.policies?.data?.policiesForClient?.nodes);
      }
    }, [statedata?.policies])

    useEffect(() => {
        if (statedata?.clientListData?.data?.clients_List?.nodes) {
          setClientList(statedata?.clientListData?.data?.clients_List?.nodes);
        }
      }, [statedata?.clientListData?.data?.clients_List?.nodes])
    useEffect(() => {
        if (statedata?.templates?.data?.templates?.nodes) {
          setTemplates(statedata?.templates?.data?.templates?.nodes);
        }
      }, [statedata?.templates?.data?.templates?.nodes])


    const reportsTabSwitch = () => {

        switch (tab) {
            case 1:
                return <CreateReportClient />
            case 2:
                return <CreateReportPolicy />
            case 3:
                return <CreateReportDetails />
            case 4:
                return <CreateReportGenerate />
            default: 
            return
        }
    }

    const validation = () => {

      let selectedpolicy = selectedPolicy?selectedPolicy?.map((item , key)=> item?.value):[]

      const updatedErrors = {
        template: selectedTemplate !== "" ? selectedTemplate?.value !== "" ?"":"Please Select Template" : "Please Select Template",
        reportName: reportName?.trim() !== "" ? validateInput(reportName) || "" : "Please enter report name",
        client: selectedClient !== "" ? selectedClient?.value!==""?"":"Please select client" : "Please select client",
        policy: selectedpolicy?.length>0  ? "": "Please select policy",
        
        
      };
  
      setError(updatedErrors);
      return Object.values(updatedErrors).every((error) => error === "");
      
    }
    
    const generateReport = () => {
      const isvalid = validation()

      if(isvalid) {

      let selectedpolicy = selectedPolicy?.map((item , key)=> item?.value)
      let payload = {

        
        
          "name":reportName?reportName:"",
          "templateId": selectedTemplate?.value?selectedTemplate?.value:"",
          "clientIds": selectedClient?.value?[selectedClient?.value]:"",
          "policyIds":selectedpolicy?.length>0?selectedpolicy:[]
      

      }

      // {console.log(payload)}

      dispatch(createReport(payload))
}

    }

    const handleSelectClient = (e) => {
setSelectedClient(e)

setSelectedPolicy("")

    }

    return (
        // <Box className="outer-header-modal create-new-modal">
        //     <Box className="settings-header"
        //         style={{
        //             display: "flex",
        //             alignItems: "center",
        //             borderBottom: "1px solid var(--grey-200)",
        //             paddingBottom: '10px'
        //             //padding: "12px 20px",
        //         }}>

        
        <Box style={{ padding: "12px 20px" }}>
        <Box>
          <Box
            className="sidepanel-header add-contact"
            style={{
              display: "flex",
              alignItems: "center",
              borderBottom: "1px solid var(--grey-200)",
              padding: "12px 20px",
            }}
          >
                <a
                    href="javascript:void(0)"
                    className="btn-close"
                    // onClick={() => closeNav1()}
                    onClick={() => handleClose()}
                    style={{ marginRight: "5px", padding: "9px" }}
                ></a>

                <ButtonComponent

                variant={"contained"}
                color={"grey"}

                onClick={()=> generateReport()}
                >Generate report</ButtonComponent>
            </Box>

            <Box className="sidepanel-body add-contact">
            <Box class="sidepanel-body add-contact">
              <Box class="contact-new-head">
                <Typography variant="h6" style={{ fontWeight: "bold" }}>
                  
                  Create report
                </Typography>
              </Box>

              <GridContainer>
                
              <Grid xs="12" md="12" lg="12">
                  <Box className="mb-3">
                    </Box>
                    
                    <Select
                  options={dropDownOptions(templates, "id", "name")}
                  placeholder="Template"
                  onChange={(e)=> setSelectedTemplate(e)}
                  value={selectedTemplate}
                  styles={customStyles(error.template) }
                  className="users-country-select"
                  maxMenuHeight={300}
                />

{error.template && (
                      <Box
                        className="error-msge mb-3"
                        style={{ color: "#dc3545", fontSize: "14px" }}
                      >
                        {error.template}
                      </Box>
                    )}

                    </Grid>

                <Grid xs="12" md="12" lg="12">
                  <Box className="mb-3">
                    </Box>
                    
                    <Select
                  options={dropDownOptions(clientList, "id", "clientName")}
                  placeholder="Client"
                  onChange={handleSelectClient}
                  value={selectedClient}
                  styles={customStyles(error.client) }
                  className="users-country-select"
                  maxMenuHeight={300}
                />

{error.client && (
                      <Box
                        className="error-msge mb-3"
                        style={{ color: "#dc3545", fontSize: "14px" }}
                      >
                        {error.client}
                      </Box>
                    )}

                    </Grid>

                    <Grid xs="12" md="12" lg="12">
                  <Box className="mb-3">
                    </Box>
                    
                    <Select
                  options={policiesOptions(policies , true)}
                  placeholder="Policy"
                  onChange={(e)=> setSelectedPolicy(e)}
                  value={selectedPolicy}
                  styles={customStyles(error.policy) }
                  className="users-country-select"
                  
                  isMulti

                  maxMenuHeight={300}
                />

{error.policy && (
                      <Box
                        className="error-msge mb-3"
                        style={{ color: "#dc3545", fontSize: "14px" }}
                      >
                        {error.policy}
                      </Box>
                    )}

                    </Grid>

                    <Grid xs="12" md="12" lg="12">
                  <Box className="mb-3">
                    </Box>

                    <RenderTextInput 

                    placeholder={"Report name"}
                    onChange={(e)=>setReportName(e.target.value)}
                    value={reportName}

                    errors={error.reportName}
                    />

                    </Grid>
                    </GridContainer>
</Box>

</Box>

            {/* <Box className="settings-header">
                <Typography className="create-report-text">Create report</Typography>
                <Typography className="clients-text">Clients <span>3</span> </Typography>
            </Box> */}
            <Box className="modal-body">
                {/* <NavTabs
                    tabs={[
                        { name: "1 Clients", id: 1 },
                        { name: "2 Policy", id: 2 },
                        { name: "3 Details", id: 3 },
                        { name: "4 Generate", id: 4 },
                    ]}
                    activeTab={tab}
                    onClick={handleTab}
                    xs="12"
                    md="3"
                    lg="3"
                    style={{ color: "red" }}
                /> */}
            </Box>
            <Box className="settings-header">
                {/* <CreateReportClient />   */}
                {/* <CreateReportPolicy /> */}
                {/* {reportsTabSwitch()} */}
            </Box>

            </Box>
            
        </Box>
    )
}

export default CreateReport;