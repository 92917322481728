import { Box, Grid } from "@mui/material";
import React from "react";
import GridContainer from "../../../components/GridContainer";
import Select from "react-select";
import { RenderTextInput } from "../../../components/TextField";
import Switch from "../../../components/Switch";
import { customFilterOption } from "../../../constants";
import { customStyles } from "../../../components/common/CustomStyle"

const Tab3 = ({
  tab,
  handleInspectionDate,
  errors,
  inceptionStartDate,
  handleExpirationDate,
  startDate,
  endDate,
  handleRenewalReminder,
  renewalReminder,
  selectedExclusion,
  handleExclusion,
  exclusion,
  handleExclusionChange,
  handleEvergreenRenewal,
  evergreenRenewal,
  exclusionOption,
  maxDate,

}) => {
  return (
    <Box
      className={`tab-pane fade show ${tab === 3 ? "active" : ""}`}
      id="pills-terms"
      role="tabpanel"
      aria-labelledby="pills-terms-tab"
      tabindex="0"
    >
      <GridContainer className="row">
        <Grid xs="12" md="6" lg="6">
          <Box className="client-text">Inception Date*</Box>
        </Grid>
        <Grid xs="12" md="6" lg="6">
          <RenderTextInput
            type="Date"
            id="basicDate"
            placeholder="Select date"
            data-input
            fullWidth
            onChange={(e) => handleInspectionDate(e.target.value)}
            errors={errors.inceptionDate}
            value={inceptionStartDate}
            onKeyDown={(e) => {
              e.preventDefault();
            }}
            
            startDate={maxDate?maxDate:""}
          />
        </Grid>
      </GridContainer>
      <GridContainer className="row mt-2">
        <Grid xs="12" md="6" lg="6">
          <Box className="client-text">Expiration Date*</Box>
        </Grid>
        <Grid xs="12" md="6" lg="6">
          <RenderTextInput
            type="Date"
            id="basicDate1"
            placeholder="Select date"
            data-input
            fullWidth
            onChange={(e) => handleExpirationDate(e.target.value)}
            errors={errors.expirationDate}
            startDate={startDate ? startDate : ""}
            // disabled={startDate === ""}
            onKeyDown={(e) => {
              e.preventDefault();
            }}
            value={endDate}
          />
        </Grid>
      </GridContainer>
      <hr />
      <GridContainer className="row">
        <Grid xs="12" md="6" lg="6">
          <Box className="client-text">Set renewal reminder</Box>
          <Box className="client-subtext">
            A task reminder will be set 30 days before expiration date
          </Box>
        </Grid>
        <Grid xs="12" md="6" lg="6" className=" ">
          <Switch
            id="checkboxinp"
            type="checkbox"
            onChange={handleRenewalReminder}
            fullWidth
            checked={renewalReminder}
            label={"No"}
          />
        </Grid>
      </GridContainer>
      <hr />
      <GridContainer className="row">
        <Grid xs="12" md="6" lg="6">
          <Box className="client-text">Exclusions</Box>
        </Grid>
        <Grid xs="12" md="6" lg="6">
          {/* <select
            className="form-select"
            aria-label="Default select example"
            value={selectedExclusion}
            onChange={handleExclusion}
          >
            <option selected>Select Exclusion</option>

            <option value="Other">Other</option>
          </select> */}

          <Select

            options={exclusionOption}
            placeholder="Select a exclusion"

            onChange={handleExclusion}

            value={selectedExclusion}
            styles={customStyles()}
          />

        </Grid>
        <Grid xs="12" md="6" lg="6" className=" mt-2">
          <Box className="client-text">If other</Box>
        </Grid>
        <Grid xs="12" md="6" lg="6" className=" mt-2">
          <textarea
            className={errors.exclusion?"is-invalid form-control": "form-control"}
            id="exampleFormControlTextarea1"
            rows="2"
            placeholder="Type exclusion here"
            defaultValue={exclusion}
            onChange={(e) => handleExclusionChange(e)}
          ></textarea>

{errors.exclusion && (
            <Box
              className="error-msge"
              style={{ color: "#dc3545", fontSize: "14px" }}
            >
              {errors.exclusion}
            </Box>
          )}

        </Grid>
        
      </GridContainer>
      <hr />
      <GridContainer className="row">
        <Grid xs="12" md="6" lg="6">
          <Box className="client-text">Tacit/Evergreen Renewal</Box>
        </Grid>
        <Grid xs="12" md="6" lg="6" className=" ">
          <Switch
            id="checkboxinp"
            type="checkbox"
            onChange={handleEvergreenRenewal}
            fullWidth
            checked={evergreenRenewal}
            label={"No"}
          />
        </Grid>
      </GridContainer>
    </Box>
  );
};

export default Tab3;
