import { ACTION_TYPES } from "./ActionTypes";
import NetworkService from "../../network/NetworkService";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  successToast,
  errorToast,
  promiseToast,
  loadingToast,
} from "../../components/common/Toast";
import { startLoading, stopLoading } from "./LoaderAction";

export const CreateUser = (params) => async (dispatch) => {
  loadingToast("Creating User...");
  dispatch(startLoading())
  return await NetworkService.addUser(params)
    .then((response) => {
      toast.dismiss();
      dispatch(stopLoading())
      if (
        response.status === 201 ||
        response.statusText === "OK" ||
        response.ok === true
      ) {
        response.json().then((responseJson) => {
          dispatch(createdUserData("success"));
          setTimeout(
            () => successToast("User has been added successfully"),
            980
          );
        });
      } else {
        if (!response.ok) {
          if (response.status === 404) {
            setTimeout(
              () =>
                errorToast(
                  "The resource you are trying to update was not found"
                ),
              980
            );
          } 
          // else if (response.status === 500) {
          //   setTimeout(() =>
          //     errorToast("Failed add user Please try again later", 980)
          //   );
          // } 
          else {
            response.json().then((responseJson) => {
              dispatch(createdUserData(responseJson));
              // errorToast(responseJson);
              setTimeout(() => errorToast(responseJson), 980);
            });
          }
        }
      }
    })
    .catch((error) => {});
};

export const createdUserData = (payload) => ({
  type: ACTION_TYPES.CREATE_USER_SUCCESS,
  payload,
});
export const getUserList = (params) => async (dispatch) => {
  dispatch(startLoading());
  return await NetworkService.getUsers(params)
    .then((response) => {
      dispatch(stopLoading());
      if (
        response.status === 201 ||
        response.statusText === "OK" ||
        response.ok === true
      ) {
        response.json().then((responseJson) => {
          dispatch(setUserList(responseJson));
        });
      } else {
        if (response.status !== 403 || response.status !== 500) {
          response.json().then((responseJson) => {
            dispatch(setUserListFailure(responseJson?.errors?.[0]));
            // errorToast(responseJson);
          });
        }
      }
    })
    .catch((error) => {});
};

export const setUserList = (payload) => ({
  type: ACTION_TYPES.GET_USER_LIST_SUCCESS,
  payload,
});
export const setUserListFailure = (payload) => ({
  type: ACTION_TYPES.GET_USER_LIST_FAILURE,
  payload,
});

export const getUserListInTasks = (params) => async (dispatch) => {
  dispatch(startLoading());
  return await NetworkService.getUsersInTasks(params)
    .then((response) => {
      dispatch(stopLoading());
      if (
        response.status === 201 ||
        response.statusText === "OK" ||
        response.ok === true
      ) {
        response.json().then((responseJson) => {
          dispatch(setUserList(responseJson));
        });
      } else {
        if (response.status !== 403 || response.status !== 500) {
          response.json().then((responseJson) => {
            dispatch(setUserListFailure(responseJson?.errors?.[0]));
            
          });
        }
      }
    })
    .catch((error) => {});
};

export const getUserById = (params) => async (dispatch) => {
  dispatch(startLoading());

  return await NetworkService.getUserById(params)
    .then((response) => {
      dispatch(stopLoading());

      if (
        response.status === 201 ||
        response.statusText === "OK" ||
        response.ok === true
      ) {
        response.json().then((responseJson) => {
          dispatch(setUserById(responseJson));
        });
      } else {
        // response.json().then((responseJson) => {
        //   dispatch(setUserById(responseJson));
        //   // errorToast(responseJson);
        // });
      }
    })
    .catch((error) => {});
};

export const setUserById = (payload) => ({
  type: ACTION_TYPES.GET_USER_BY_ID_SUCCESS,
  payload,
});
export const updateUser = (params) => async (dispatch) => {
  loadingToast("Updating user...");

  dispatch(startLoading());
  return await NetworkService.updateUser(params)
    .then((response) => {
      toast.dismiss();
      dispatch(stopLoading());

      if (
        response.status === 201 ||
        response.statusText === "OK" ||
        response.ok === true
      ) {
        // response.json().then((responseJson) => {

        dispatch(setUpdatedUser("success"));
        setTimeout(
          () => successToast("User has been updated successfully"),
          980
        );

        // });
      } else {
        if (!response.ok) {
          if (response.status === 404) {
            setTimeout(
              () =>
                errorToast(
                  "The resource you are trying to update was not found"
                ),
              980
            );
          } 
          // else if (response.status === 500) {
          //   setTimeout(() =>
          //     errorToast("Failed update user Please try again later", 980)
          //   );
          // } 
          else {
            response.json().then((responseJson) => {
              dispatch(setUpdatedUser(responseJson));
              // errorToast(responseJson);
              errorToast(() => errorToast(responseJson), 980);
            });
          }
        }
      }
    })
    .catch((error) => {
      console.log(error);
      // setTimeout(
      //   () => errorToast("Failed to update user. Please try again later"),
      //   980
      // );
    });
};

export const setUpdatedUser = (payload) => ({
  type: ACTION_TYPES.UPDATE_USER_SUCCESS,
  payload,
});
export const deleteUser = (params) => async (dispatch) => {
  loadingToast("Deleting user...");
  dispatch(startLoading());

  return await NetworkService.deleteUser(params)
    .then((response) => {
      toast.dismiss();
      dispatch(stopLoading());
      if (
        response.status === 201 ||
        response.statusText === "OK" ||
        response.ok === true
      ) {
        // response.json().then((responseJson) => {

        dispatch(setdeletedUser("success"));
        setTimeout(
          () => successToast("User has been deleted successfully"),
          980
        );

        // });
      } else {
        response.json().then((responseJson) => {
          dispatch(setdeletedUser(responseJson));
          // errorToast(responseJson);
          errorToast(() => errorToast(responseJson), 980);
        });
      }
    })
    .catch((error) => {});
};

export const setdeletedUser = (payload) => ({
  type: ACTION_TYPES.DELETE_USER_SUCCESS,
  payload,
});
export const changeFirstLogin = (params) => async (dispatch) => {
  return await NetworkService.changeFirstLogin(params)
    .then((response) => {
      if (
        response.status === 201 ||
        response.statusText === "OK" ||
        response.ok === true
      ) {
        dispatch(setChangeFirstLogin("success"));
      } else {
        response.json().then((responseJson) => {
          dispatch(setChangeFirstLogin(responseJson));
        });
      }
    })
    .catch((error) => {});
};

export const setChangeFirstLogin = (payload) => ({
  type: ACTION_TYPES.SET_CHANGE_FIRST_LOGIN,
  payload,
});
export const getUserByFilter = (params) => async (dispatch) => {
  dispatch(startLoading());
  return await NetworkService.getUserByFilter(params)
    .then((response) => {
      dispatch(stopLoading());
      if (
        response.status === 201 ||
        response.statusText === "OK" ||
        response.ok === true
      ) {
        response.json().then((responseJson) => {
          dispatch(setUserByFilterData(responseJson));
        });
      } else {
        response.json().then((responseJson) => {
          dispatch(setUserByFilterData(responseJson));
        });
      }
    })
    .catch((error) => {});
};

export const setUserByFilterData = (payload) => ({
  type: ACTION_TYPES.SET_USER_BY_FILTER_DATA,
  payload,
});
