import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import Select from "react-select";
import { Box, Grid, Typography } from "@mui/material";

import ConfirmationModal from "../../components/common/ConfirmationModal";
import GridContainer from "../../components/GridContainer";

import ButtonComponent from "../../components/Button";
import { RenderTextInput } from "../../components/TextField";
import { customStyles } from "../../components/common/CustomStyle";


import { countriesOptions, customFilterOption, validateInput, loggedInUserDetails, blockInvalidChar } from "../../constants";


import { getCountries } from "../../store/actions/CountriesAction";
import { addLocation, updateLocation } from "../../store/actions/ClientAction";
import CloseButton from "../../components/CloseButton";

const AddLocationModal = ({
  clientDetail,
  handleClose,
  page,
  locationdetails,
  title,
}) => {
  const [country, setCountry] = useState([]);

  const [line1, setLine1] = useState("");
  const [line2, setLine2] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [locationname, setLocationname] = useState("");
  const [headcount, setheadcount] = useState("");
  const [locationnumber, setlocationnumber] = useState("");
  const [city, setcity] = useState("");
  const [state, setstate] = useState("");
  const [zipcode, setzipcode] = useState("");

  const [error, setError] = useState({});

  const [showConfirmation, setShowConfirmation] = useState(false);

  //extract specific pieces of state from the Redux store
  const stateData = useSelector((state) => {
    return {
      countries: state?.countries?.Countries,
      
    };
  });

  const dispatch = useDispatch();

  useEffect(() => {
    if (
      page === "edit" &&
      locationdetails !== null &&
      countriesOptions(country)
    ) {
      setLocationname(locationdetails?.locationName);
      setheadcount(locationdetails?.headcount);
      setlocationnumber(locationdetails?.locationNumber);
      setcity(locationdetails?.city);
      setstate(locationdetails?.state);
      let countryoption = country?.filter(
        (item, key) => item.id === locationdetails?.countryId
      );
      // setCountry(locationdetails?.country)

      // setSelectedCountry(countryoption?.[0])
      // handleCountryChange(locationdetails?.countryId)
      let selectedCountryOption = countriesOptions(country)?.find(
        (country) => country.value === locationdetails.countryId
      );
      setSelectedCountry(selectedCountryOption);
      setLine1(locationdetails?.street1);
      setLine2(locationdetails?.street2);
      setzipcode(locationdetails?.zipcode);
    }
  }, [locationdetails, country]);

  //handle to update country
  useEffect(() => {
    if (stateData.countries) {
      setCountry(stateData.countries);
    }
  }, [stateData.countries]);

  
  const handleCloseConfirmation = () => {
    setShowConfirmation(false);
  };
  const handleConfirm = () => {
    handleClose();
  };

  //validation
  const validation = () => {
    const updatedErrors = {
      country: selectedCountry !== "" ? selectedCountry?.value !== "" ?"":"Please Select Country" : "Please Select Country",
      locationname: locationname?.trim() !== "" ? validateInput(locationname) || "" : "Please enter location name",
      headcount: headcount !== "" ? "" : "Please enter head count",
      locationnumber:
        locationnumber?.trim() !== "" ? validateInput(locationnumber) | ""  : "Please enter location number",
      // city:city !==""?"":"Please enter city",
      // state:state !==""?"":"Please enter state",
      line1: line1 !== "" ? validateInput(line1) || ""  : "Please enter line1",
      line2: line2 === "" ? "" : validateInput(line2) || "" ,
      zipcode: zipcode === "" ? "" : validateInput(zipcode) || "" ,
      city: city === "" ? "" : validateInput(city) || "" ,
      state: state === "" ? "" : validateInput(state) || "" ,
      
    };

    setError(updatedErrors);
    return Object.values(updatedErrors).every((error) => error === "");
  };
  const handleAddAndEditlocation = () => {
    const isValid = validation();
    if (isValid) {
      let countryname = country?.filter(
        (item, key) => item.id === selectedCountry?.value
      );

      let payload = {
        ClientId: clientDetail?.id,
        LocationHierarchy: "",
        LocationNumber: locationnumber ? locationnumber : "",
        LocationName: locationname ? locationname : "",
        Description: "",
        ActiveDate: "",
        InactiveDate: "",
        Elevation: "",
        Latitude: "",
        Longitude: "",
        Street1: line1 ? line1 : "",
        Street2: line2 ? line2 : "",
        City: city ? city : "",
        State: state ? state : "",
        Zipcode: zipcode ? zipcode : "",

        County: countryname?.name ? countryname?.name : "",

        Country: countryname?.[0]? countryname?.[0] : "",
        CountryId: selectedCountry?.value ? selectedCountry?.value : "",
        Headcount: headcount ? headcount : 0,
        ActionPerformedBy: loggedInUserDetails?.sub?loggedInUserDetails?.sub:"",
      };
      if (page === "edit") {
      payload.Id= locationdetails?.id
      payload.isHQ= locationdetails?.isHQ
      payload.ClientProducingBroker =  clientDetail?.producingBrokerId
      payload.createdOn = locationdetails?.createdOn
      payload.createdBy = locationdetails?.createdBy

      dispatch(updateLocation(payload))
      }
      else {
      dispatch(addLocation(payload));
      }
    }
  };

  const handleDiscard = () => {
    setLocationname("");
    setheadcount("");
    setlocationnumber("");
    setcity("");
    setstate("");
    setSelectedCountry("");
    setLine1("");
    setLine2("");
    setzipcode("");
  };

  const handleCountryChange = (event) => {
    setSelectedCountry(event);

    setError((prevState) => ({
      ...prevState,
      country: "",
    }));
  };

  const resetFieldError = (field , name) =>
    setError((prevState) => ({
      ...prevState,
      [field]: name?validateInput(name) || "":"",
    }));

  return (
    <>
      {showConfirmation && (
        <ConfirmationModal
          showConfirmation={showConfirmation}
          handleCloseConfirmation={handleCloseConfirmation}
          handleConfirm={handleConfirm}
          title={"Location"}
          subTitle={"discard this location"}
          buttonname={"Discard"}
        />
      )}

      <Box style={{ padding: "12px 20px" }}>
        <Box>
          <Box
            className="sidepanel-header add-contact"
            style={{
              display: "flex",
              alignItems: "center",
              borderBottom: "1px solid var(--grey-200)",
              padding: "12px 20px",
            }}
          >
            {/* <a
              href="javascript:void(0)"
              className="btn-close"
              onClick={() => handleClose()}
              style={{ marginRight: "5px", padding: "9px" }}
            ></a> */}

            <Typography sx={{marginRight: "5px"}}>
              <CloseButton onClick={() => handleClose()} />
            </Typography>

            <ButtonComponent
              className="btn btn-default add-policy btn-md mr-8"
              style={{ backgroundColor: "grey", color: "#FFF" }}
              variant={"contained"}
              color={"grey"}
              onClick={() => handleAddAndEditlocation()}
            >
              Save
            </ButtonComponent>
            <span style={{ marginLeft: "10px" }}>
              <ButtonComponent
                className="btn btn-default add-policy btn-md revoke-cancellation-btn"
                onClick={() => setShowConfirmation(true)}
                variant={"outlined"}
                color={"white"}
              >
                Discard
              </ButtonComponent>
            </span>
          </Box>
          <Box className="sidepanel-body add-contact">
            <Box class="sidepanel-body add-contact">
              <Box class="contact-new-head">
                <Typography variant="h6" style={{ fontWeight: "bold" }}>
                  
                  {title}
                </Typography>
              </Box>
              <GridContainer>
                <Grid xs="12" md="12" lg="12">
                  <Box className="mb-3">
                    <RenderTextInput
                      value={locationname}
                      onChange={(e) => {
                        setLocationname(e.target.value);
                        resetFieldError("locationname" , e.target.value);
                      }}
                      label="Location name*"
                      placeholder="Location name"
                      block
                      required
                      errors={error.locationname}
                    />
                  </Box>
                </Grid>
                <Grid xs="12" md="12" lg="12">
                  <Box className="mb-3">
                    <RenderTextInput
                      value={headcount}
                      onChange={(e) => {
                        setheadcount(e.target.value);
                        resetFieldError("headcount");
                      }}
                      label="Head count*"
                      placeholder="Head count"
                      block
                      required
                      type="number"
                      errors={error.headcount}
                      onKeyDown={blockInvalidChar}
                    />
                  </Box>
                </Grid>
                <Grid xs="12" md="12" lg="12">
                  <Box className="mb-3">
                    <RenderTextInput
                      value={locationnumber}
                      onChange={(e) => {
                        setlocationnumber(e.target.value);
                        resetFieldError("locationnumber" , e.target.value);
                      }}
                      label="Location number*"
                      placeholder="Location number"
                      block
                      required
                      errors={error.locationnumber}
                    />
                  </Box>
                </Grid>
                <Grid xs="12" md="12" lg="12">
                  <Box className="mb-3">
                    <RenderTextInput
                      value={city}
                      onChange={(e) => {setcity(e.target.value);resetFieldError("city" , e.target.value)}}
                      label="City"
                      placeholder="City"
                      block
                      required
                      errors={error.city}

                      // errors={error.city}
                    />
                  </Box>
                </Grid>
                <Grid xs="12" md="12" lg="12">
                  <Box className="mb-3">
                    <RenderTextInput
                      value={state}
                      onChange={(e) => {setstate(e.target.value);resetFieldError("state" , e.target.value)}}
                      label="State"
                      placeholder="State"
                      block
                      required
errors={error.state}
                      // errors={error.state}
                    />
                  </Box>
                </Grid>

                {/*Modified By: Anjali
                   Modified On: 15-03-2024
                   JiraId: https://bosframework.atlassian.net/browse/WBN-231
                   Reason: mandatory sign (*) display at the Address and Country fields */}

                <Grid xs="12" md="12" lg="12">
                  <Box className="mb-3">
                    <label
                      for="exampleFormControlInput1"
                      className="form-label"
                    >
                      Address
                    </label>
                    <RenderTextInput
                      type="text"
                      // className=" mb-1"
                      // id="exampleFormControlInput1"
                      placeholder="Line 1*"
                      onChange={(e) => {
                        setLine1(e.target.value);
                        resetFieldError("line1" , e.target.value);
                      }}
                      fullWidth
                      required
                      block
                      errors={error.line1}
                      value={line1}
                    />
                    <Box sx={{ marginTop: "5px" }}>
                      <RenderTextInput
                        type="text"
                        // class="form-control"
                        // id="exampleFormControlInput1"
                        placeholder="Line 2"
                        onChange={(e) => {setLine2(e.target.value);resetFieldError("line2" , e.target.value)}}
                        required
                        value={line2}
                        errors={error.line2}
                      />
                    </Box>
                    <Box sx={{ marginTop: "5px" }}>
                      <Select
                        aria-label="Default select example"
                        placeholder="Select a country*"
                        onChange={handleCountryChange}
                        value={selectedCountry}
                        styles={customStyles(error.country)}
                        filterOption={customFilterOption}
                        // options={countryoption}
                        options={countriesOptions(country)}
                      />
                      {/* // > */}
                    </Box>
                    {error.country && (
                      <Box
                        className="error-msge mb-3"
                        style={{ color: "#dc3545", fontSize: "14px" }}
                      >
                        {error.country}
                      </Box>
                    )}
                    <div style={{ marginTop: "5px" }}>
                      <RenderTextInput
                        type="text"
                        placeholder="Zipcode"
                        onChange={(e) => {setzipcode(e.target.value);resetFieldError("zipcode" , e.target.value)}}
                        required
                        value={zipcode}
                        errors={error.zipcode}
                      />
                    </div>
                  </Box>
                </Grid>
              </GridContainer>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default AddLocationModal;
