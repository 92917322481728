import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

import Select from "react-select";
import CreatableSelect from "react-select/creatable";

//Material-UI components
import { Box, Grid, InputAdornment, Typography } from "@mui/material";

//custom material-UI components
import Table from "../../components/Table";

import GridContainer from "../../components/GridContainer";
import Button from "../../components/Button";
import ButtonComponent from "../../components/Button";
import { RenderTextInput } from "../../components/TextField";
import Dialog from "../../components/Dialog";
import Switch from "../../components/Switch";
import CloseButton from "../../components/CloseButton";

import CancelPolicyModal from "./CancelPolicyModal";
import RenewPolicyModal from "./RenewPolicy";
import RevokeCancellationModal from "./ConfirmtionModal";
import {
  DOCUMENTS_API_URL,
  DOCUMENT_COUMNS,
  REACT_APP_GATEWAY_API_URL,
  countriesOptions,
  customFilterOption,
  dateFormat,
  dropDownOptions,
  
} from "../../constants";

import DocumentsUpload from "./DocumentsUpload";
import PIIConfirmationModal from "../../components/common/PIIConfirmationModal";

import {
  GetGetCoverageTypes,
  getMember,
  GetLanguages,
  GetPolicyTypes,
  GetCurrencies,
  Insurer,
  CancelPolicy,
  RenewalPolicy,
  RevokeCancellation,
  getPolicyDetails,
  setCancelPolicyData,
  setRevokeCancellationData,
  setRenewalPolicyData,
  GetGetCoverageTypesById,
  getCoverageDetailsData,
  setUpdatedPolicyData,
  setDeletedDocumentData,
  getBrokerForPolicy,
  getDocumentsForPolicy,
  setCoverageTypesById,
  setCoverageDetailsData,
  setCoverageData,
  setPolicyData,
} from "../../store/actions/ClientAction";
import { getCountries } from "../../store/actions/CountriesAction";
import {
  getDocuemntsData,
  setupdateDocumentsData,
  uploadDocumentData,
} from "../../store/actions/DocumentsAction";
import ConfirmationModal from "../../components/common/ConfirmationModal";
import ReactCountryFlag from "react-country-flag";
import { countryIdToCodeMap } from "../../constants/Countrycodes";
import renderField from "./renderCoverageFields";

function PolicyDetail({
  policyDetails,
  handleClose,
  fileupload,
  showprogress,
  progressbarpercentage,
  selectedFiles,
  documentTypes,
  handleChangedocumentType,
  selecteddocumentType,
  base64,
  fileType,
  handleremovefile,
  clientDetail,
  filename,
  handlefilename,
  handleEdit,
  handleCloseEditPolicy,

  handleDeleteDocument,
  handleCloseConfirmation,
  showConfirmation,

  handleConfirm,
}) {
  const [coverageTypes, setCoverageTypes] = useState([]);
  const [policyNumber, setPolicyNumber] = useState("");
  const [currencies, setcurrencies] = useState([]);
  const [language, setLanguage] = useState([]);
  const [policyType, setPolicyType] = useState([]);
  const [countries, setCountries] = useState([]);
  const [selectedCurrency, setCurrency] = useState(
    policyDetails?.currency?.id
  );
  const [evergreenRenuwal, setevergreenRenuwal] = useState(
    policyDetails?.isAutoRenewal
  );
  const [renuwalremainder, setrenuwalremainder] = useState(false);
  const [totalestimatedannualpremium, settotalestimatedannualpremium] =
    useState("");
  const [selectedPaymentFrequency, setPaymentFrequency] = useState("");
  const [commisionRate, setCommisionRate] = useState("");
  const [charges, setCharges] = useState("");
  const [taxes, setTaxes] = useState("");
  const [totalestimatedannualcommision, settotalestimatedannualcommision] =
    useState("");
  const [selectedCommisionPaymentFrequency, setCommisionPaymentFrequency] =
    useState("");
  const [inceptionDate, setinceptiondate] = useState("");
  const [expirationdate, setexpirationdate] = useState("");
  const [premiumpaid, setpremiumpaid] = useState("");
  const [selectedPolicyCountry, setPolicyCountry] = useState("");
  const [selectedPolicyType, setSelectedPolicyType] = useState(
    policyDetails?.policyType?.id
  );
  const [locallyAdmittedCoverage, setLocallyAdmittedCoverage] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showrenewModal, setShowRenewModal] = useState(false);
  const [showRevokeCancellation, setShowRevokeCancellation] = useState(false);
  const [selectedgCoverage, setselectedgCoverage] = useState("");
  const [disableinceptionDate, setDisableinceptionDate] = useState(false);
  const [disableexpirationDate, setDisableexpirationdisable] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");
  const [isInsure, setInsure] = useState([]);
  const [insuredName, setInsuredName] = useState("");
  const [exclusion, setexclusion] = useState("");
  const [formData, setFormData] = useState([]);
  const [numberlimit, setnumberlimit] = useState(true);
  const [showDocumentUpload, setShowDocumentUpload] = useState(false);
  const [sortDocumentsASC, setSortDocumentsASC] = useState(false);
  const [sortdocumentsName, setSortDocumentsName] = useState("")
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [coverageFields, setCoverageFields] = useState([]);
  const [brokerForPolicy, setBrokerForPolicy] = useState([]);
  const [policyDetailsData, setPolicyDetailsData] = useState("");
  const [fieldData, setFieldData] = useState("")
  const [policyDocuments, setPolicyDocuments] = useState([]);

  const Document_API_URL =
  `${REACT_APP_GATEWAY_API_URL}/Document/Graphql`;

  //extract specific pieces of state from the Redux store
  const stateData = useSelector((state) => {
    return {
      coverageTypes: state?.fetchData?.coverageTypes,

      currencies: state?.fetchData?.currenciesData,
      membersadata: state?.fetchData?.memberData,
      countries: state?.countries?.Countries,
      language: state?.fetchData?.laguagesData?.data,
      policyData: state?.fetchData?.policyTypesData?.data,

      coverageData: state?.fetchData?.coverageTypeById,
      insurer: state?.fetchData?.insureData,
      addpolicyData: state?.fetchData?.addpolicyData,
      cancelPolicyData: state?.fetchData?.cancelPolicyData,
      renewalPolicyData: state?.fetchData?.renewalPolicyData,
      revokeCancellationData: state?.fetchData?.revokeCancellationData,
      clientDetailsData: state?.fetchData?.clientDetailsData,
      coverageDetailsData: state?.fetchData?.coverageDetailsData,
      policyDetailsData: state?.fetchData?.policyDetailsData,
      uploaddocumentdata: state?.documents?.uploadDocument,
      updatedpolicydata: state?.fetchData?.updatedPolicyData,
      deletedDocument: state?.fetchData?.deletedDocument,
      brokerforpolicyData: state?.fetchData?.brokerforpolicyData,
      documentsForPolicyData:state?.fetchData?.documentsForPolicyData,
      updateddocumentsData: state?.documents?.updateddocumentsData,

    };
  });

  const dispatch = useDispatch();

  let isFirstref = useRef(true);
  useEffect(() => {
    const {
      coverageTypes,
      countries,
      membersadata,
      language,
      policyData,
      insurer,
      currencies,
    } = stateData;
    if (isFirstref.current) {
      isFirstref.current = false;
    } else {
      if (!coverageTypes) {
        dispatch(GetGetCoverageTypes());
      }
      if (countries?.length === 0) {
        dispatch(getCountries());
      }
      if (!membersadata) {
        dispatch(getMember());
      }
      if (!language) {
        dispatch(GetLanguages());
      }
      if (!policyData) {
        dispatch(GetPolicyTypes());
      }
      if (!insurer) {
        dispatch(Insurer());
      }
      if (!currencies) {
        dispatch(GetCurrencies());
      }
    }
  }, []);

  useEffect(()=> {

    return () => {
      dispatch(setCoverageDetailsData(""))
      dispatch(setCoverageData(""))
    }
  } , [])

  useEffect(()=> {

    return () => {
      dispatch(setCoverageTypesById(""))
    }
  } , [])
  
  useEffect(() => {
    if (stateData.coverageDetailsData?.coverageFields) {
      // setselectedgCoverage(stateData?.coverageDetailsData?.coverageTypeId)
      setCoverageFields(stateData.coverageDetailsData?.coverageFields);
    }
  }, [stateData.coverageDetailsData?.coverageFields]);

  //update currencies
  useEffect(() => {
    if (stateData.currencies) {
      setcurrencies(stateData.currencies);
    }
  }, [stateData.currencies]);

  //update language
  useEffect(() => {
    if (stateData.language) {
      setLanguage(stateData.language);
    }
  }, [stateData.language]);

  useEffect(() => {
    if (stateData.brokerforpolicyData?.data?.brokersForPolicy?.nodes) {
      setBrokerForPolicy(stateData.brokerforpolicyData?.data?.brokersForPolicy?.nodes);
    }
  }, [stateData.brokerforpolicyData?.data?.brokersForPolicy?.nodes]);

  //update policytype
  useEffect(() => {
    if (stateData.policyData) {
      setPolicyType(stateData.policyData);
    }
  }, [stateData.policyData]);

  //update coveragetypes
  useEffect(() => {
    if (stateData.coverageTypes) {
      setCoverageTypes(stateData.coverageTypes);
    }
  }, [stateData.coverageTypes]);

  //update countries
  useEffect(() => {
    if (stateData.countries) {
      setCountries(stateData.countries);
    }
  }, [stateData.countries]);

  //Modified By: Anjali
  //Modified On: 01-12-2024
  //Modified On: 01-12-2024
  //Reason: update when any changes happend in policy details

  //update when any changes happend in policy details
  let secondref = useRef(true);
  useEffect(() => {
    if (secondref.current) {
      secondref.current = false;
    } else {
      if (policyDetails) {
        let payload = {
          isSort:false,
          sortBy:sortDocumentsASC === true ? "ASC" : "DESC",
          sortName:sortdocumentsName
        }
      if(policyDetails?.id) {
        fetchPolicyDocument(policyDetails?.id, clientDetail.id , payload);
      }
        setPolicyDetailsData(policyDetails);
        setPolicyNumber(policyDetails?.policyNumber);
        setInsuredName(policyDetails?.namedInsured);
        settotalestimatedannualpremium(
          policyDetails?.policyPeriodDetail
            ?.totalEstimatedAnnualPremiumExcludingChargesAndTaxes
        );
        setPaymentFrequency(
          policyDetails?.policyPeriodDetail?.premiumPaymentFrequency
        );
        setCommisionRate(
          policyDetails?.policyPeriodDetail?.commissionRate
        );
        setCommisionPaymentFrequency(
          policyDetails?.policyPeriodDetail?.commissionPaymentFrequency
        );
        setCharges(policyDetails?.policyPeriodDetail?.charges);
        setTaxes(policyDetails?.policyPeriodDetail?.taxes);
        settotalestimatedannualcommision(
          policyDetails?.policyPeriodDetail?.totalEstimatedAnnualCommission
        );
        let value = dropDownOptions(isInsure , "id" , "code")?.find(
          (item, key) => item.value === policyDetails?.insurer?.id
        );

        setSelectedValue(value);

        if (countries) {
          let policycountry = countriesOptions(countries)?.find(
            (item, key) => item.value === policyDetails?.country?.id
          );
          // setPolicyCountry({
          //   value: policyDetails?.[0]?.country?.id,
          //   label: policyDetails?.[0]?.country?.country,
          // });
          setPolicyCountry(policycountry);
        }

        setinceptiondate(
          dateFormat(policyDetails?.policyPeriodDetail?.inceptionDate)
        );
        setDisableinceptionDate(true);
        setexpirationdate(
          dateFormat(policyDetails?.policyPeriodDetail?.expirationDate)
        );
        setDisableexpirationdisable(true);
        // setpremiumpaid(convertToInputDate(policyDetails?.[0]?.policyPeriodDetail?.expirationDate))
        setrenuwalremainder(
          policyDetails?.policyPeriodDetail?.isRenewReminderSet
        );
        setLocallyAdmittedCoverage(
          policyDetails?.isLocallyAdmittedCoverage
        );
        setSelectedPolicyType(policyDetails?.policyType?.id);
        setCurrency(policyDetails?.currency?.id)
        setselectedgCoverage(policyDetails?.coverageTypeId);
        if(policyDetails?.id) {
          dispatch(getCoverageDetailsData(policyDetails?.id));

          dispatch(getBrokerForPolicy(policyDetails?.id));
        }
      }
    }
  }, [policyDetails, countries]);

  useEffect(() => {
    if (selectedgCoverage) {
      setCoverageFields([]);
      dispatch(GetGetCoverageTypesById(selectedgCoverage));
    }
  }, [selectedgCoverage]);
  useEffect(() => {
    if (stateData.cancelPolicyData) {
      if (stateData.cancelPolicyData) {
        dispatch(getPolicyDetails(policyDetails?.id));
        handleCloseCancelModal();
        dispatch(setCancelPolicyData(""));
      }
    }
  }, [stateData.cancelPolicyData]);

  useEffect(() => {
    if (stateData.renewalPolicyData) {
      // handleCloseRenewModal()
      dispatch(getPolicyDetails(policyDetails?.id));
      // handleCloseRenewModal()
      handleCloseRenewModal();
      dispatch(setRenewalPolicyData(""));
    }
  }, [stateData.renewalPolicyData]);

  useEffect(() => {
    if (stateData.revokeCancellationData) {
      // handleCloseRevokeCancellationModal()
      dispatch(getPolicyDetails(policyDetails?.id));
      handleCloseRevokeCancellationModal();
      dispatch(setRevokeCancellationData(""));
    }
  }, [stateData.revokeCancellationData]);
  useEffect(() => {
    if (stateData.policyDetailsData) {
      // handleCloseRevokeCancellationModal()

      setPolicyDetailsData(stateData.policyDetailsData?.data?.policyDetails);
    }
  }, [stateData.policyDetailsData]);
  useEffect(() => {
    if (stateData.updatedpolicydata === "success" || stateData?.addpolicyData) {
      // handleCloseRevokeCancellationModal()

      // setPolicyDetailsData(stateData.policyDetailsData?.data?.policyDetails);

      dispatch(getPolicyDetails(policyDetails?.id));
      handleCloseEditPolicy();
      dispatch(setUpdatedPolicyData(""));
      dispatch(setPolicyData(""))
      dispatch(getBrokerForPolicy(policyDetails?.id));
    }
  }, [stateData.updatedpolicydata , stateData?.addpolicyData]);

  //Modified By: Anjali
  //Modified On: 01-12-2024
  //Modified On: 01-12-2024
  //Reason: update converagefields when coverage type changed

  //update converagefields when coverage type changed
  useEffect(() => {
    if (stateData.coverageData?.coverageFields) {
      setCoverageFields(stateData.coverageData?.coverageFields);
    }
  }, [stateData.coverageData]);
  useEffect(() => {
    if (stateData.coverageTypes) {
      setCoverageTypes(stateData.coverageTypes);
    }
  }, [stateData.coverageTypes]);
  useEffect(() => {
    if (stateData.insurer?.data) {
      setInsure(stateData.insurer?.data);
    }
  }, [stateData.insurer]);
  // useDispatch(() => {
  //   dispatch(getDocuemntsData(clientDetail?.id));
  // }, []);

  useEffect(() => {
    if (stateData.deletedDocument === "success-Policy") {
      let payload = {
        isSort:false,
        sortBy:sortDocumentsASC === true ? "ASC" : "DESC",
        sortName:sortdocumentsName
      }

      fetchPolicyDocument(policyDetails?.id, clientDetail?.id , payload);

      handleCloseConfirmation();

      dispatch(setDeletedDocumentData(""));
    }
  }, [stateData.deletedDocument]);

  useEffect(() => {
    if (stateData.uploaddocumentdata === "success-Policy" || stateData?.updateddocumentsData) {
      let payload = {
        isSort:false,
        sortBy:sortDocumentsASC === true ? "ASC" : "DESC",
        sortName:sortdocumentsName
      }
      fetchPolicyDocument(policyDetails?.id, clientDetail.id , payload);
      setShowDocumentUpload(false);
      dispatch(uploadDocumentData(""));
      dispatch(setupdateDocumentsData(""))
    }
  }, [stateData.uploaddocumentdata , stateData?.updateddocumentsData]);
  useEffect(() => {
    if (stateData.documentsForPolicyData ) {
      let data = stateData?.documentsForPolicyData?.data.documentsByPolicy.nodes?.map((item, key) => {
                return {
                  ...item,
                  id: item.documentId,
                };
              });
              setPolicyDocuments(data)
      setPolicyDocuments(data)
    }
  }, [stateData.documentsForPolicyData]);
  
  useEffect(() => {
    if (stateData.coverageDetailsData) {
      const updatedFormData = { ...formData }; // Create a copy of formData
  
      stateData.coverageDetailsData.coverageFields.forEach((field) => {
        updatedFormData[field.coverageFieldId] = field.value; // Update the field value in formData
        updatedFormData[`${field.coverageFieldId}code`] = getCodeFromFieldOptions(field.fieldOptions, field.value); // Update the corresponding code in formData
      });
  
      // Update the state with the new formData
      setFormData(updatedFormData);
    }
  }, [stateData.coverageDetailsData])

  useEffect(()=> {
    if(sortdocumentsName) {
      let payload = {
        isSort:true,
        sortBy:sortDocumentsASC === true ? "ASC" : "DESC",
        sortName:sortdocumentsName
      }
      fetchPolicyDocument(policyDetails?.id , clientDetail?.id , payload)
    }
  } , [sortDocumentsASC , sortdocumentsName])
  
  const fetchPolicyDocument = (id, clientId , policypayload) => {
    let user = JSON.parse(localStorage.getItem("user"))
    let payload = {
      UserId:user?.sub?user?.sub:"",
      ClientId:clientId,
      ProducingFirmId:"5919d327-2e41-4499-b91d-aa027ea6d500",
      
    }

    dispatch(getDocumentsForPolicy(payload , id , policypayload))
    
  };

  const handleDocumentRow = (id) => {};

  const offcanvasElement = document.getElementById("pills-policy-details-tab");
  if (offcanvasElement) {
    offcanvasElement.click();
  }

  const handlePolicyCountry = (event) => {
    // setPolicyCountry(event.target.value);
    setPolicyCountry(event);
  };

  const handleCurrency = (event) => {
    setCurrency(event.target.value);
  };

  const handlePolicyType = (event) => {
    setSelectedPolicyType(event.target.value);
  };

  const handleLocallyAdmittedCoverage = () => {
    // Toggle the state when the checkbox is clicked
    setLocallyAdmittedCoverage(!locallyAdmittedCoverage);
  };
  const handleEvergreenRenuwal = () => {
    // Toggle the state when the checkbox is clicked
    setevergreenRenuwal(!locallyAdmittedCoverage);
  };
  const handlerenuwalremainder = () => {
    // Toggle the state when the checkbox is clicked
    setrenuwalremainder(!renuwalremainder);
  };
  const handlePaymentFrequency = (event) => {
    setPaymentFrequency(event.target.value);
  };
  const handleCommisionPaymentFrequency = (event) => {
    setCommisionPaymentFrequency(event.target.value);
  };

  const handleChange = (newValue) => {
    setSelectedValue(newValue);
  };

  const handleCoverageChange = (event) => {
    setselectedgCoverage(event.target.value);
    dispatch(GetGetCoverageTypesById(event.target.value));
  };

  const handleCloseCancelModal = () => {
    setShowCancelModal(false);
  };
  const handleCloseRevokeCancellationModal = () => {
    setShowRevokeCancellation(false);
  };
  const handleCloseRenewModal = () => {
    setShowRenewModal(false);
  };

  const handleCancelPolicy = (date, reason) => {
    // setShowCancelModal(false)
    let payload = {
      id: policyDetails?.id ? policyDetails?.id : "",
      actionPerformedBy: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      cancellationDate: new Date(date).toISOString(),
      cancellationReasonOther: reason,
    };
    dispatch(CancelPolicy(payload));
  };
  const handleRevokeCancellation = (date, reason) => {
    let payload = {
      id: policyDetails?.id ? policyDetails?.id : "",
      actionPerformedBy: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    };
    dispatch(RevokeCancellation(payload));
  };
  const handleRenewPolicy = (
    inceptionDate,
    expirationDate,
    renewalreminder
  ) => {
    let payload = {
      id: policyDetails?.id ? policyDetails?.id : "",
      actionPerformedBy: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      inceptionDate: new Date(inceptionDate).toISOString(),
      expirationDate: new Date(expirationDate).toISOString(),
      isRenewReminderSet: renewalreminder,
    };
    dispatch(RenewalPolicy(payload));
  };

  const handleInputChangeWithValidation = (id, value) => {
    // dispatch(GetGetCoverageTypesById(event.target.value))
    const isValidInput =
      /^(100(\.0{1,2})?|\d{1,2}(\.\d{1,2})?|0(\.\d{1,2})?)$/.test(value) ||
      value === "";

    if (isValidInput) {
      setnumberlimit(true);
      handleInputChange(id, isValidInput);
    } else {
      setnumberlimit(false);
    }
  };


  const handleFormData = (coverageFieldId, value) => {
    setFieldData({
      ...fieldData,
      [coverageFieldId]: value,
    });
  }

  const handleInputChange = (fieldId, value, fieldName, code, fieldOptions) => {
    
    handleFormData(fieldId, value);
    setFormData((prevFormData) => {
      const updatedFormData = { ...prevFormData };
      if (typeof value === "boolean") {
        updatedFormData[fieldId] = value.toString();
        value.toString() === "true"
          ? (updatedFormData[`${fieldId}code`] = code)
          : delete updatedFormData[`${fieldId}code`];
      } else if (Array.isArray(value)) {
        if (value.length === 0) {
          let fieldcode = getCodeFromFieldOptions(fieldOptions, value);

          delete updatedFormData[fieldId];
          // delete updatedFormData[`${fieldId}code`];
        } else {
          let fieldcode = getCodeFromFieldOptions(fieldOptions, value);

          updatedFormData[fieldId] = value;
          // updatedFormData[`${fieldId}code`] = fieldcode
        }
      } else {
        if (value === "") {
          let fieldcode = getCodeFromFieldOptions(fieldOptions, value);

          delete updatedFormData[fieldId];
          delete updatedFormData[`${fieldId}code`];
        } else {
          let fieldcode = getCodeFromFieldOptions(fieldOptions, value);

          updatedFormData[fieldId] = value;
          updatedFormData[`${fieldId}code`] = fieldcode;
        }
      }

      return updatedFormData;
    });
  }
  
  const getCodeFromFieldOptions = (fieldOptions, selectedValue) => {
    const selectedOption = fieldOptions?.find(
      (option) => option?.fieldOptionId == selectedValue
    );

    return selectedOption ? selectedOption.code : null;
  }

  //Modified By: Anjali
  //Modified On: 01-12-2024
  //Modified On: 01-12-2024
  //Reason: render dynamic coverage fields

  

  const handleCloseDocumentUpload = () => {
    setShowDocumentUpload(false);
  };

  const addBackgroundBlur = () => {
    var sidepanelOpen = document.getElementById("policyDetailsSidepanel");
    sidepanelOpen.classList.add("sidepanel-blur");
  };
  const handleCancelUpload = () => {
    setConfirmationOpen(false);
  };
  const handleConfirmUpload = () => {
    setConfirmationOpen(false);
    setShowDocumentUpload(true);
  };

  
  const handleSortDocument = (e) => {
    
      if(e === sortdocumentsName) {
      setSortDocumentsASC(!sortDocumentsASC)
      }
      
      else {
        setSortDocumentsASC(true)
      }
      
    
        setSortDocumentsName(e)
      
      
  }
  const removeBackgroundBlur = () => {
    var sidepanelClose = document.getElementById("policyDetailsSidepanel");
    sidepanelClose.classList.remove("sidepanel-blur");
  };

  return (
    <Box>
      {showConfirmation && (
        <ConfirmationModal
          showConfirmation={showConfirmation}
          handleCloseConfirmation={() => handleCloseConfirmation()}
          handleConfirm={handleConfirm}
          title={"Document"}
          subTitle={"delete this document"}
          buttonname="Delete"
        />
      )}

      <Box className="sidepanel-header" sx={{ padding: "12px 40px" }}>
        <Typography variant="span">
          <CloseButton onClick={() => handleClose()} />

          <ButtonComponent
            variant={"contained"}
            color={"grey"}
            sx={{ marginLeft: "10px" }}
            onClick={() => handleEdit()}
          >
            Edit
          </ButtonComponent>
        </Typography>
      </Box>
      <div class="border"></div>
      <Box className="sidepanel-body" sx={{ padding: "12px 40px" }}>
        <GridContainer className="row policy-row">
          <Grid xs="12" md="12" lg="12">
            <Box className="offcanvas-body-title d-flex justify-content-start align-items-start">
              {" "}
              {policyDetailsData?.coverageTypeName}{" "}
              <span className="policy-number">
                {policyDetailsData?.policyNumber
                  ? `#${policyDetailsData?.policyNumber}`
                  : ""}
              </span>{" "}
              {policyDetailsData?.isActive ? (
                <span className="body-row active-text ms-3">Active</span>
              ) : (
                <span className="body-row inactive-text ms-3">Inactive</span>
              )}
            </Box>
          </Grid>
        </GridContainer>
        <GridContainer>
          <Grid xs="6" md="6" lg="3">
            <Box className="head-row">Coverage type</Box>
            <Box className="body-row">
              {policyDetailsData?.coverageTypeName}
            </Box>
          </Grid>
          <Grid xs="6" md="6" lg="3">
            <Box className="head-row">Policy number</Box>
            <Box className="body-row">
              {policyDetailsData?.policyNumber}
            </Box>
          </Grid>
          <Grid xs="6" md="6" lg="3">
            <Box className="head-row">Term</Box>
            
            <Box className="body-row">
              {policyDetailsData?.policyPeriodDetail?.term?policyDetailsData?.policyPeriodDetail?.term:1}
            </Box>
          </Grid>
          <Grid xs="6" md="6" lg="3">
            <Box className="head-row">Incpt – Exp Date</Box>
            <Box className="body-row start-date">
              {policyDetailsData?.policyPeriodDetail?.inceptionDate}
            </Box>
            <Box className="body-row end-date">
              {policyDetailsData?.policyPeriodDetail?.expirationDate}
            </Box>
          </Grid>
          <Grid xs="6" md="6" lg="3">
            <Box className="head-row">Country</Box>
            {/* <Box className="flag-country position-relative"> */}
              {/* <Box
                className={`flag ${policyDetailsData?.[0]?.country?.country.toLowerCase()}`}
                id="great-britain"
                sx={{ top: "-12px" }}
              ></Box> */}
            {/* </Box> */}
            <Box className="country">

            <ReactCountryFlag countryCode={countryIdToCodeMap[policyDetailsData?.country?.id]} svg style={{width:"30px" , height:"19px"}} />

              {" "}
              {policyDetailsData?.country?.country}
            </Box>
          </Grid>
        </GridContainer>
        <hr />
        <GridContainer className="row">
          <Grid xs="6" md="6" lg="3">
            <Box className="head-row">Producing</Box>
            <Box className="body-row  mt-4">
              {policyDetailsData?.producingBroker?.memberName}
            </Box>
          </Grid>
          <Grid xs="6" md="6" lg="3">
            <Box className="head-row">Country </Box>
            <Box className="body-row mt-4">
              <ReactCountryFlag countryCode={countryIdToCodeMap[policyDetailsData?.producingBroker?.countryId]} svg style={{width:"30px" , height:"19px"}} />
              {" "}
              {policyDetailsData?.producingBroker?.country?.country}
            </Box>
          </Grid>
          <Grid xs="6" md="6" lg="3">
            <Box className="head-row"> </Box>
            {/* <div className="body-row mt-4">
                email@email.com
                <img src="assets/images/copy.png" className="img-fluid ps-2" />
              </div> */}
          </Grid>
          <Grid xs="6" md="6" lg="3">
            <Box className="head-row"> </Box>
            {/* <div className="body-row mt-4">
                (555) 5555-555
                <img src="assets/images/copy.png" className="img-fluid ps-2" />
              </div> */}
          </Grid>
        </GridContainer>
        <hr />
        <GridContainer className="row">
          <Grid xs="6" md="6" lg="3">
            <Box className="head-row">Servicing</Box>
          </Grid>
          <Grid xs="6" md="6" lg="3">
            <Box className="head-row">Country</Box>
          </Grid>
          <Grid xs="6" md="6" lg="3"></Grid>
          <Grid xs="6" md="6" lg="3"></Grid>
          {brokerForPolicy?.map((item, key) => (
            <React.Fragment key={key}>
              <Grid xs="6" md="6" lg="3">
                {/* <Box className="head-row">Servicing</Box> */}
                <Box className="body-row mt-4">
                  {/* {policyDetailsData?.[0]?.servicingBroker?.memberName} */}
                  {item.memberName}
                </Box>
              </Grid>
              <Grid xs="6" md="6" lg="3">
                <Box className="body-row mt-4">
                <ReactCountryFlag countryCode={countryIdToCodeMap[item?.countryId]} svg style={{width:"30px" , height:"19px"}} />
                  {" "}
                  {item.country}</Box>
              </Grid>
              <Grid xs="6" md="6" lg="3">
                <Box className="head-row"> </Box>
                {/* <div className="body-row mt-4">
                email@email.com
                <img src="assets/images/copy.png" className="img-fluid ps-2" />
              </div> */}
              </Grid>
              <Grid xs="6" md="6" lg="3">
                <Box className="head-row"> </Box>
                {/* <div className="body-row mt-4">
                (555) 5555-555
                <img src="assets/images/copy.png" className="img-fluid ps-2" />
              </div> */}
              </Grid>
            </React.Fragment>
          ))}
        </GridContainer>
        <hr />
        
        {/* show archive tabs */}
        <GridContainer className="row">
          <Box className="accordion accordion-flush" id="accordionFlushExample">
            <Box className="accordion-item">
              {/* <h2 className="accordion-header"> */}
              {/* <button
                    className="accordion-button collapsed ps-0"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseOne"
                    aria-expanded="false"
                    aria-controls="flush-collapseOne"
                  >
                    Show archive
                  </button> */}
              {/* </h2> */}
              <Box
              // id="flush-collapseOne"
              // className="accordion-collapse collapse"
              // data-bs-parent="#accordionFlushExample"
              >
                <Box className=" p-0 mt-2">
                  <ul
                    className="nav nav-pills mb-3 tabs"
                    id="pills-tab"
                    role="tablist"
                  >
                    <li className="nav-item nav-tab" role="presentation">
                      <button
                        className="tab-button nav-link active"
                        id="pills-policy-details-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-policy-details"
                        type="button"
                        role="tab"
                        aria-controls="pills-policy-details"
                        aria-selected="true"
                      >
                        Details
                      </button>
                    </li>
                    <li className="nav-item nav-tab" role="presentation">
                      <button
                        className="tab-button nav-link"
                        id="pills-policy-terms-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-policy-terms"
                        type="button"
                        role="tab"
                        aria-controls="pills-policy-terms"
                        aria-selected="false"
                      >
                        Terms
                      </button>
                    </li>
                    <li className="nav-item nav-tab" role="presentation">
                      <button
                        className="tab-button nav-link"
                        id="pills-policy-premium-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-policy-premium"
                        type="button"
                        role="tab"
                        aria-controls="pills-policy-premium"
                        aria-selected="false"
                      >
                        Premium
                      </button>
                    </li>
                  </ul>
                  <Box className="tab-content" id="pills-tabContent">
                    <Box
                      className="tab-pane fade show active view-policy"
                      id="pills-policy-details"
                      role="tabpanel"
                      aria-labelledby="pills-policy-details-tab"
                      tabindex="0"
                    >
                      <GridContainer className="row accordion-body-row mb-3 mb-3">
                        <Grid
                          xs="12"
                          md="6"
                          lg="6"
                          className="col-12 col-md-6 col-lg-6"
                        >
                          <Box className="client-text">Coverage Type</Box>
                        </Grid>
                        <Grid
                          xs="12"
                          md="6"
                          lg="6"
                          className="col-12 col-md-6 col-lg-6"
                        >
                          <select
                            className="form-select"
                            //   value={policyDetails?.[0]?.coverageTypeId || ""}
                            value={selectedgCoverage}
                            onChange={handleCoverageChange}
                            aria-label="Default select example"
                            disabled
                          >
                            <option selected disabled>
                              Select Coverage
                            </option>

                            {coverageTypes?.map((item, key) => {
                              return (
                                <option value={item.id}>
                                  {item.name}
                                </option>
                              );
                            })}
                          </select>
                        </Grid>
                      </GridContainer>
                      <GridContainer className="row accordion-body-row mb-3 mb-3">
                        <Grid xs="12" md="6" lg="6">
                          <Box className="client-text">Policy Number</Box>
                          <Box className="client-subtext">
                            As seen on the document
                          </Box>
                        </Grid>
                        <Grid xs="12" md="6" lg="6">
                          <RenderTextInput
                            type="email"
                            className="form-control"
                            id="exampleFormControlInput1"
                            placeholder="name@example.com"
                            // value={policyDetails?.[0]?.policyNumber}
                            value={policyNumber}
                            onChange={(e) => setPolicyNumber(e.target.value)}
                            disabled

                            InputProps={{
                              endAdornment: (
                                <InputAdornment
                                  position="end"
                                  sx={{ marginLeft: "27px !important" }}
                                >
                                  {" "}
                                  
                                  <div style={{marginRight:"10px"}}>
                                  <img
                            src="wwwroot/assets/images/hash.png"
                            className="img-fluid"
                          />
                          
                          </div>

                                </InputAdornment>
                              ),
                            }}

                          />
                          
                        </Grid>
                      </GridContainer>
                      <GridContainer className="row accordion-body-row mb-3">
                        <Grid xs="12" md="6" lg="6">
                          <Box className="client-text">Policy Country</Box>
                          <Box className="client-subtext">
                            The country that the policy is covering
                          </Box>
                        </Grid>
                        <Grid xs="12" md="6" lg="6">
                          <Select
                            options={countriesOptions(countries)}
                            placeholder="Select a country"
                            styles={customFilterOption}
                            value={selectedPolicyCountry}
                            onChange={handlePolicyCountry}
                            isDisabled
                          />
                        </Grid>
                      </GridContainer>
                      <GridContainer className="row accordion-body-row mb-3">
                        <Grid xs="12" md="6" lg="6">
                          <Box className="client-text">Language</Box>
                          <Box className="client-subtext">
                            Language in which the document is issued
                          </Box>
                        </Grid>
                        <Grid xs="12" md="6" lg="6">
                          <select
                            className="form-select"
                            value={policyDetailsData?.language?.id}
                            aria-label="Default select example"
                            disabled
                          >
                            <option selected disabled>
                              Select Language
                            </option>

                            {language?.map((item, key) => {
                              return (
                                <option value={item.id}>{item.language}</option>
                              );
                            })}
                          </select>
                        </Grid>
                      </GridContainer>
                      <GridContainer className="row accordion-body-row mb-3">
                        <Grid xs="12" md="6" lg="6" className=" input-div">
                          <div className="client-text">Policy Type/Class</div>
                        </Grid>
                        <Grid xs="12" md="6" lg="6">
                          <select
                            className="form-select"
                            aria-label="Default select example"
                            value={selectedPolicyType}
                            onChange={handlePolicyType}
                            disabled
                          >
                            <option value="" disabled>
                              Select Type/Class
                            </option>

                            {policyType?.map((item, key) => {
                              return (
                                <option value={item.id}>
                                  {item.policyType}
                                </option>
                              );
                            })}
                          </select>
                        </Grid>
                      </GridContainer>
                      <GridContainer className="row accordion-body-row mb-3">
                        <Grid xs="12" md="6" lg="6">
                          <Box className="client-text">
                            Locally Admitted Coverage
                          </Box>
                        </Grid>
                        <Grid xs="12" md="6" lg="6" className=" ">
                          <Switch
                            id="checkboxinp"
                            type="checkbox"
                            // onChange={handleLocallyAdmittedCoverage}
                            checked={locallyAdmittedCoverage}
                            disabled
                          />
                        </Grid>
                      </GridContainer>
                      <GridContainer className="row accordion-body-row mb-3">
                        <Grid xs="12" md="6" lg="6">
                          <Box className="client-text">
                            Insurer / Service Provider
                          </Box>
                        </Grid>
                        <Grid xs="12" md="6" lg="6">
                          <CreatableSelect
                            onChange={handleChange}
                            
                            options={dropDownOptions(isInsure , "id" , "code")}
                            value={selectedValue}
                            isDisabled
                          />
                        </Grid>
                      </GridContainer>
                      <GridContainer className="row accordion-body-row mb-3">
                        <Grid xs="12" md="6" lg="6">
                          <Box className="client-text">Named Insured</Box>
                          <Box className="client-subtext">
                            As seen on the policy document
                          </Box>
                        </Grid>
                        <Grid xs="12" md="6" lg="6">
                          <input
                            type="email"
                            className="form-control"
                            id="exampleFormControlInput1"
                            placeholder="name@example.com"
                            value={insuredName}
                            onChange={(e) => setInsuredName(e.target.value)}
                            disabled
                          />
                        </Grid>
                      </GridContainer>
                      <GridContainer className="row accordion-body-row mb-3 ps-2">
                        <Grid xs="12" md="6" lg="6" className=" ps-0">
                          <Box className="client-text ps-0">
                            Coverage specific
                          </Box>
                        </Grid>
                        <Grid xs="12" md="6" lg="6">
                          {/* {coverageFields?.map((field) => renderField(field))} */}

                          {coverageFields?.map((field) =>
                              renderField(
                                field,
                                formData,
                                handleInputChange,
                                handleInputChangeWithValidation,
                                numberlimit,
                                true,
                                
                              )
                            )}
          
                        </Grid>
                      </GridContainer>
                    </Box>
                    <Box
                      className="tab-pane fade view-policy"
                      id="pills-policy-terms"
                      role="tabpanel"
                      aria-labelledby="pills-policy-terms-tab"
                      tabindex="0"
                    >
                      <GridContainer className="row accordion-body-row mb-3">
                        <Grid xs="12" md="6" lg="6">
                          <Box className="client-text">Inception Date</Box>
                        </Grid>
                        <Grid xs="12" md="6" lg="6">
                          <RenderTextInput
                            type="Date"
                            className="form-control"
                            id="basicDate3"
                            placeholder="Select date"
                            data-input
                            //   value={(policyDetails?.[0]?.policyPeriodDetail?.inceptionDate)}
                            value={inceptionDate}
                            onChange={(e) => setinceptiondate(e.target.value)}
                            // disabled
                            disabled={disableinceptionDate}
                          />
                        </Grid>
                      </GridContainer>
                      <GridContainer className="row accordion-body-row mb-3">
                        <Grid xs="12" md="6" lg="6">
                          <Box className="client-text">Expiration Date</Box>
                        </Grid>
                        <Grid xs="12" md="6" lg="6">
                          <RenderTextInput
                            type="Date"
                            className="form-control"
                            id="basicDate4"
                            placeholder="Select date"
                            data-input
                            value={expirationdate}
                            onChange={(e) => setexpirationdate(e.target.value)}
                            // disabled
                            disabled={disableexpirationDate}
                          />
                        </Grid>
                      </GridContainer>
                      <GridContainer className="row accordion-body-row mb-3">
                        <Grid xs="12" md="6" lg="6">
                          <Box className="client-text">Exclusion</Box>
                        </Grid>
                        <Grid xs="12" md="6" lg="6">
                          <RenderTextInput
                            type="email"
                            className="form-control"
                            id="exampleFormControlInput1"
                            placeholder="Exclusion"
                            disabled
                            value={exclusion}
                          />
                        </Grid>
                      </GridContainer>
                      <GridContainer className="row accordion-body-row mb-3">
                        <Grid xs="12" md="6" lg="6">
                          <Box className="client-text">If other</Box>
                        </Grid>
                        <Grid xs="12" md="6" lg="6">
                          <RenderTextInput
                            type="email"
                            className="form-control"
                            id="exampleFormControlInput1"
                            placeholder="Other"
                            disabled
                          />
                        </Grid>
                      </GridContainer>
                      <GridContainer className="row accordion-body-row mb-3">
                        <Grid xs="12" md="6" lg="6">
                          <Box className="client-text">
                            Tacit/Evergreen Renewal
                          </Box>
                        </Grid>
                        <Grid xs="12" md="6" lg="6" className=" ">
                          <Switch
                            id="checkboxinp"
                            type="checkbox"
                            onChange={handleEvergreenRenuwal}
                            checked={evergreenRenuwal}
                            disabled
                          />
                        </Grid>
                      </GridContainer>
                    </Box>
                    <Box
                      className="tab-pane fade view-policy"
                      id="pills-policy-premium"
                      role="tabpanel"
                      aria-labelledby="pills-policy-premium-tab"
                      tabindex="0"
                    >
                      <GridContainer className="row accordion-body-row mb-3">
                        <Grid xs="12" md="6" lg="6">
                          <Box className="client-text">Currency</Box>
                        </Grid>
                        <Grid xs="12" md="6" lg="6" className=" input-div">
                          {/* <input type="email" className="form-control"
                                                                                    id="exampleFormControlInput1"
                                                                                    placeholder="Currency" /> */}
                          {/* <img src="assets/images/dollar-sign.png" className="img-fluid" /> */}
                          <select
                            className="form-select"
                            aria-label="Default select example"
                            value={selectedCurrency}
                            onChange={handleCurrency}
                            disabled
                          >
                            <option value="" disabled>
                              Select Currency
                            </option>
                            {currencies?.map((item, key) => {
                              return (
                                <option value={item.id}>{item.currency}</option>
                              );
                            })}
                          </select>
                        </Grid>
                      </GridContainer>
                      <GridContainer className="row accordion-body-row mb-3">
                        <Grid xs="12" md="6" lg="6">
                          <Box className="client-text">
                            Total Estimated Annual Premium
                          </Box>
                          <Box className="client-subtext">
                            Is this a calculated number from below inputs?
                          </Box>
                        </Grid>
                        <Grid xs="12" md="6" lg="6" className=" input-div">
                          <RenderTextInput
                            type="email"
                            className="form-control"
                            id="exampleFormControlInput1"
                            placeholder="Estimate amount here"
                            value={totalestimatedannualpremium}
                            onChange={(e) =>
                              settotalestimatedannualpremium(e.target.value)
                            }
                            disabled

                            InputProps={{
                              endAdornment: (
                                <InputAdornment
                                  position="end"
                                  sx={{ marginLeft: "27px !important" }}
                                >
                                  {" "}
                                  
                                  
                              <div style={{    fontSize: "25px" , 
    marginRight: "10px" , 
    color: "#ADB4C0"}}>    
                          {policyDetails?.currency?.symbol?policyDetails?.currency?.symbol:
                          currencies?.find((item , key)=> item?.id === policyDetails?.currency?.id)?.symbol?currencies?.find((item , key)=> item?.id === policyDetails?.currency?.id)?.symbol:
                          ""}
                          </div>
                          
                                </InputAdornment>
                              ),
                            }}

                          />
                        
                        </Grid>
                      </GridContainer>
                      <GridContainer className="row accordion-body-row mb-3">
                        <Grid xs="12" md="6" lg="6">
                          <Box className="client-text">
                            Premium Payment Frequency
                          </Box>
                        </Grid>
                        <Grid xs="12" md="6" lg="6">
                          <select
                            className="form-select"
                            aria-label="Default select example"
                            value={selectedPaymentFrequency}
                            onChange={handlePaymentFrequency}
                            disabled
                          >
                            <option value="" disabled>
                              Frequency
                            </option>
                            <option value="1">Annual</option>
                            <option value="2">Quarterly</option>
                            <option value="3">Monthly</option>
                          </select>
                        </Grid>
                      </GridContainer>
                      <GridContainer className="row accordion-body-row mb-3">
                        <Grid xs="12" md="6" lg="6">
                          <Box className="client-text">Date Premium Paid</Box>
                        </Grid>
                        <Grid xs="12" md="6" lg="6">
                          <RenderTextInput
                            type="Date"
                            className="form-control"
                            id="basicDate5"
                            placeholder="Select date"
                            data-input
                            value={premiumpaid}
                            onChange={(e) => setpremiumpaid(e.target.value)}
                            disabled
                          />
                        </Grid>
                      </GridContainer>
                      <GridContainer className="row accordion-body-row mb-3">
                        <Grid xs="12" md="6" lg="6">
                          <Box className="client-text">Comission Rate</Box>
                        </Grid>
                        <Grid xs="12" md="6" lg="6" className=" input-div">
                          <RenderTextInput
                            type="text"
                            className="form-control"
                            id="basicDate2"
                            placeholder="Commision Rate"
                            data-input
                            value={commisionRate}
                            onChange={(e) => setCommisionRate(e.target.value)}
                            disabled

                            InputProps={{
                              endAdornment: (
                                <InputAdornment
                                  position="end"
                                  sx={{ marginLeft: "27px !important" }}
                                >
                                  {" "}
                                  
                                  <div style={{marginRight:"10px"}}>
                                  <img
                            src="wwwroot/assets/images/percent.png"
                            className="img-fluid"
                          />

                          </div>

                                </InputAdornment>
                              ),
                            }}

                          />
                          
                        </Grid>
                      </GridContainer>
                      <GridContainer className="row accordion-body-row mb-3">
                        <Grid xs="12" md="6" lg="6">
                          <Box className="client-text">
                            Comission Payment Frequency
                          </Box>
                        </Grid>
                        <Grid xs="12" md="6" lg="6">
                          <select
                            className="form-select"
                            aria-label="Default select example"
                            value={selectedCommisionPaymentFrequency}
                            onChange={handleCommisionPaymentFrequency}
                            disabled
                          >
                            <option value="" disabled>
                              Frequency
                            </option>
                            <option value="1">Annual</option>
                            <option value="2">Quarterly</option>
                            <option value="3">Monthly</option>
                          </select>
                        </Grid>
                      </GridContainer>
                      <GridContainer className="row accordion-body-row mb-3">
                        <Grid xs="12" md="6" lg="6">
                          <Box className="client-text">Charges</Box>
                        </Grid>
                        <Grid xs="12" md="6" lg="6" className=" input-div">
                          <RenderTextInput
                            type="email"
                            className="form-control"
                            id="exampleFormControlInput1"
                            placeholder="Charges"
                            value={charges}
                            onChange={(e) => setCharges(e.target.value)}
                            disabled

                            InputProps={{
                              endAdornment: (
                                <InputAdornment
                                  position="end"
                                  sx={{ marginLeft: "27px !important" }}
                                >
                                  {" "}
                                  
                                  
                                  <div style={{    fontSize: "25px" , 
    marginRight: "10px" , 
    color: "#ADB4C0"}}>
                          {policyDetails?.currency?.symbol?policyDetails?.currency?.symbol:
                          currencies?.find((item , key)=> item?.id === policyDetails?.currency?.id)?.symbol?currencies?.find((item , key)=> item?.id === policyDetails?.currency?.id)?.symbol:
                          ""}

                          </div>

                                </InputAdornment>
                              ),
                            }}

                          />
                          
                        </Grid>
                      </GridContainer>
                      <GridContainer className="row accordion-body-row mb-3">
                        <Grid xs="12" md="6" lg="6">
                          <Box className="client-text">Taxes</Box>
                        </Grid>
                        <Grid xs="12" md="6" lg="6" className=" input-div">
                          <RenderTextInput
                            type="email"
                            className="form-control"
                            id="exampleFormControlInput1"
                            placeholder="Taxes"
                            value={taxes}
                            onChange={(e) => setTaxes(e.target.value)}
                            disabled

                            InputProps={{
                              endAdornment: (
                                <InputAdornment
                                  position="end"
                                  sx={{ marginLeft: "27px !important" }}
                                >
                                  {" "}
                                  
                                  
                                  <div style={{    fontSize: "25px" , 
    marginRight: "10px" , 
    color: "#ADB4C0"}}>
                          {policyDetails?.currency?.symbol?policyDetails?.currency?.symbol:
                          currencies?.find((item , key)=> item?.id === policyDetails?.currency?.id)?.symbol?currencies?.find((item , key)=> item?.id === policyDetails?.currency?.id)?.symbol:
                          ""}

                          </div>

                                </InputAdornment>
                              ),
                            }}

                          />
                          
                        </Grid>
                      </GridContainer>
                      <GridContainer className="row accordion-body-row mb-3">
                        <Grid xs="12" md="6" lg="6">
                          <Box className="client-text">
                            Total Estimate Annual Comission
                          </Box>
                          <Box className="client-subtext">
                            Calculated from total estimated premium, commission
                            rate and frequency
                          </Box>
                        </Grid>
                        <Grid xs="12" md="6" lg="6">
                          <Box className="total-amount-div" sx={{display:"flex" , alignItems:"baseline"}}>
                            
                          <div style={{    fontSize: "25px" , 
    marginRight: "10px" , 
    color: "#ADB4C0"}}>
                            {policyDetails?.currency?.symbol?policyDetails?.currency?.symbol:
                            currencies?.find((item , key)=> item?.id === policyDetails?.currency?.id)?.symbol?currencies?.find((item , key)=> item?.id === policyDetails?.currency?.id)?.symbol:
                            ""}
</div>
                            <div className="ps-2">
                              {totalestimatedannualcommision}
                            </div>
                          </Box>
                        </Grid>
                      </GridContainer>
                      <GridContainer className="row accordion-body-row mb-3">
                        <Grid xs="12" md="6" lg="6">
                          <Box className="client-text">Total Premium</Box>
                          <Box className="client-subtext">
                            Calculated from total estimated premium, charges and
                            taxes
                          </Box>
                        </Grid>
                        <Grid xs="12" md="6" lg="6">
                          <Box className="total-amount-div" sx={{display:"flex" , alignItems:"baseline"}}>
                            
                          <div style={{    fontSize: "25px" , 
    marginRight: "10px" , 
    color: "#ADB4C0"}}>
                            {policyDetails?.currency?.symbol?policyDetails?.currency?.symbol:
                            currencies?.find((item , key)=> item?.id === policyDetails?.currency?.id)?.symbol?currencies?.find((item , key)=> item?.id === policyDetails?.currency?.id)?.symbol:
                            ""}
                            
                            </div>

                            <div className="ps-2">
                              {
                                policyDetailsData?.policyPeriodDetail
                                  ?.totalPremium
                              }
                            </div>
                          </Box>
                        </Grid>
                      </GridContainer>
                      <GridContainer className="row accordion-body-row mb-3">
                        <Grid xs="12" md="6" lg="6">
                          <Box className="client-text">Add Broker Fees</Box>
                        </Grid>
                        <Grid xs="12" md="6" lg="6">
                          <RenderTextInput
                            type="email"
                            className="form-control"
                            id="exampleFormControlInput1"
                            placeholder="Broker fees"
                            disabled
                          />
                        </Grid>
                      </GridContainer>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </GridContainer>
        <hr />
        <Box className="mb-3 comments">Comments</Box>
        <GridContainer className="row mb-3 comments-row">
          <Grid xs="12" md="6" lg="6">
            <Box className="client-text">Any additional information</Box>
          </Grid>
          <Grid xs="12" md="6" lg="6" className=" toggle">
            <Box className="client-subtext">
              {policyDetailsData?.comments}
            </Box>
          </Grid>
        </GridContainer>
        <hr />
        <Box className="mb-3 comments">Actions</Box>
        <GridContainer className="row mb-3 comments-row">
          <Grid xs="12" md="6" lg="6">
            <Box className="client-text">Set renewal reminder</Box>
            <Box className="description-text">
              {/* Automatically set reminder at expiry date. <br />
              Reminder is set to 30 days before Expiration date */}
              Automatically set renewal reminder 30 <br/>
              days before expiration date
            </Box>
          </Grid>
          <Grid xs="12" md="6" lg="6" className=" ">
            <Switch
              id="checkboxinp"
              type="checkbox"
              // isRenewReminderSet
              onChange={handlerenuwalremainder}
              checked={renuwalremainder}
              disabled={true}
              // disabled
            />
          </Grid>
        </GridContainer>
        <GridContainer className="mb-3 comments-row">
          <Grid xs="12" md="6" lg="6">
            <Box className="client-text">Renew Policy</Box>
            <Box className="description-text">
              
              This option will only be available 90 days before the policy expiration
            </Box>
          </Grid>
          <Grid
            xs="12"
            md="6"
            lg="6"
            // className="toggle"
          >
            <Button
              type="button"
              className="btn btn-primary renew-policy-btn btn-md"
              variant={!(policyDetails?.isRenewPolicyButtonVisible)||(policyDetails?.isActive === false)?"outlined":"contained"}
              color={!(policyDetails?.isRenewPolicyButtonVisible) ||(policyDetails?.isActive === false)?"white":"primary"}
              // data-bs-toggle="modal"
              // data-bs-target="#renewModal"
              // onClick={() => setShowRenewModal(true)}
              
              onClick={() => handleEdit("renewPolicy")}

              disabled={!(policyDetails?.isRenewPolicyButtonVisible) || (policyDetails?.isActive===false)}
            >
              Renew policy
            </Button>
          </Grid>

          {showrenewModal && (
            <Dialog
              maxWidth="xs"
              maxHeight="150px"
              open={showrenewModal}
              onClose={handleCloseRenewModal}
              // showCloseIcon="false"
              showCloseIcon="true"
              body={
                <RenewPolicyModal
                  showrenewModal={showrenewModal}
                  handleCloseRenewModal={handleCloseRenewModal}
                  handleRenewPolicy={handleRenewPolicy}
                />
              }
            />
          )}
        </GridContainer>
        <hr />
        <GridContainer className="row mb-3 comments-row">
          <Grid xs="12" md="6" lg="6">
            <Box className="client-text">Cancel policy</Box>
            <Box className="description-text">
              Cancel policy on the day to be cancelled. Set a task as a reminder
            </Box>
          </Grid>
          <Grid xs="12" md="6" lg="6">
            {policyDetailsData?.policyPeriodDetail?.isPolicyCancelled ? (
              <Box>
                <Box style={{ color: "red" }}>
                  Policy cancelled on{" "}
                  {policyDetailsData?.policyPeriodDetail?.cancellationDate}
                </Box>
                <Box style={{ fontWeight: "bold" }}>Reason</Box>
                <Box>
                  {
                    policyDetailsData?.policyPeriodDetail
                      ?.cancellationReasonOther
                  }
                </Box>
                <Button
                  type="button"
                  className="btn btn-default add-policy btn-md revoke-cancellation-btn"
                  variant={"contained"}     
                  color={"primary"}
                  // data-bs-toggle="modal"
                  // data-bs-target="#cancelModal"
                  onClick={() => setShowRevokeCancellation(true)}
                >
                  Revoke Cancellation
                </Button>
              </Box>
            ) : (
              <Button
                type="button"
                className="btn btn-default cancel-policy-btn btn-md"
                // data-bs-toggle="modal"
                // data-bs-target="#cancelModal"
                variant={"outlined"}
                color={"red"}
                onClick={() => setShowCancelModal(true)}
              >
                Cancel policy
              </Button>
            )}
          </Grid>

          {showCancelModal && (
            <Dialog
              maxWidth="xs"
              maxHeight="150px"
              open={showCancelModal}
              onClose={handleCloseCancelModal}
              // showCloseIcon="false"
              showCloseIcon="true"
              body={
                <CancelPolicyModal
                  showCancelModal={showCancelModal}
                  handleCloseCancelModal={handleCloseCancelModal}
                  handleCancelPolicy={handleCancelPolicy}

                  // maxExpiration={policyDetails?.policyPeriodDetail?.expirationDate}
                  maxExpiration={expirationdate}

                />
              }
            />
          )}

          {showRevokeCancellation && (
            <Dialog
              maxWidth="xs"
              maxHeight="150px"
              open={showRevokeCancellation}
              onClose={handleCloseRevokeCancellationModal}
              // showCloseIcon="false"
              showCloseIcon="true"
              body={
                <RevokeCancellationModal
                  showRevokeCancellation={showRevokeCancellation}
                  handleCloseRevokeCancellationModal={
                    handleCloseRevokeCancellationModal
                  }
                  handleRevokeCancellation={handleRevokeCancellation}
                />
              }
            />
          )}
        </GridContainer>

        <hr />
        
        <GridContainer className="row table-main-row mb-0">
          <Grid xs="12" md="6" lg="6">
            <Box className="document-name">Documents</Box>
          </Grid>
          <Grid xs="12" md="6" lg="6" className=" text-end mb-2">
            <ButtonComponent
              type="button"
              className="btn btn-default add-name"
              variant={"outlined"}
              color={"white"}
              // onClick={() => setShowDocumentUpload(true)}
              onClick={() => setConfirmationOpen(true)}
            >
              Add documents
            </ButtonComponent>
            {confirmationOpen && (
              <PIIConfirmationModal
                setConfirmationOpen={setConfirmationOpen}
                confirmationOpen={confirmationOpen}
                handleCancelUpload={handleCancelUpload}
                handleConfirmUpload={handleConfirmUpload}
              />
            )}
            {showDocumentUpload && (
              <Dialog
                maxWidth="xs"
                maxHeight="150px"
                open={showDocumentUpload}
                onClose={handleCloseDocumentUpload}
                showCloseIcon="true"
                body={
                  <DocumentsUpload
                    handleFileChange={fileupload}
                    // handleDragOver={handleDragOver}
                    // handleDrop={handleDrop}
                    showprogress={showprogress}
                    progressbarpercentage={progressbarpercentage}
                    selectedFiles={selectedFiles}
                    documentTypes={
                      documentTypes?.length > 0 ? documentTypes : []
                    }
                    handleDocumentType={handleChangedocumentType}
                    selecteddocumentType={selecteddocumentType}
                    base64={base64}
                    fileType={fileType}
                    handleremovefile={handleremovefile}
                    clientDetail={clientDetail}
                    from="Policy"
                    categoryid={policyDetailsData?.id}
                    filename={filename}
                    handlefilename={(e) => handlefilename(e)}
                    handleCloseDocumentUpload={handleCloseDocumentUpload}
                  />
                }
              />
            )}
          </Grid>
          <Grid xs="12" md="12" lg="12" className=" documents-table">
            <Box className="table-responsive">
              {policyDocuments?.length > 0 ? (
                <Table
                  columns={DOCUMENT_COUMNS}
                  rows={policyDocuments}
                  handleRow={handleDocumentRow}
                  from="policyDocument"
                  // handleChange={handleCheckboxChange}
                  handleDelete={(e) => handleDeleteDocument(e)}
                  handleSort={(e)=> handleSortDocument(e)}
                />
              ) : (
                <Grid xs="12" md="12" lg="12" className=" mb-4">
                  <RenderTextInput
                    type="text"
                    className="form-control"
                    placeholder="No Documents added"
                    disabled
                    style={{ backgroundColor: "#FFF" }}
                  />
                </Grid>
              )}
            </Box>
          </Grid>
        </GridContainer>
      </Box>
    </Box>
  );
}

export default PolicyDetail;
