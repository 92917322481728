import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { StyledCheckbox } from '../../components/common/CustomStyle';

const CreateReportDetails = () => {
    return (
        <div>
            <Accordion className='accordion-header'
            //defaultExpanded
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                >
                    <Typography className='accordion-main-text'>Details </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <FormGroup>
                        <FormControlLabel control={<StyledCheckbox defaultChecked />} label="Coverage" />
                        <FormControlLabel control={<StyledCheckbox />} label="Policy number" />
                        <FormControlLabel control={<StyledCheckbox />} label="Policy country" />
                        <FormControlLabel control={<StyledCheckbox />} label="Language" />
                        <FormControlLabel control={<StyledCheckbox />} label="Policy type/class" />
                        <FormControlLabel control={<StyledCheckbox />} label="Locally Admitted Coverage" />
                        <FormControlLabel control={<StyledCheckbox />} label="Insurer/Service Provider" />
                        <FormControlLabel control={<StyledCheckbox />} label="Name Insured" />
                    </FormGroup>
                </AccordionDetails>
            </Accordion>

        </div>
    );
}

export default CreateReportDetails;