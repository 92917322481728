import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import Tasks from '../dasboard/Tasks';
import CreateReportPolicyTable from './CreateReportPolicyTable';

const CreateReportPolicy = () => {
    return (
        <div>
            <Accordion className='accordion-header'
            //defaultExpanded
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                >
                    <Typography className='accordion-main-text'>BioGenetic Sciences Ltd. Policies <span>All</span> </Typography>
                </AccordionSummary>
                <AccordionDetails>

                </AccordionDetails>
            </Accordion>
            <Accordion className='accordion-header'
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                >
                    <Typography className='accordion-main-text'>BioGenetic Sciences Ltd. Policies <span>1</span> </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <CreateReportPolicyTable />
                </AccordionDetails>
            </Accordion>
            <Accordion className='accordion-header'
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                >
                    <Typography className='accordion-main-text'>BioGenetic Sciences Ltd. Policies <span>1</span> </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <CreateReportPolicyTable />
                </AccordionDetails>
            </Accordion>
            <Accordion className='accordion-header'
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                >
                    <Typography className='accordion-main-text'>BioGenetic Sciences Ltd. Policies <span>1</span> </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <CreateReportPolicyTable />
                </AccordionDetails>
            </Accordion>
        </div>
    );
}

export default CreateReportPolicy;