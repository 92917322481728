import { ACTION_TYPES } from "../actions/ActionTypes";
import { MEMBER_FIRM_CONST } from "../actions/actionTypes/MemberFirmActionTypes";

let initialState = {
  memberFirmList: [],
};
const Reducer = (state = initialState, action) => {
  switch (action.type) {
    case MEMBER_FIRM_CONST.GET_MEMBER_FIRM_REQUEST:
      return {
        ...state,
        isLoading: true,
        memberFirmList: [],
        error: "",
      };

    case MEMBER_FIRM_CONST.GET_MEMBER_FIRM_SUCCESS:
      return {
        ...state,
        isLoading: false,
        memberFirmList: action.payload,
        error: "",
      };
    case MEMBER_FIRM_CONST.GET_MEMBER_FIRM_FAILURE:
      return {
        ...state,
        isLoading: false,
        memberFirmList: [],
        error: action.error,
      };

    case MEMBER_FIRM_CONST.GET_MEMEBR_FIRM_BY_ID_REQUEST:
      return {
        ...state,
        isLoading: true,
        memberFirmByidData: "",
        error: "",
      };

    case MEMBER_FIRM_CONST.GET_MEMBER_FIRM_BY_ID_SUCCESS:
      return {
        ...state,
        isLoading: false,
        memberFirmByidData: action.payload,
        error: "",
      };
    case MEMBER_FIRM_CONST.GET_MEMBER_FIRM_BY_ID_FAILURE:
      return {
        ...state,
        isLoading: false,
        memberFirmByidData: "",
        error: action.error,
      };

    case MEMBER_FIRM_CONST.CREATE_MEMBER_FIRM_REQUEST:
      return {
        ...state,
        isLoading: true,
        createdmemberData: "",
        error: "",
      };

    case MEMBER_FIRM_CONST.CREATE_MEMBER_FIRM_SUCCESS:
      return {
        ...state,
        isLoading: false,
        createdmemberData: action.payload,
        error: "",
      };
    case MEMBER_FIRM_CONST.CREATE_MEMBER_FIRM_FAILURE:
      return {
        ...state,
        isLoading: false,
        createdmemberData: "",
        error: action.error,
      };

    case MEMBER_FIRM_CONST.UPDATE_MEMBER_FIRM_REQUEST:
      return {
        ...state,
        isLoading: true,
        updatedmemberData: "",
        error: "",
      };

    case MEMBER_FIRM_CONST.UPDATE_MEMBER_FIRM_SUCCESS:
      return {
        ...state,
        isLoading: false,
        updatedmemberData: action.payload,
        error: "",
      };
    case MEMBER_FIRM_CONST.UPDATE_MEMBER_FIRM_FAILURE:
      return {
        ...state,
        isLoading: false,
        updatedmemberData: "",
        error: action.error,
      };

    case MEMBER_FIRM_CONST.DELETE_MEMBER_FIRM_REQUEST:
      return {
        ...state,
        isLoading: true,
        deletedmemberData: "",
        error: "",
      };

    case MEMBER_FIRM_CONST.DELETE_MEMBER_FIRM_SUCCESS:
      return {
        ...state,
        isLoading: false,
        deletedmemberData: action.payload,
        error: "",
      };
    case MEMBER_FIRM_CONST.DELETE_MEMBER_FIRM_FAILURE:
      return {
        ...state,
        isLoading: false,
        deletedmemberData: "",
        error: action.error,
      };

    case MEMBER_FIRM_CONST.GET_USERS_BY_MEMBER_FIRM_ID_REQUEST:
      return {
        ...state,
        isLoading: true,
        userbymemberData: [],
        error: "",
      };

    case MEMBER_FIRM_CONST.GET_USERS_BY_MEMBER_FIRM_ID_SUCCESS:
      return {
        ...state,
        isLoading: false,
        userbymemberData: action.payload,
        error: "",
      };
    case MEMBER_FIRM_CONST.GET_USERS_BY_MEMBER_FIRM_ID_FAILURE:
      return {
        ...state,
        isLoading: false,
        userbymemberData: [],
        error: action.error,
      };

    case MEMBER_FIRM_CONST.GET_MEMBER_FIRM_BY_NAME_REQUEST:
      return {
        ...state,
        isLoading: true,
        memberListBynameData: [],
        error: "",
      };

    case MEMBER_FIRM_CONST.GET_MEMBER_FIRM_BY_NAME_SUCCESS:
      return {
        ...state,
        isLoading: false,
        memberListBynameData: action.payload,
        error: "",
      };
    case MEMBER_FIRM_CONST.GET_USERS_BY_MEMBER_FIRM_ID_FAILURE:
      return {
        ...state,
        isLoading: false,
        memberListBynameData: [],
        error: action.error,
      };

    case MEMBER_FIRM_CONST.GET_MEMBER_FIRM_BY_CLIENTS_REQUEST:
      return {
        ...state,
        islLoading: true,
        memberListByclientData: [],
        error: "",
      };

    case MEMBER_FIRM_CONST.GET_MEMBER_FIRM_BY_CLIENTS_SUCCESS:
      return {
        ...state,
        islLoading: false,
        memberListByclientData: action.payload,
        error: "",
      };
    case MEMBER_FIRM_CONST.GET_MEMBER_FIRM_BY_CLIENTS_FAILURE:
      return {
        ...state,
        islLoading: false,
        memberListByclientData: [],
        error: action.error,
      };

    case MEMBER_FIRM_CONST.GET_SORTED_MEMBER_FIRM_REQUEST:
      return {
        ...state,
        isLoading: true,
        sortedMemberFirmData: [],
        error: "",
      };

    case MEMBER_FIRM_CONST.GET_SORTED_MEMBER_FIRM_SUCCESS:
      return {
        ...state,
        isLoading: false,
        sortedMemberFirmData: action.payload,
        error: "",
      };
    case MEMBER_FIRM_CONST.GET_SORTED_MEMBER_FIRM_FAILURE:
      return {
        ...state,
        isLoading: false,
        sortedMemberFirmData: [],
        error: action.error,
      };

    default:
      return state;
  }
};
export default Reducer;
