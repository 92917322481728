import { ACTION_TYPES } from "../actions/ActionTypes";
import { COUNTRIES_CONST } from "../actions/actionTypes/CountriesActionTypes";

const intialState = {
  Countries: [],
};
const Reducer = (state = intialState, action) => {
  switch (action.type) {
    case COUNTRIES_CONST.GET_COUNTRIES_REQUEST:
      return {
        ...state,
        isLoading: true,
        Countries: [],
        error: "",
      };

    case COUNTRIES_CONST.GET_COUNTRIES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        Countries: action.payload.data,
        error: "",
      };
    case COUNTRIES_CONST.GET_COUNTRIES_FAILURE:
      return {
        ...state,
        isLoading: false,
        Countries: [],
        error: action.error,
      };

    case COUNTRIES_CONST.GET_REGIONS_REQUEST:
      return {
        ...state,
        isLoading: true,
        regionData: [],
        error: "",
      };

    case COUNTRIES_CONST.GET_REGIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        regionData: action.payload,
        error: "",
      };
    case COUNTRIES_CONST.GET_REGIONS_FAILURE:
      return {
        ...state,
        isLoading: false,
        regionData: [],
        error: action.error,
      };

    case COUNTRIES_CONST.GET_REGIONS_BY_COUNTRIES_REQUEST:
      return {
        ...state,
        isLoading: true,
        regionByCountryData: [],
        error: "",
      };

    case COUNTRIES_CONST.GET_REGIONS_BY_COUNTRIES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        regionByCountryData: action.payload,
        error: "",
      };
    case COUNTRIES_CONST.GET_REGIONS_BY_COUNTRIES_FAILURE:
      return {
        ...state,
        isLoading: false,
        regionByCountryData: [],
        error: action.error,
      };
    default:
      return state;
  }
};

export default Reducer;
