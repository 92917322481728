import { Box, Grid, Typography } from "@mui/material";
import React from "react";

import Card from "../../components/Card";
import GridContainer from "../../components/GridContainer";

import { useNavigate } from "react-router-dom";
import { Paths } from "../../constants";

const Templates = () => {
  let history = useNavigate();

  
  return (
    <Box>
      <Box className="subtext" sx={{ marginTop: "25px" }}>
        Templates
      </Box>
      <GridContainer>
        <Grid
          xs="12"
          md="3"
          lg="3"
          className=" card-parent quick_links"
          //onClick={() => history(Paths.TASKS , {state:{ showModal: true }})}
          sx={{ cursor: "pointer" }}
        >
          <Card className="d-flex flex-row align-items-center card">
            <Typography className="quick-links p-0">
              <img src="wwwroot/assets/images/reports-document-icon.png" className="img-fluid"></img>
            </Typography>
            <Typography sx={{ marginLeft: "10px" }}>
              <Box className="quick-links-text">Full report of business</Box>
              <Box className="quick-links-subtext">Report of all clients</Box>
            </Typography>
          </Card>
        </Grid>
        <Grid
          xs="12"
          md="3"
          lg="3"
          className=" card-parent quick_links"
          //onClick={() => history(Paths.CLIENT_DASHBOARD , {state: { showModal: true }})}
          sx={{ cursor: "pointer" }}
        >
          <Card className="d-flex flex-row align-items-center card">
          <Typography className="quick-links p-0">
              <img src="wwwroot/assets/images/reports-document-icon.png" className="img-fluid"></img>
            </Typography>
            <Typography sx={{ marginLeft: "10px" }}>
              <Box className="quick-links-text">Action Title</Box>
              <Box className="quick-links-subtext">
               Supporting text to go here
              </Box>
            </Typography>
          </Card>
        </Grid>
        <Grid xs="12" md="3" lg="3" className=" card-parent quick_links ">
          <Card className="d-flex flex-row align-items-center card ">
          <Typography className="quick-links p-0">
              <img src="wwwroot/assets/images/reports-document-icon.png" className="img-fluid"></img>
            </Typography>
            <Typography sx={{ marginLeft: "10px" }}>
              <Box className="quick-links-text">Action Title</Box>
              <Box className="quick-links-subtext">
               Supporting text to go here
              </Box>
            </Typography>
          </Card>
        </Grid>
        <Grid xs="12" md="3" lg="3" className=" card-parent quick_links ">
          <Card className="d-flex flex-row align-items-center card ">
          <Typography className="quick-links p-0">
              <img src="wwwroot/assets/images/reports-document-icon.png" className="img-fluid"></img>
            </Typography>
            <Typography sx={{ marginLeft: "10px" }}>
              <Box className="quick-links-text">Action Title</Box>
              <Box className="quick-links-subtext">
               Supporting text to go here
              </Box>
            </Typography>
          </Card>
        </Grid>
      </GridContainer>
    </Box>
  );
};
export default Templates;
