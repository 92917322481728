import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

//Material-UI components
import { Box, Grid, InputAdornment } from "@mui/material";

//Custom MUI components
import PageTitle from "../../components/PageTitle";

import Table from "../../components/Table";
import GridContainer from "../../components/GridContainer";
import ButtonComponent from "../../components/Button";

import Dialog from "../../components/Dialog";
import FilterSection from "../client/FilterSection";

import { dateFormat, REPORTS_COLUMNS, USERS_COLUMNS } from "../../constants";
import CreateReport from "./CreateReport";
import Templates from "./Templates";
import { getClientList } from "../../store/actions/ClientAction";
import DrawerComponent from "../../components/Drawer";
import { getGeneratedReport, getReports, getTemplates } from "../../store/actions/ReportsActions";
import * as base from "../../store/actions/BaseActions"
import { REPORTS_CONST } from "../../store/actions/actionTypes/ReportsActionsTypes";

const Reports = () => {

    const handleReportsDetails = () => {

    }

    const [createReport, setCreateReport] = useState(false);
    const [reports, setReports] = useState([]);

    const statedata = useSelector((state) => {
        return {
          

          
          reports: state?.report?.reports,

          addedreports: state?.report?.addedreports,

        }})

    const dispatch = useDispatch()
    useEffect(()=> {
        let payload = {
            isDraft: false,
            filterTab: "activeOnly"
        }
dispatch(getClientList(payload))

dispatch(getTemplates())

dispatch(getReports())

    } , [])
    
    
    const handleDownloadReport = async(e) => {
        
        let reportName = reports?.filter((item , key)=> item?.id === e)
        dispatch(getGeneratedReport(e , reportName?.[0]?.displayName))


    }


    useEffect(()=> {
        if(statedata?.addedreports) {
          dispatch(base.getSuccess(REPORTS_CONST.CREATE_REPORTS_SUCCESS , ""))
          dispatch(getReports())
          setCreateReport(false)
        }
            } , [statedata?.addedreports])

    useEffect(()=> {
if(statedata?.reports?.data?.reports?.nodes) {
    
    let reportsList = statedata?.reports?.data?.reports?.nodes?.map((item , key)=> {
        return {
            ...item,
            createdOn: item?.createdOn?dateFormat(item?.createdOn):""
        }
    })
// setReports(statedata?.reports?.data?.reports?.nodes)

setReports(reportsList)


}
    } , [statedata?.reports?.data?.reports?.nodes])

    {console.log("reports" , statedata?.reports?.data , reports)}

    
    return (
        <Box id="main" className="main">
            <Box>
                <GridContainer sx={{ marginBottom: "30px !important" }}>
                    <Grid xs={12} md={9} lg={9}>
                        <PageTitle title={"Reports"} />
                    </Grid>
                    {/* {(loggedInUserDetails?.extension_Role !== "MemberContributor") && */}
                    <Grid xs={12} md={3} lg={3}>
                        <ButtonComponent
                            variant={"contained"}
                            color={"grey"}
                            onClick={() => setCreateReport(true)}
                            sx={{ float: "right" }}
                        >
                            Create a report
                        </ButtonComponent>
                        {/* {createReport && (
                            <Dialog
                                maxWidth="xl"
                                height="150px"
                                open={createReport}
                                onClose={() => setCreateReport(false)}
                                showCloseIcon="false"
                                body={
                                    <CreateReport
                                        show={createReport}
                                        handleClose={() => setCreateReport(false)}
                                    />
                                }
                            />
                        )} */}
                        {createReport && (
                            <DrawerComponent
                                // maxWidth="xl"
                                width={350}
                                // height="150px"
                                isOpen={createReport}
                                handleClose={() => setCreateReport(false)}
                                // showCloseIcon="false"
                                body={
                                    <CreateReport
                                        show={createReport}
                                        handleClose={() => setCreateReport(false)}
                                    />
                                }
                            />
                        )}
                    </Grid>
                    {/* }  */}

                    {/* Add user modal */}
                    {/* {showAddUserModal && (
            <Dialog
              className="secondary-modal-show"
              maxWidth="false"
              maxHeight="150px"
              open={showAddUserModal}
              onClose={() => setShowAddUserModal(false)}
              showCloseIcon="false"
              sx={{ p: 0 }}
              body={
                <AddUserModal
                  handleClose={() => setShowAddUserModal(false)}
                  page="add"
                />
              }
            />
          )} */}

                </GridContainer>
            </Box>

            <Box>
                {/* <Templates /> */}
            </Box>
            {/* Filter and search section */}
            <Box className="section client-dashboard mt-4">
                <GridContainer>
                    <Grid xs={12} md={12} lg={12}>
                        <Box className="dashboard-content">
                            <Box className="dashboard-main-head row ms-0 me-0">
                                <GridContainer>
                                    <Grid
                                        xs="12"
                                        md="9"
                                        lg="9"
                                        className=" d-flex align-items-center"
                                    >
                                        <FilterSection
                                            filterTab={"All"}
                                            //viewby={["Region", "Country"]}
                                            filterby={["All", 
                                                // "Most recent", "Archived"
                                            ]}
                                        //handleView={setviewType}
                                        //viewType={viewtype}

                                        showViewBy={false}

                                        />
                                    </Grid>
                                </GridContainer>
                            </Box>
                        </Box>
                    </Grid>
                </GridContainer>
            </Box>
            <Box className="table-responsive">
                <Table
                    columns={REPORTS_COLUMNS}
                    rows={reports}
                    handleRow={(e) => handleReportsDetails(e)}
                    handleDownload={(e) => handleDownloadReport(e)}
                    from="reports"
                />
            </Box>
        </Box>
    )
}
export default Reports;