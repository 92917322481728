import React, { useRef, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { Box, Grid } from "@mui/material";

import InputAdornment from "@mui/material/InputAdornment";

import GridContainer from "../../components/GridContainer";
import Table from "../../components/Table";

import Button from "../../components/Button";


import { RenderTextInput } from "../../components/TextField";

import {
  archiveMultipleClients,
  filterClientByisActive,
  filterClientByisArchive,
  filterClientByisDraft,
  getClient,
  getClientDetails,
  getClientList,
  searchClients,
  setArchiveMutipleClientsData,
  setClientData,
  setPolicyData,
  getMember,
  GetLanguages,
  GetPolicyTypes,
  Insurer,
  GetCurrencies,
  GetGetCoverageTypes,
  getClientDetailsByid,
} from "../../store/actions/ClientAction";
import { GetContact } from "../../store/actions/ContactsAction";

import { getCountries } from "../../store/actions/CountriesAction";
import { getDocumentsTypes } from "../../store/actions/DocumentsAction";
import { CLIENT_DASHBOARD_COLUMNS, REPORTS_CLIENTS } from "../../constants";
import { useLocation, useNavigate } from "react-router-dom";
import { Padding } from "@mui/icons-material";


const ClientDashboard = () => {
  const [clientDetail, setClientDetail] = useState([]);
  const [clients, setClients] = useState([]);

  const [showMainModal, setShowMainModal] = useState(false);
  const [showSecondaryModal, setShowSecondaryModal] = useState(false);
  const [showNewPolicyModal, setShowNewPolicyModal] = useState(false);
  const [viewType, setViewType] = useState("List");
  const [contactsList, setContactsList] = useState(false);
  const [showref, setShowref] = useState(false);
  const [showClientDetailsModal, setShowClientDetailsModal] = useState(false);
  const [clientName, setClientName] = useState("");

  const [filterTab, setFilterTab] = useState("All");

  const [id, setId] = useState([]);

  //get state from store
  const stateData = useSelector((state) => {
    return {
      clientsData: state?.fetchData?.clientListData,
      clientData: state?.fetchData?.clientdata,
      addpolicyData: state?.fetchData?.addpolicyData,
      contactsList: state?.contact?.contactListData,
      addedfielddata: state?.fetchData?.addedfielddata,
      clientDetailsData: state?.fetchData?.clientDetailsData,
      clientsListData: state?.fetchData?.clientListData,
      archivedMutipleClientsData: state?.fetchData?.archivedMutipleClientsData,
    };
  });

  const dispatch = useDispatch();

  const location = useLocation();
  const navigate = useNavigate()
  const showModal = location.state && location.state.showModal

  // Fetch data from Redux store on component mount

  useEffect(()=> {
    if(showModal) {
      setShowMainModal(true)
      setShowMainModal(true)
      navigate({ pathname: location.pathname })
    }
  } , [showModal])

  useEffect(() => {

    if (stateData.contactsList?.data?.contacts) {
      setContactsList(stateData.contactsList?.data?.contacts);
    }
  }, [stateData.contactsList?.data?.contacts]);

  useEffect(() => {
    if (stateData.clientDetailsData) {
      // setClientDetail(stateData.clientDetailsData?.data.clientsDetails[0])
      setClientDetail(stateData.clientDetailsData?.data?.clientsDetails[0]);
      fetchData();
    }
  }, [stateData.clientDetailsData]);
  useEffect(() => {
    if (stateData.archivedMutipleClientsData === "success") {
      fetchData();
      setId([]);

      dispatch(setArchiveMutipleClientsData(""));
    }
  }, [stateData.archivedMutipleClientsData]);

  useEffect(() => {

    fetchData();
    dispatch(getCountries());
    dispatch(getMember());

    dispatch(GetLanguages());
    dispatch(GetPolicyTypes());
    dispatch(Insurer());
    dispatch(GetCurrencies());
    dispatch(GetGetCoverageTypes());
    dispatch(getDocumentsTypes());
  }, []);

  const fetchData = async () => {
    dispatch(getClientList());
  };

  useEffect(() => {
    dispatch(getClient());
  }, []);

  useEffect(() => {
    if (stateData.clientsListData?.data?.clients_List?.nodes) {
      setClients(stateData?.clientsListData?.data?.clients_List?.nodes);
    }
  }, [stateData.clientsListData?.data?.clients_List?.nodes]);

  
  const handlesetclientName = (name) => {
    setClientName(name);
  };
  const handleRow = (id) => {
    
    showClientDetails(id);
  };

  const showClientDetails = (id) => {
    setShowClientDetailsModal(true);

    // dispatch(GetContact(id));
    const fetchData = async () => {
      dispatch(getClientDetails(id));
    };

    fetchData();
  };

  const handleShowSecondaryModal = () => {
    setShowMainModal(false);
    setShowSecondaryModal(true);
  };
  const handleCloseSecondaryModal = () => {
    setShowSecondaryModal(false);
  };
  const handleCloseMainModal = () => {
    setShowMainModal(false);
  };
  const handleCheckboxChange = (event) => {
    const elementId = event.id;

    // Check if the ID already exists in the array
    if (id.includes(elementId)) {
      // If it exists, remove it
      let filteredIds = id.filter((existingId) => existingId !== elementId);
      setId(filteredIds);
    } else {
      // If it doesn't exist, add it to the array
      let updatedIds = [...id, elementId];
      setId(updatedIds);
    }
  };

  const locationRef = useRef(null);
  const handleOpenlocation = (id) => {
    showClientDetails(id);
    handleCloseMainModal();
    setShowref(true);

    let element = document.getElementsByClassName("locationRef");

    if (element?.length > 0) {
      element[0].onscroll({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  const searchclients = (e) => {
    if (e?.length > 1) {
      dispatch(searchClients(e));
    }
    else {
      dispatch(getClientList())
    }
  };
  const handleCloseNewPolicyModal = () => {
    setShowNewPolicyModal(false);
  };

  const handleFilter = (type) => {
    setFilterTab(type);
    setClients([]);
    if (type === "Active") {
      dispatch(getClientList(true));
    } else if (type === "Drafts") {
      dispatch(getClientList(true));
    } else if (type === "Archived") {
      dispatch(getClientList(true));
    } else if (type === "All") {
      fetchData();
    }
  };


  const handleArchiveClients = () => {
    let payload = {
      clientIds: id,
      actionPerformedBy: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    };
    dispatch(archiveMultipleClients(payload));
  };
  
  
  return (
    <>

      <Box>

          <Box className="section client-dashboard">
            <GridContainer>
              <Grid xs="12" md="12" lg="12">
                <Box className="dashboard-content">
                  <Box className="dashboard-main-head row me-0" sx={{ width: "99%" , marginLeft: "1px" }}>
                    <GridContainer
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        border: "1px solid #dfdfdf",
                        borderRadius:'8px 8px 0 0',
                        padding:'8px 20px'

                      }}
                    >
                      {/* Client Search bar */}

                      <Grid xs="12" md="12" lg="12">
                        <RenderTextInput
                          placeholder="Type to search clients"
                          id="outlined-start-adornment"
                          sx={{ m: 0, width: "100%" }}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                {" "}
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  viewBox="0 0 16 16"
                                  fill="none"
                                >
                                  <path
                                    d="M14 14L11.1 11.1M12.6667 7.33334C12.6667 10.2789 10.2789 12.6667 7.33334 12.6667C4.38782 12.6667 2 10.2789 2 7.33334C2 4.38782 4.38782 2 7.33334 2C10.2789 2 12.6667 4.38782 12.6667 7.33334Z"
                                    stroke="#98A2B3"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </svg>
                              </InputAdornment>
                            ),
                          }}
                          onChange={(e) => searchclients(e.target.value)}
                        />
                      </Grid>
                      {/* </Box> */}
                    </GridContainer>

                    {/* Client Table Header and table details */}
                    <Box className="tab-content p-0" id="myTabContent">
                        <Box className="table-responsive">
                          <Table
                            columns={REPORTS_CLIENTS}
                            rows={clients}
                            handleRow={handleRow}
                            from="client"
                            handleChange={handleCheckboxChange}
                            checkedId={id}
                          />
                        </Box>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </GridContainer>
          </Box>

      </Box>
    </>
  );
};

export default ClientDashboard;
