import { PROFILE_CONST } from "../actions/actionTypes/ProfileActionTypes";
import { TASKS_CONST } from "../actions/actionTypes/TasksActionTypes";

let initialState = {
    changePassword: "",
  
};
const Reducer = (state = initialState, action) => {
  switch (action.type) {
    case PROFILE_CONST.CHANGE_PASSWORD_REQUEST:
      return {
        ...state,
        changePassword: "",
        isLoading: true,
      };
    case PROFILE_CONST.CHANGE_PASSWORD_SUCCESS:
      {console.log(action.payload)}
      return {
        ...state,
        changePassword: action.payload,
        isLoading: false,
        error: "",
      };
    case PROFILE_CONST.CHANGE_PASSWORD_FAILURE:
      return {
        ...state,
        changePassword: "",
        isLoading: false,
        error: action.error,
      };
    case PROFILE_CONST.GET_TIMEZONES_REQUEST:
      return {
        ...state,
        timezones: [],
        isLoading: true,
      };
    case PROFILE_CONST.GET_TIMEZONES_SUCCESS:
      
      return {
        ...state,
        timezones: action.payload,
        isLoading: false,
        error: "",
      };
    case PROFILE_CONST.GET_TIMEZONES_FAILURE:
      return {
        ...state,
        timezones: "",
        isLoading: false,
        error: action.error,
      };
    

    default:
      return state;
  }
};
export default Reducer;
