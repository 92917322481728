import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import ReactCountryFlag from "react-country-flag";

//MUI-Components
import { Box, Grid, Typography } from "@mui/material";

//custom MUI-Components
import Table from "../../components/Table";
import GridContainer from "../../components/GridContainer";
import DrawerComponent from "../../components/Drawer";
import Button from "../../components/Button";
import Dialog from "../../components/Dialog";
import { RenderTextInput } from "../../components/TextField";
import CloseButton from "../../components/CloseButton";
import PIIConfirmationModal from "../../components/common/PIIConfirmationModal";
import ConfirmationModal from "../../components/common/ConfirmationModal";

import PolicyDetail from "./PolicyDetail";
import AddNewPolicyModal from "./AddNewPolicyModal";
import AddContactModal from "./AddContactsModal";
import ContactDetails from "./ContactDetails";
import AddLocationModal from "./AddLocation";
import LocationDetailsModal from "./LocationDetails";
import SecondaryModal from "./SecondayModal";
import DocumentsUpload from "./DocumentsUpload";

import {
  LOCATION_COLUMNS,
  CONTACT_COLUMNS,
  POLICY_COLUMNS,
  DOCUMENT_COLUMNS,
  BROKER_COLUMNS,
  DOCUMENTS_API_URL,
  REACT_APP_GATEWAY_API_URL,
  clearSession,
  
} from "../../constants";
import { countryIdToCodeMap } from "../../constants/Countrycodes";

import {
  AddContacts,
  ContactType,
  ContactsPrefixes,
  ContactsPronoun,
  ContactsSuffix,
  GetContact,
  GetContactDetails,
  deleteContactData,
  setContactListData,
  setaddedContactData,
  setdeletedContactData,
  setupdatedContactData,
  updateContactData,
} from "../../store/actions/ContactsAction";

import {
  deleteDocument,
  deleteLocation,
  deletePolicy,
  getClientDetails,
  getClientLocationDetails,
  getPolicyDetails,
  getPolicyDetailsByid,
  getclientLocations,
  getclientMember,
  getClientPolicies,
  setPolicyData,
  setaddlocation,
  setdeleteLocation,
  setupdatedClientData,
  setDeletedDocumentData,
  setDeletedPolicyData,
  setUpdatedPolicyData,
  setupdateLocation,
  setClientDetails,
  getClientList,
} from "../../store/actions/ClientAction";

import {
  getDocuemntsData,
  getDocumentsTypes,
  uploadDocumentData,
} from "../../store/actions/DocumentsAction";

const ClientDetails = ({
  clientDetails,
  // locationRef,
  handleClose,
  // contactsList,
  showref,
  handlesetShowref,
  contactId,

  handleContactId,
}) => {
  const [show, setShow] = useState(false);
  const [policyDetails, setPolicyDetail] = useState([]);
  const [showContactModal, setShowAddContact] = useState(false);
  const [showPolicyDetailsPage, setShowPolicyDetailsPage] = useState(false);
  const [showcontactDetailsModal, setShowContactDetailsModal] = useState(false);
  const [sidePannel2Width, setSidePanel2Width] = useState(0);
  const [contactsData, setContactsData] = useState([]);
  const [contactDetails, setContactDetails] = useState(null);
  const [clientDetail, setClientDetail] = useState(null);
  const [clientLocations, setClientLocations] = useState(null);
  const [clientPolicies, setClientPolicies] = useState([]);
  const [clientDocuments, setClientDocuments] = useState([]);
  const [clientMembers, setClientMembers] = useState(null);
  const [selectedFiles, setSelectedFiles] = useState("");
  const [showDocumentUpload, setShowDocumentUpload] = useState(false);
  const [progressbarpercentage, setProgressPercentage] = useState(0);
  const [showprogress, setShowProgress] = useState(false);
  const [base64, setBase64] = useState("");
  const [fileType, setFileType] = useState("");
  const [documentTypes, setDocumentTypes] = useState("");
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [selecteddocumentType, setSelectedDocumentType] = useState("");
  const [documentsData, setDocumentsData] = useState("");
  const [contactsList, setContactsList] = useState(null);
  const [showAddlocationModal, setShowAddLocationModal] = useState(false);
  const [showLocationdetailsModal, setshowlocationdetailsModal] =
    useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showConfirmationPolicy, setShowPolicyConfirmation] = useState(false);
  const [showLocationConfirmation, setShowLocationConfirmation] =
    useState(false);
  const [showContactConfirmation, setShowContactConfirmation] = useState(false);
  const [contactid, setContactid] = useState("");
  const [locationid, setLocationid] = useState("");
  const [documentid, setDocumentid] = useState("");
  const [policyid, setPolicyid] = useState("");
  const [category, setCategory] = useState("");
  const [showEditClient, setShowEditClient] = useState(false);

  const [locationdetails, setlocationdetails] = useState(null);
  const [showEditContactModal, setShowEditContactModal] = useState(false);
  const [sortDocumentsASC, setSortDocumentsASC] = useState(false);
  const [sortdocumentsName, setSortDocumentsName] = useState("");
  const [sortPolicesName, setSortPoliciesName] = useState("");
  const [sortPoliciesASC, setSortPoliciesASC] = useState(false);
  const [clientPrimaryContact, setClientPrimaryContact] = useState("");
  const [showEditPolicy, setShowEditPolicyModal] = useState(false);
  const [renewPolicy, setRenewPolicy] = useState(false);
  const [brokerforpolicy, setBrokerForPolicy] = useState([]);

  const [page, setpage] = useState("");
  const [filename, setfilename] = useState("");

  const [sidePanelWidth, setSidePanelWidth] = useState(0);

  //extract specific pieces of state from the Redux store
  const stateData = useSelector((state) => {
    return {
      addedcontactData: state?.contact?.addedcontactData,
      contactDetailsData: state?.contact?.contactDetailsData,
      contactDeletedData: state?.contact?.contactDeletedData,
      addpolicyData: state?.fetchData?.addpolicyData,
      clientDetailsData: state?.fetchData?.clientDetailsData,
      contactsListData: state?.contact?.contactListData,
      documentTypes: state?.documents?.documentsTypes,
      uploaddocumentdata: state?.documents?.uploadDocument,
      documentsData: state?.documents?.documentsData,
      locationdetailsData: state?.fetchData?.clientLocationdetailsData,
      addlocation: state?.fetchData?.addlocationData,
      deletelocationData: state?.fetchData?.deletelocationData,
      policyDetailsData: state?.fetchData?.policyDetailsData,
      clientLocationsData: state?.fetchData?.clientLocationsData,
      clientPoliciesData: state?.fetchData?.clientPoliciesData,
      clientMemberData: state?.fetchData?.clientMemberData,
      updatedcontactData: state?.contact?.updatedcontactData,

      updatedClientData: state?.fetchData?.updatedClientData,

      deletedDocument: state?.fetchData?.deletedDocument,
      deletedPolicyData: state?.fetchData?.deletedPolicyData,

      updatedPolicyData: state?.fetchData?.updatedPolicyData,

      updatelocationData: state?.fetchData?.updatelocationData,
      brokerforpolicyData: state?.fetchData?.brokerforpolicyData,
    };
  });

  const Document_API_URL =
    `${REACT_APP_GATEWAY_API_URL}/Document/Graphql`;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getDocumentsTypes());
    dispatch(ContactsPrefixes());
    dispatch(ContactsSuffix());
    dispatch(ContactsPronoun());
    dispatch(ContactType());
  }, []);

  useEffect(() => {
    if (!stateData.documentTypes) {
      dispatch(getDocumentsTypes());
    }
  }, []);

  useEffect(() => {
    return () => {
      dispatch(setClientDetails(""));
      dispatch(setContactListData(""));
    };
  }, []);

  //update client details
  useEffect(() => {
    if (stateData.clientDetailsData?.data) {
      setClientDetail(stateData.clientDetailsData?.data?.clientsDetails?.[0]);
    }
  }, [stateData.clientDetailsData?.data]);

  let isFirstref = useRef(true);
  useEffect(() => {
    if (isFirstref.current) {
      isFirstref.current = false;
      return;
    }
    if (clientDetail?.id) {
      // setClientDetail(clientDetails);
      
      fetchClientLocation(clientDetail?.id);
      let payload = {
        isSort: false,
        sortName: "",
        sortASC: true,
      };

      fetchClientPolicies(clientDetail?.id, payload);
      fetchClientMembers(clientDetail?.id);
      fetchClientDocument({ isSort: false });
      
      let ClientProducingBroker =  clientDetail?.producingBrokerId
      dispatch(GetContact(clientDetail?.id , ClientProducingBroker));

    }
  }, [clientDetail]);

  //update location details
  useEffect(() => {
    if (stateData.locationdetailsData) {
      setlocationdetails(
        stateData.locationdetailsData?.data?.locationDetails
      );
    }
  }, [stateData.locationdetailsData]);

  //update policy details

  useEffect(() => {
    if (stateData.policyDetailsData) {
      setPolicyDetail(stateData.policyDetailsData?.data?.policyDetails);
    }
  }, [stateData.policyDetailsData]);

  //update location details
  useEffect(() => {
    if (stateData.clientLocationsData?.data?.locationsForClient?.nodes) {
      setClientLocations(
        stateData.clientLocationsData?.data?.locationsForClient?.nodes
      );
    }
  }, [stateData.clientLocationsData?.data?.locationsForClient?.nodes]);

  //update policy list
  useEffect(() => {
    if (stateData.clientPoliciesData?.data?.policiesForClient) {
      setClientPolicies(
        stateData.clientPoliciesData?.data?.policiesForClient?.nodes
      );
    }
  }, [stateData.clientPoliciesData?.data?.policiesForClient]);

  //update client member
  useEffect(() => {
    if (stateData.clientMemberData?.data?.brokersForClient?.nodes) {
      setClientMembers(
        stateData.clientMemberData?.data?.brokersForClient?.nodes
      );
    }
  }, [stateData.clientMemberData?.data?.brokersForClient?.nodes]);

  useEffect(() => {
    if (stateData.uploaddocumentdata === "success-Client") {
      setShowDocumentUpload(false);
      fetchClientDocument({ isSort: false });
      dispatch(uploadDocumentData(""));
    }
  }, [stateData.uploaddocumentdata]);

  //update documents
  useEffect(() => {
    if (stateData.documentsData) {
      setDocumentsData(stateData.documentsData);
    }
  }, [stateData.documentsData]);

  //handle to navigate location section
  let locationRef = useRef(null);
  useEffect(() => {
    if (showref === true && clientDetail && clientPolicies && clientMembers) {
      locationRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
      handlesetShowref();
    }
  }, [showref, clientDetail, clientPolicies, clientMembers]);

  //get contact list if any changes in addedcontactData || contactDeletedData
  useEffect(() => {
    if (
      stateData?.addedcontactData ||
      stateData?.contactDeletedData ||
      stateData.updatedcontactData
    ) {
      if (
        stateData?.addedcontactData === "success" ||
        stateData?.contactDeletedData === "success" ||
        stateData?.updatedcontactData === "success"
      ) {
        // dispatch(GetContact(clientDetail?.id));
        let ClientProducingBroker =  clientDetail?.producingBrokerId
        dispatch(GetContact(clientDetail?.id , ClientProducingBroker));

        closeSidePanel();
        closeSidePanel2();

        setShowAddContact(false);
        setShowContactDetailsModal(false);
        dispatch(setdeletedContactData(""));
        dispatch(setaddedContactData(""));
        setShowEditContactModal(false);
        dispatch(setupdatedContactData(""));
      }
    }
  }, [
    stateData.addedcontactData,
    stateData.contactDeletedData,
    stateData.updatedcontactData,
  ]);

  // update contact details
  useEffect(() => {
    if (stateData?.contactDetailsData?.data?.contactDetails?.nodes) {
      setContactDetails(
        stateData?.contactDetailsData?.data?.contactDetails?.nodes[0]
      );
    }
  }, [stateData?.contactDetailsData?.data?.contactDetails?.nodes]);

  //get client details if any chnages in addpolicyData || addlocation || deletelocationData
  useEffect(() => {
    if (
      stateData.addpolicyData ||
      stateData.addlocation ||
      stateData.deletelocationData ||
      stateData.updatedPolicyData
    ) {
      if(stateData.updatedPolicyData) {

        let payload = {
          isSort: false,
          sortName: "",
          sortASC: true,
        };
  
        fetchClientPolicies(clientDetail?.id, payload)
        
      }
      // handleCloseNewPolicyModal()
      dispatch(setaddlocation(""));
      dispatch(setdeleteLocation(""));
      dispatch(setPolicyData(""));
      dispatch(setUpdatedPolicyData(""));
      setShow(false);
    }
  }, [
    stateData.addpolicyData,
    stateData.addlocation,
    stateData.deletelocationData,
    stateData.updatedPolicyData,
  ]);

  useEffect(() => {
    if (stateData?.updatedClientData === "success") {
      setShowEditClient(false);

      fetchClientLocation(clientDetail?.id);
      fetchClientMembers(clientDetail?.id);

      let payload = {
        isSort: false,
        sortBy: "ASC",
        sortName: "",
        isArchived: false,
        filterName: "All",
      };

      dispatch(getClientList(payload))

      dispatch(setupdatedClientData(""));

      dispatch(getClientDetails(clientDetail?.id));
    }
  }, [stateData?.updatedClientData]);

  useEffect(() => {
    if (stateData.contactsListData) {
      let contactsList = stateData?.contactsListData?.data?.clientContacts?.nodes?.map((item , key)=> {
        return {
          ...item,
          id:item.id,
          country:item?.country?.country
        }
      })

      let primaryContact = stateData?.contactsListData?.data?.clientContacts?.nodes?.find((item , key)=> item?.
      clientPrimaryContact === true)
      
      setClientPrimaryContact(primaryContact)
      
      setContactsList(contactsList);
    }
    if (stateData.documentTypes) {
      setDocumentTypes(stateData.documentTypes);
    }
  }, [stateData.contactsListData, stateData.documentTypes]);

  useEffect(() => {
    if (contactsList?.length > 0 && contactId) {
      showContactDetails(contactId);

      handleContactId();
    }
  }, [contactsList]);

  

  useEffect(() => {
    if (stateData.documentsData) {
      setDocumentsData(stateData.documentsData);
    }
  }, [stateData.documentsData]);

  useEffect(() => {
    if (stateData.deletedDocument === "success-Client") {
      fetchClientDocument({ isSort: false });
      setShowConfirmation(false);
      dispatch(setDeletedDocumentData(""));
    }
    if (stateData.deletedPolicyData === "success") {
      setShowPolicyConfirmation(false);
      dispatch(setDeletedPolicyData(""));
    }
  }, [stateData.deletedDocument, stateData.deletedPolicyData]);

  useEffect(() => {
    if (stateData?.contactDetailsData?.data?.contactDetails?.nodes) {
      let contactdetails = stateData?.contactDetailsData?.data?.contactDetails?.nodes?.map((item , key)=> {
        return {
          ...item,
          country:item?.country?.country,
        }
      })
      
      setContactDetails(
        contactdetails?.[0]
      );
    }
  }, [stateData?.contactDetailsData?.data?.contactDetails?.nodes]);

  useEffect(() => {
    if (
      stateData.addpolicyData ||
      stateData.addlocation ||
      stateData.deletelocationData ||
      stateData?.updatelocationData
    ) {
      // handleCloseNewPolicyModal()
      fetchClientDetails(clientDetail?.id);
      dispatch(setaddlocation(""));
      dispatch(setdeleteLocation(""));
      dispatch(setPolicyData(""));
      dispatch(setupdateLocation(""));
      setShowAddLocationModal(false);
    }
  }, [
    stateData.addpolicyData,
    stateData.addlocation,
    stateData.deletelocationData,
    stateData?.updatelocationData,
  ]);

  useEffect(() => {
    if (stateData.deletedDocument === "success-Client") {
      fetchClientDocument({ isSort: false });
      setShowConfirmation(false);
      dispatch(setDeletedDocumentData(""));
    }
    if (stateData.deletedPolicyData === "success") {
      fetchClientDetails(clientDetail?.id);
      setShowPolicyConfirmation(false);
      dispatch(setDeletedPolicyData(""));
    }
    if (stateData?.deletelocationData === "success") {
      fetchClientDetails(clientDetail?.id);
      setShowLocationConfirmation(false);
    }
    if (stateData?.contactDeletedData === "success") {
      // dispatch(GetContact(clientDetail?.id));
      let ClientProducingBroker =  clientDetail?.producingBrokerId
      dispatch(GetContact(clientDetail?.id , ClientProducingBroker));
      setShowContactConfirmation(false);
    }
  }, [
    stateData.deletedDocument,
    stateData.deletedPolicyData,
    stateData?.deletelocationData,
    stateData?.contactDeletedData,
  ]);

  //get sorted policies
  useEffect(() => {
    if (sortPolicesName) {
      let payload = {
        isSort: true,
        sortName: sortPolicesName,
        sortASC: sortPoliciesASC,
      };
      fetchClientPolicies(clientDetail?.id, payload);
    }
  }, [sortPoliciesASC, sortPolicesName]);

  //get sorted documents
  useEffect(() => {
    if (sortdocumentsName) {
      fetchClientDocument({ isSort: true });
    }
  }, [sortDocumentsASC, sortdocumentsName]);

  //handle to get client documents
  const fetchClientDocument = (params) => {
    let isSort =
      params?.isSort === true
        ? `order: { ${sortdocumentsName}: ${
            sortDocumentsASC === true ? "ASC" : "DESC"
          } }`
        : "";
    const fetchData = async () => {
      try {
        const response = await fetch(Document_API_URL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            ClientId: clientDetail?.id,
            "Authorization": `Bearer ` + localStorage.getItem("accessToken")
          },
          body: JSON.stringify({
            query: `
                    query DocumentsByClient() {
                      documentsByClient(${isSort}) {
                        nodes {
                          documentId
                          documentTypeId
                          documentTypeName
                          documentName
                          documentDisplayName
                          description
                          fileFormat
                          isArchived
                          visibility
                          uploadDate
                        }
                      }
                    }
                  `,
            variables: { id: clientDetail?.id },
          }),
        });

        const result = await response.json();

        if (response.ok) {
          let data = result?.data?.documentsByClient?.nodes?.map(
            (item, key) => {
              return {
                ...item,
                id: item.documentId,
              };
            }
          );
          setClientDocuments(data);
        } else {
          if(response.status === 403) {
            clearSession()
          }
          // throw new Error("Network response was not ok");
        }
      } catch (error) {
        console.error("Error fetching data:", error.message);
      }
    };

    fetchData();
  };

  //get client locations
  const fetchClientLocation = (id) => {
    let ClientProducingBroker =
      
      clientDetail?.producingBrokerId;

    dispatch(getclientLocations(id, ClientProducingBroker));
  };

  //get client policies
  const fetchClientPolicies = (id, payload) => {
    const fetchData = async () => {
      let ClientProducingBroker =
        
        clientDetail?.producingBrokerId;
      dispatch(getClientPolicies(id, ClientProducingBroker, false, payload));
    };
    fetchData();
  };

  const handleremovefile = (item, index) => {
    setSelectedFiles([]);
    setBase64("");
  };

  //get client members
  const fetchClientMembers = (id) => {
    dispatch(getclientMember(id));
  };

  const handleDocumentRow = (id) => {};

  const handleopenAddPolicy = () => {
    // handleClose()
    setShow(true);
  };

  const handleCloseNewPolicyModal = () => {
    setShow(false);
  };

  const closeSidePanel = () => {
    setShowAddContact(false);
    setSidePanelWidth(0);
  };

  const closeSidePanel2 = () => {
    setShowContactDetailsModal(false);
    setSidePanel2Width(0);
  };

  const addContact = (payload) => {
    dispatch(AddContacts(payload));
  };

  //edit contact
  const editContact = (payload) => {
    dispatch(updateContactData(payload));
  };

  //handle to get contact details and show contact details modal
  const showContactDetails = (id) => {
    setContactDetails("");
    dispatch(GetContactDetails(id));
    setShowContactDetailsModal(true);
  };

  const fetchClientDetails = async (id) => {
    dispatch(getClientDetails(id));
    handleCloseNewPolicyModal();
    // dispatch(setPolicyData(""));
  };

  const handleRow = (id) => {
    setPolicyDetail([]);
    showPolicyDetails(id);
  };

  const handlepolicyrow = (id) => {
    showPolicyDetails(id);
  };

  //handle to get location details
  const handleLocationRow = (id) => {
    //showPolicyDetails(id);

    setshowlocationdetailsModal(true);
    dispatch(getClientLocationDetails(id));
  };

  //handle to get contact details
  const handleContactRow = (id) => {
    
    showContactDetails(id);
  };

  const handleBrokerRow = (id) => {
    //showPolicyDetails(id);
  };
  const handleCloseDocumentUpload = () => {
    setShowDocumentUpload(false);
  };

  //generate base64
  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      let fileInfo;
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();

      reader.onprogress = (event) => {
        // if (event.lengthComputable) {
        const progress = (event.loaded / event.total) * 100;
        setProgressPercentage(parseInt(progress));
      };

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        

        baseURL = reader.result;
        
        setBase64(baseURL.split("base64,")[1]);
        // setShowProgress(false)
        // setTimeout(() => setShowProgress(false) , 1000)
        setTimeout(() => setShowProgress(false), 1000);
        resolve(baseURL);
      };
      reader.onerror = (error) => {
        setShowProgress(false);
        reject(error);
      };
    });
  };

  //upload document
  const fileupload = (event) => {
    const files = event;
    let file = event[0];
    setSelectedFiles(files);

    let popfiletype = files?.[0]?.path?.split(".")?.pop();
    let filetype = popfiletype ? popfiletype.toLowerCase() : popfiletype;

    setFileType(`${filetype}`);
    setShowProgress(true);
    setfilename(files?.[0]?.name);
    getBase64(file);
  };

  //handle to change document type
  const handleChangedocumentType = (event) => {
    setSelectedDocumentType(event);
  };

  //handle to cancel upload
  const handleCancelUpload = () => {
    setConfirmationOpen(false);
  };

  //handle to show confirmation modal before uploading document
  const handleConfirmUpload = () => {
    setConfirmationOpen(false);
    setShowDocumentUpload(true);
  };

  const handleEditClient = () => {
    setShowEditClient(true);
  };

  const handleEditPolicy = (type) => {

    if(type === "renewPolicy") {
      setRenewPolicy(true)
    }
    else {
      setRenewPolicy(false)
    }
    setShowEditPolicyModal(true);
    // dispatch(getPolicyDetailsByid(clientDetail?.id));
  };

  //get policy details
  const showPolicyDetails = (id) => {
    setShowPolicyDetailsPage(true);
    dispatch(getPolicyDetails(id));
  };

  const handleConfirm = () => {
    let user = JSON.parse(localStorage.getItem("user"));
    let payload = {
      ClientId: clientDetail?.id,

      UserId: user?.sub,
      ProducingFirmId: user?.extension_MemberFirmId,
    };
    let documentsPayload = {
      documentIds: [documentid],
      category: category,
      categoryId: category === "Client"? clientDetail?.id:policyDetails?.id
    }
    dispatch(deleteDocument(documentsPayload, category, payload));
  };

  //delete policy
  const handlePolicyConfirm = () => {
    let user = JSON.parse(localStorage.getItem("user"));
    let ClientProducingBroker =  clientDetail?.producingBrokerId
    let payload = {
      Id: policyid,
      ActionPerformedBy: user?.sub ? user?.sub : "",
      ClientProducingBroker: ClientProducingBroker,
    };
    dispatch(deletePolicy(payload));
  };

  //delete location
  const handleLocationConfirm = () => {
    let user = JSON.parse(localStorage.getItem("user"));
    let payload = {
      id: locationid,
      actionPerformedBy: user?.sub ? user?.sub : "",
      ClientProducingBroker: clientDetail?.producingBrokerId
    };

    dispatch(deleteLocation(payload));
  };

  //delete contact
  const handleContactConfirm = () => {
    let ClientProducingBroker= clientDetail?.producingBrokerId
    dispatch(deleteContactData(contactid , ClientProducingBroker));
  };

  //delete document
  const handleDeleteDocument = (e, category) => {
    setDocumentid(e);
    setCategory(category);
    setShowConfirmation(true);
  };

  //handle to show delete policy confirmation
  const handleDeletePolicy = (id) => {
    setPolicyid(id);
    setShowPolicyConfirmation(true);
  };

  //handle to show delete location confirmation
  const handleDeleteLocation = (id) => {
    setLocationid(id);
    setShowLocationConfirmation(true);
  };

  //sort documents
  const handleSortClientDocuments = (e) => {
    if (e === sortdocumentsName) {
      setSortDocumentsASC(!sortDocumentsASC);
    } else {
      setSortDocumentsASC(true);
    }
    setSortDocumentsName(e);
  };

  //sort policies
  const handleSortPolicies = (e) => {
    setSortPoliciesName(e);
    if (e === sortPolicesName) {
      setSortPoliciesASC(!sortPoliciesASC);
    } else {
      setSortPoliciesASC(true);
    }
  };

  //handle to show delete contact confirmation
  const handleDeleteContact = (id) => {
    setContactid(id);
    setShowContactConfirmation(true);
  };

  return (
    <Box sx={{ padding: "0px" }}>
      

      {showConfirmation && (
        <ConfirmationModal
          showConfirmation={showConfirmation}
          handleCloseConfirmation={() => setShowConfirmation(false)}
          handleConfirm={handleConfirm}
          title={"Document"}
          subTitle={"delete this document"}
          buttonname="Delete"
        />
      )}
      {showConfirmationPolicy && (
        <ConfirmationModal
          showConfirmation={showConfirmationPolicy}
          handleCloseConfirmation={() => setShowPolicyConfirmation(false)}
          handleConfirm={handlePolicyConfirm}
          title={"Policy"}
          subTitle={"delete this policy"}
          buttonname="Delete"
        />
      )}
      {showLocationConfirmation && (
        <ConfirmationModal
          showConfirmation={showLocationConfirmation}
          handleCloseConfirmation={() => setShowLocationConfirmation(false)}
          handleConfirm={handleLocationConfirm}
          title={"Location"}
          subTitle={"delete this location"}
          buttonname="Delete"
        />
      )}
      {showContactConfirmation && (
        <ConfirmationModal
          showConfirmation={showContactConfirmation}
          handleCloseConfirmation={() => setShowContactConfirmation(false)}
          handleConfirm={handleContactConfirm}
          title={"Contact"}
          subTitle={"delete this contact"}
          buttonname="Delete"
        />
      )}

      <Box
        className=""
        sx={{
          display: "flex",
          justifyContent: "space-between",
          padding: "12px 40px",
        }}
      >
        <Typography variant="span">
          <CloseButton onClick={() => handleClose()} />

          {(JSON.parse(localStorage.getItem("user"))?.extension_Role ===
            "HqAdmin" ||
            JSON.parse(localStorage.getItem("user"))?.extension_MemberFirmId ===
              clientDetail?.producingBrokerId) && (
            <Button
              variant={"contained"}
              color={"grey"}
              onClick={() => handleEditClient()}
              sx={{ marginLeft: "10px" }}
            >
              Edit
            </Button>
          )}
        </Typography>
        <h5 className="offcanvas-title" id="offcanvasRightLabel">
          Last edited {clientDetail?.modifiedOn}
        </h5>
      </Box>

      {showEditClient && (
        <Dialog
          maxWidth="lg"
          maxHeight="150px"
          open={showEditClient}
          onClose={() => setShowEditClient(false)}
          showCloseIcon="false"
          body={
            <SecondaryModal
              showSecondaryModal={showEditClient}
              handleCloseSecondaryModal={() => setShowEditClient(false)}
              clientName={clientDetail?.clientName}
              page="edit"
              contactsList={contactsList}
              clientDetail={clientDetail}
              clientLocations={clientLocations}
              clientMembers={clientMembers}
            />
          }
        />
      )}
      
      <div class="border"></div>
      {show && (
        <Box>
          <main id="main" className="main">
            <Box className="container-fluid heading ps-0 pe-0">
              {show && (
                <Dialog
                  maxWidth="lg"
                  maxHeight="150px"
                  open={show}
                  onClose={handleCloseNewPolicyModal}
                  showCloseIcon="false"
                  body={
                    <AddNewPolicyModal
                      showNewPolicyModal={show}
                      handleCloseNewPolicyModal={handleCloseNewPolicyModal}
                      clientDetail={clientDetail}
                      from="clientDetails"
                      clientid={clientDetail?.id}
                      page="add"
                      clientMembers={clientMembers}
                    />
                  }
                />
              )}
            </Box>
          </main>
        </Box>
      )}

      {showEditPolicy && (
        <Dialog
          maxWidth="lg"
          maxHeight="150px"
          open={showEditPolicy}
          onClose={() => setShowEditPolicyModal(false)}
          showCloseIcon="false"
          body={
            <AddNewPolicyModal
              showNewPolicyModal={showEditPolicy}
              handleCloseNewPolicyModal={() => setShowEditPolicyModal(false)}
              clientDetail={clientDetail}
              from="clientDetails"
              clientid={clientDetail?.id}
              policyDetails={policyDetails}
              page="edit"
              clientMembers={clientMembers}

              renewPolicy={renewPolicy}

            />
          }
        />
      )}

      <Box sx={{ padding: "12px 40px 100px 40px" }}>
        <Grid xs="12" md="12" lg="12">
          <Box className="offcanvas-body-title">
            {" "}
            <span></span> {clientDetail?.clientName}{" "}
          </Box>
        </Grid>

        <GridContainer>
          <Grid xs="4" md="2" lg="2">
            <Box className="head-row">Status</Box>
            <Box className="body-row active-text">{clientDetail?.status}</Box>
          </Grid>
          <Grid xs="4" md="3" lg="3">
            <Box className="head-row">Country</Box>
            <ReactCountryFlag
              countryCode={countryIdToCodeMap[clientDetails?.countryId]}
              svg
              style={{ width: "30px", height: "19px" }}
            />{" "}
            {clientDetail?.country}
          </Grid>
          <Grid xs="4" md="2" lg="2">
            <Box className="head-row">Contact name</Box>
            <Box className="body-row">{clientPrimaryContact?.firstName}&nbsp;</Box>
          </Grid>
          <Grid xs="4" md="2" lg="2">
            <Box className="head-row">Client phone</Box>
            <Box className="body-row">
              {clientPrimaryContact?.mobilePhone}
              <img src="wwwroot/assets/images/copy.png" className="img-fluid" />
            </Box>
          </Grid>
          <Grid xs="4" md="3" lg="3">
            <Box className="head-row">Client email</Box>
            <Box className="body-row">
              {clientPrimaryContact?.email}
              <img src="wwwroot/assets/images/copy.png" className="img-fluid" />
            </Box>
          </Grid>
        </GridContainer>
        <hr />
        <GridContainer>
          <Grid xs="4" md="2" lg="2">
            <Box className="head-row">Total cost</Box>
            <Box className="body-row">${clientDetail?.totalCost}</Box>
          </Grid>
          <Grid xs="4" md="3" lg="3">
            <Box className="head-row">Total commission</Box>
            <Box className="body-row">${clientDetail?.totalCommission}</Box>
          </Grid>
          <Grid xs="4" md="2" lg="2">
            <Box className="head-row">Total headcount</Box>
            <Box className="body-row">{clientDetail?.totalHeadCount}</Box>
          </Grid>
          <Grid xs="4" md="2" lg="2"></Grid>
          <Grid xs="4" md="3" lg="3"></Grid>
        </GridContainer>
        <hr />
        

        <GridContainer className="" style={{ marginBottom: "40px" }}>
          <Grid xs="12" md="6" lg="6">
            <Box className="document-name">Policies</Box>
          </Grid>
          {clientDetail?.isDraft === true ? "" :
          <Grid xs="12" md="6" lg="6" className=" text-end mb-2">
            <Button
              type="button"
              className="btn btn-default add-name"
              variant={"outlined"}
              color={"white"}
              onClick={() => handleopenAddPolicy()}
            >
              Add policy
            </Button>
          </Grid>
}

          <Grid xs="12" md="12" lg="12" className=" documents-table">
            <Box className="table-responsive">
              {clientPolicies?.length > 0 ? (
                <Table
                  columns={POLICY_COLUMNS}
                  rows={clientPolicies}
                  handleRow={handleRow}
                  from="clientPolicy"
                  handleDelete={(e) => handleDeletePolicy(e)}
                  handleSort={(e) => handleSortPolicies(e)}
                  isDraft={clientDetail?.isDraft}
                />
              ) : (
                <Grid xs="12" md="12" lg="12" className=" mb-4">
                  <RenderTextInput
                    type="text"
                    className="form-control"
                    placeholder="No Policies added"
                    disabled
                    style={{ backgroundColor: "#FFF" }}
                  />
                </Grid>
              )}
              {/* Policy Details */}
              {showPolicyDetailsPage && (
                <DrawerComponent
                  isOpen={showPolicyDetailsPage}
                  handleClose={() => setShowPolicyDetailsPage(false)}
                  // width={900}
                  width={"75vw"}
                  body={
                    <PolicyDetail
                      policyDetails={policyDetails}
                      handleClose={() => setShowPolicyDetailsPage(false)}
                      fileupload={fileupload}
                      showprogress={showprogress}
                      progressbarpercentage={progressbarpercentage}
                      selectedFiles={selectedFiles}
                      documentTypes={
                        documentTypes?.length > 0 ? documentTypes : []
                      }
                      handleChangedocumentType={handleChangedocumentType}
                      selecteddocumentType={selecteddocumentType}
                      base64={base64}
                      fileType={fileType}
                      handleremovefile={handleremovefile}
                      clientDetail={clientDetail}
                      filename={filename}
                      handlefilename={(e) => setfilename(e)}
                      handleEdit={(e) => handleEditPolicy(e)}
                      handleCloseEditPolicy={() =>
                        setShowEditPolicyModal(false)
                      }
                      handleDeleteDocument={(e) =>
                        handleDeleteDocument(e, "Policy")
                      }
                      showConfirmation={showConfirmation}
                      handleCloseConfirmation={() => setShowConfirmation(false)}
                      handleConfirm={handleConfirm}
                    />
                  }
                />
              )}
            </Box>
          </Grid>
        </GridContainer>

        <Box ref={locationRef}></Box>
        <GridContainer className="table-main-row scroll-to-location mt-3">
          <Grid xs="12" md="6" lg="6">
            <Box className="document-name locationRef">Locations</Box>
          </Grid>
          {clientDetail?.isDraft === true ? "" :
          <Grid xs="12" md="6" lg="6" className=" text-end mb-2">
            <Button
              type="button"
              className="btn btn-default add-name"
              variant={"outlined"}
              color={"white"}
              onClick={() => {
                setShowAddLocationModal(true);
                setpage("add");
              }}
            >
              Add location
            </Button>
          </Grid>
}

          {/* Add and edit location */}

          {showAddlocationModal && (
            <DrawerComponent
              isOpen={showAddlocationModal}
              handleClose={() => setShowAddLocationModal(false)}
              width={350}
              body={
                <AddLocationModal
                  showContactModal={showAddlocationModal}
                  clientDetail={clientDetail}
                  handleClose={() => setShowAddLocationModal(false)}
                  // page="add"
                  locationdetails={locationdetails}
                  page={page}
                  title={
                    page === "add" ? "Create new location" : "Update location"
                  }
                />
              }
            />
          )}
          {showLocationdetailsModal && (
            <DrawerComponent
              isOpen={showLocationdetailsModal}
              handleClose={() => setshowlocationdetailsModal(false)}
              width={350}
              body={
                <LocationDetailsModal
                  showlocationModal={showLocationdetailsModal}
                  locationdetails={locationdetails}
                  handleClose={() => setshowlocationdetailsModal(false)}
                  // page="edit"
                  handleedit={() => {
                    setshowlocationdetailsModal(false);
                    setShowAddLocationModal(true);
                    setpage("edit");
                  }}
                  clientDetail={clientDetail}
                />
              }
            />
          )}
          <Grid xs="12" md="12" lg="12" className=" documents-table">
            <Box className="table-responsive">
              {clientLocations?.length > 0 ? (
                <Table
                  columns={LOCATION_COLUMNS}
                  rows={clientLocations}
                  handleRow={handleLocationRow}
                  from="clientLocation"
                  handleDelete={(e) => handleDeleteLocation(e)}
                  isDraft={clientDetail?.isDraft}
                />
              ) : (
                <Grid xs="12" md="12" lg="12" className=" mb-4">
                  <RenderTextInput
                    type="text"
                    className="form-control"
                    placeholder="No Locations added"
                    disabled
                    style={{ backgroundColor: "#FFF" }}
                  />
                </Grid>
              )}
            </Box>
          </Grid>
        </GridContainer>

        <GridContainer className="table-main-row mt-3">
          <Grid xs="12" md="6" lg="6">
            <Box className="document-name">Client contacts</Box>
          </Grid>
          {clientDetail?.isDraft === true ? "" :
          <Grid xs="12" md="6" lg="6" className=" text-end mb-2">
            <Button
              type="button"
              className="btn btn-default add-name btn openbtn"
              variant={"outlined"}
              color={"white"}
              onClick={() => setShowAddContact(true)}
            >
              Add contact
            </Button>
          </Grid>
}

          {showContactModal && (
            <DrawerComponent
              isOpen={showContactModal}
              handleClose={() => setShowAddContact(false)}
              width={350}
              body={
                <AddContactModal
                  showContactModal={showContactModal}
                  closeSidePanel={() => setShowAddContact(false)}
                  panelWidth={sidePanelWidth}
                  addContact={addContact}
                  clientDetail={clientDetail}
                  page="add"
                  title="Create new contact"
                />
              }
            />
          )}

          {showcontactDetailsModal && (
            <DrawerComponent
              isOpen={showcontactDetailsModal}
              handleClose={() => setShowContactDetailsModal(false)}
              width={350}
              body={
                <ContactDetails
                  showcontactDetails={showcontactDetailsModal}
                  closeSidePanel={() => setShowContactDetailsModal(false)}
                  panelWidth={sidePannel2Width}
                  contactDetails={contactDetails}
                  handleEdit={() => {
                    setShowEditContactModal(true);
                    setShowContactDetailsModal(false);
                  }}
                  clientDetail={clientDetail}
                />
              }
            />
          )}
          {showEditContactModal && (
            <DrawerComponent
              isOpen={showEditContactModal}
              handleClose={() => setShowEditContactModal(false)}
              width={350}
              body={
                <AddContactModal
                  showContactModal={showEditContactModal}
                  closeSidePanel={() => setShowEditContactModal(false)}
                  panelWidth={sidePanelWidth}
                  // editContact={editContact}
                  clientDetail={clientDetail}
                  editContact={editContact}
                  contactDetails={contactDetails}
                  page="edit"
                  title="Update Contact"
                />
              }
            />
          )}

          <Grid xs="12" md="12" lg="12" className=" documents-table">
            <Box className="table-responsive">
              {contactsList?.length > 0 ? (
                <Table
                  columns={CONTACT_COLUMNS}
                  rows={contactsList}
                  handleRow={handleContactRow}
                  from="clientContact"
                  handleDelete={(e) => handleDeleteContact(e)}
                  isDraft={clientDetail?.isDraft}
                />
              ) : (
                <Grid xs="12" md="12" lg="12" className=" mb-4">
                  <RenderTextInput
                    type="text"
                    className="form-control"
                    placeholder="No Contacts added"
                    disabled
                    style={{ backgroundColor: "#FFF" }}
                  />
                </Grid>
              )}
            </Box>
          </Grid>
        </GridContainer>

        <GridContainer className="table-main-row mt-3">
          <Grid xs="12" md="6" lg="6">
            <Box className="document-name">Brokers</Box>
          </Grid>
          {/* <Grid xs="12" md="6" lg="6" className=" text-end mb-2">
            <Button
              type="button"
              className="btn btn-default add-name"
              variant={"outlined"}
              color={"white"}
            >
              Add broker
            </Button>
          </Grid> */}
          <Grid xs="12" md="12" lg="12" className=" broker-table">
            <Box className="table-responsive">
              {clientMembers?.length > 0 ? (
                <Table
                  columns={BROKER_COLUMNS}
                  rows={clientMembers}
                  handleRow={handleBrokerRow}
                  from="clientBroker"
                  isDraft={clientDetail?.isDraft}
                />
              ) : (
                <Grid xs="12" md="12" lg="12" className=" mb-4">
                  <RenderTextInput
                    type="text"
                    className="form-control"
                    placeholder="No Broker added"
                    disabled
                    style={{ backgroundColor: "#FFF" }}
                  />
                </Grid>
              )}
            </Box>
          </Grid>
        </GridContainer>

        <GridContainer style={{ marginBottom: "40px" }}>
          <Grid xs="12" md="6" lg="6">
            <Box className="document-name">Documents</Box>
          </Grid>
          {clientDetail?.isDraft === true ? "" :
          <Grid xs="12" md="6" lg="6" className=" text-end mb-2  mt-3">
            <Button
              type="button"
              className="btn btn-default add-name"
              variant={"outlined"}
              color={"white"}
              onClick={() => setConfirmationOpen(true)}
            >
              Add Document
            </Button>
            {confirmationOpen && (
              <PIIConfirmationModal
                setConfirmationOpen={setConfirmationOpen}
                confirmationOpen={confirmationOpen}
                handleCancelUpload={handleCancelUpload}
                handleConfirmUpload={handleConfirmUpload}
              />
            )}
            <Grid xs="12" md="12" lg="12" className=" documents-table"></Grid>
            {showDocumentUpload && (
              <Dialog
                maxWidth="xs"
                maxHeight="150px"
                open={showDocumentUpload}
                onClose={handleCloseDocumentUpload}
                showCloseIcon="true"
                body={
                  <DocumentsUpload
                    handleFileChange={fileupload}
                    // handleDragOver={handleDragOver}
                    // handleDrop={handleDrop}
                    showprogress={showprogress}
                    progressbarpercentage={progressbarpercentage}
                    selectedFiles={selectedFiles}
                    documentTypes={
                      documentTypes?.length > 0 ? documentTypes : []
                    }
                    handleDocumentType={handleChangedocumentType}
                    selecteddocumentType={selecteddocumentType}
                    base64={base64}
                    fileType={fileType}
                    handleremovefile={handleremovefile}
                    clientDetail={clientDetail}
                    from="Client"
                    categoryid={clientDetail?.id}
                    handlefilename={(e) => setfilename(e)}
                    filename={filename}
                    handleCloseDocumentUpload={handleCloseDocumentUpload}
                  />
                }
              />
            )}
          </Grid>
}

          <Grid xs="12" md="12" lg="12">
            <Box className="table-responsive">
              {clientDocuments?.length > 0 ? (
                <Table
                  columns={DOCUMENT_COLUMNS}
                  rows={clientDocuments}
                  handleRow={handleDocumentRow}
                  from="policyDocument"
                  handleDelete={(e) => handleDeleteDocument(e, "Client")}
                  handleSort={(e) => handleSortClientDocuments(e)}
                  isDraft={clientDetail?.isDraft}
                />
              ) : (
                <Grid xs="12" md="12" lg="12" className=" mb-4">
                  <RenderTextInput
                    type="text"
                    className="form-control"
                    placeholder="No Documents added"
                    disabled
                    style={{ backgroundColor: "#FFF" }}
                  />
                </Grid>
              )}
            </Box>
          </Grid>
        </GridContainer>
        
        <div className="row"></div>
      </Box>
    </Box>
  );
};

export default ClientDetails;
