import React from "react";

import GridContainer from "../../../components/GridContainer";
import Select from "react-select";
import { RenderTextInput } from "../../../components/TextField";
import Switch from "../../../components/Switch";
import {
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Typography,
  Menu,
  MenuItem,
} from "@mui/material";
import {  dropDownOptions } from "../../../constants";
import { Textarea, customStyles , usersCountryDropdown } from "../../../components/common/CustomStyle";
import CloseButton from "../../../components/CloseButton";
import Progressbar from "../../../components/ProgressBar";
import Dropzone from "../../../components/common/Dropzone";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

const Tab5 = ({
  tab,
  handleChangedocumentType,
  documentTypes,
  selecteddocumentType,
  errors,
  handleFile,
  validateFile,
  fileError,
  document,
  handleFilename,
  inputRef,
  selectedOption,
  showprogress,
  removeFile,
  handleClick,
  handleClose,
  handleSelectedoption,
  progressbarpercentage,
  handleChangeVisibility,
  handleComment,
  resetFieldError,
  filename,
  documentsDetailsdata,
  page,

  filesize,
  anchorEl,
  handleVisibility,
  visibilityOption,
  handleDeleteDocument,
}) => {
  return (
    <Box
      className={`tab-pane fade show ${tab === 5 ? "active" : ""}`}
      id="pills-attachments"
      role="tabpanel"
      aria-labelledby="pills-attachments-tab"
      tabindex="0"
    >
      <GridContainer className="row">
        <Grid xs="12" md="12" lg="12">
          {" "}
          <Box className="client-text">
            {page === "edit" ? "Documents" : "Add Document"}
          </Box>{" "}
        </Grid>
        {page === "edit" ? (
          // <Grid xs="12" md="6" lg="6"></Grid>
          <>
            {documentsDetailsdata?.length>0?documentsDetailsdata?.map((item, key) => {
              return (
                <>
                  <Grid xs="12" md="6" lg="6"></Grid>
                  <Grid xs="12" md="6" lg="6">
                    <Box>
                      <Box
                        className="toast align-items-center d-block mb-3"
                        role="alert"
                        aria-live="assertive"
                        aria-atomic="true"
                        
                        sx={{width:"100%"}}
                        
                      >
                        <Box
                          sx={{ display: "flex" , width:"100%" }}
                          className="d-flex justify-content-between align-items-center"
                        >
                          <Box
                            className="toast-body"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              // paddingBottom: "0px",

                              width:"50%"

                            }}
                          >
                            <Typography>{item.documentDisplayName}</Typography>
                          </Box>
                          
                          <Typography className="memberaccess" sx={{marginRight:"10px"}}>
                          {/* {item.visibility} */}
                          
                          <Select
                            className="users-country-select"
                            options={visibilityOption}
                            placeholder="Visibility"
                            
                            onChange={(e)=> handleVisibility(e , item , key)}
                            value={visibilityOption?.find((visibility , key)=> visibility.value === item.accessGroup)}
                            styles={{...customStyles() , ...usersCountryDropdown()}}
                            dropdownPosition="auto"
                            maxMenuHeight={300}
                          
                          />
                          </Typography>
                          <Typography sx={{marginRight:"1%"}}>
                          <CloseButton onClick={()=> handleDeleteDocument(item.id)}/>
                          </Typography>
                        </Box>
                      </Box>
                      
                    </Box>
                  </Grid>
                </>
              );
            }):
            
            <>
            <Grid xs="12" md="6" lg="6"></Grid>
                  <Grid xs="12" md="6" lg="6">
            <Typography className="client-text"> No Documents added </Typography>
            
            </Grid>

</>
            
            }
          </>
        ) : (
          <>
            <Grid xs="12" md="6" lg="6"></Grid>
            <Grid xs="12" md="6" lg="6">
              <Box sx={{ marginBottom: "10px" }}>
                <Select
                  className="text-start"
                  sx={{ zIndex: "110" }}
                  onChange={(e) => handleChangedocumentType(e)}
                  
                  options={
                    documentTypes?.length > 0
                      ? dropDownOptions(documentTypes , "documentTypeId" , "name")
                      : []
                  }
                  value={selecteddocumentType}
                  styles={customStyles(errors.selecteddocumentType)}
                />
              </Box>
              {errors.selecteddocumentType && (
                <Box
                  className="error-msge"
                  style={{ color: "#dc3545", fontSize: "14px" }}
                >
                  {errors.selecteddocumentType}
                </Box>
              )}
            </Grid>
            <Grid xs="12" md="6" lg="6"></Grid>
            <Grid xs="12" md="6" lg="6">
              <Box className="document-upload-outer mb-3">
                <Dropzone
                  onFileUpload={handleFile}
                  validateFile={validateFile}
                />
              </Box>
              <Typography>
                {fileError && (
                  <Typography
                    sx={{ color: "var(--color-text-critical) !important" }}
                  >
                    {fileError}
                  </Typography>
                )}
              </Typography>

              <Box className=" mb-3 mt-3">
                {document?.map((item, key) => {
                  return (
                    // <p>{item.name}</p>
                    <Box
                      className="toast align-items-center d-block mb-3 mt-3"
                      role="alert"
                      aria-live="assertive"
                      aria-atomic="true"
                      sx={{ width: "100%" }}
                    >
                      <Box sx={{ display: "flex" }} className="d-flex">
                        <Box
                          className="toast-body"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            paddingBottom: "0px",
                            justifyContent: "space-between",
                            marginBottom: "10px",
                            width: "100%",
                          }}
                        >
                          <Grid item xs="1">
                            {" "}
                            <svg
                              width="40"
                              height="40"
                              viewBox="0 0 40 40"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              style={{ width: "30px", height: "30px" }}
                            >
                              <path
                                d="M0 20C0 8.95431 8.95431 0 20 0C31.0457 0 40 8.95431 40 20C40 31.0457 31.0457 40 20 40C8.95431 40 0 31.0457 0 20Z"
                                fill="#E9D7FE"
                              />
                              <path
                                d="M20.667 13.3333H16.0003C15.6467 13.3333 15.3076 13.4738 15.0575 13.7238C14.8075 13.9739 14.667 14.313 14.667 14.6666V25.3333C14.667 25.6869 14.8075 26.0261 15.0575 26.2761C15.3076 26.5262 15.6467 26.6666 16.0003 26.6666H24.0003C24.354 26.6666 24.6931 26.5262 24.9431 26.2761C25.1932 26.0261 25.3337 25.6869 25.3337 25.3333V18M20.667 13.3333L25.3337 18M20.667 13.3333L20.6671 18L25.3337 18"
                                stroke="#6941C6"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </Grid>
                          <Grid item xs="10">
                            <input
                              type="text"
                              value={filename}
                              className="form-control upload-input-field"
                              placeholder="Typing"
                              disabled={
                                selectedOption === "edit"
                                  ? false
                                  : filename
                                  ? true
                                  : false
                              }
                              onChange={(e) => {
                                {
                                  handleFilename(e.target.value);
                                }
                                resetFieldError("filename");
                              }}
                              onBlur={(e) => handleSelectedoption("")}
                              style={{ backgroundColor: "#FFF" }}
                              ref={inputRef}
                            />
                            {filesize ? (
                              <Typography
                                variant="span"
                                sx={{
                                  color: "var(--grey-500)",
                                  marginLeft: "9%",
                                  marginBottom: "10px",
                                }}
                              >
                                {filesize}
                              </Typography>
                            ) : (
                              ""
                            )}
                          </Grid>

                          <Grid item xs="1">
                            {showprogress ? (
                              <CloseButton
                                onClick={() => removeFile(item, key)}
                              />
                            ) : (
                              <>
                                <IconButton
                                  aria-controls="vertical-menu"
                                  aria-haspopup="true"
                                  onClick={handleClick}
                                  sx={{ zIndex: "0 !important" }}
                                >
                                  <MoreHorizIcon />
                                </IconButton>
                                <Menu
                                  id="vertical-menu"
                                  anchorEl={anchorEl}
                                  open={Boolean(anchorEl)}
                                  onClose={handleClose}
                                  anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "right",
                                  }}
                                  transformOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                  }}
                                  getContentAnchorEl={null}
                                  sx={{
                                    "& .MuiPaper-root": {
                                      // width:"150px",
                                      padding: "5px",
                                    },
                                  }}
                                >
                                  <MenuItem
                                    onClick={() => {
                                      handleSelectedoption("edit");
                                      // inputRef.current.focus();
                                      setTimeout(() => {
                                        inputRef.current.focus();
                                      }, 20);
                                      handleClose();
                                    }}
                                    sx={{ fontSize: "13px !important" }}
                                  >
                                    Edit name
                                  </MenuItem>
                                  <MenuItem
                                    onClick={() => {
                                      handleSelectedoption("delete");
                                      handleClose();
                                      removeFile(item, key);
                                    }}
                                    sx={{ fontSize: "13px !important" }}
                                  >
                                    Delete
                                  </MenuItem>
                                </Menu>
                              </>
                            )}
                          </Grid>
                        </Box>
                      </Box>

                      {showprogress && (
                      <Progressbar
                        progressbarpercentage={progressbarpercentage}
                      />
                       )} 
                    </Box>
                  );
                })}
              </Box>
              <Typography>
                {errors.filename && (
                  <Typography
                    sx={{ color: "var(--color-text-critical) !important" }}
                  >
                    {errors.filename}
                  </Typography>
                )}
              </Typography>
              <Typography className="upload-text text-start mb-1">
                Visibility
              </Typography>
              <Box
                className="visibility-box text-start ps-2"
                sx={{ zIndex: "100" }}
              >
                <FormControl>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="Client"
                    name="radio-buttons-group"
                    onChange={(e) => {
                      handleChangeVisibility(e);
                    }}
                  >
                    <FormControlLabel
                      value="Client"
                      control={<Radio sx={{ color: "#53389e !important" }} />}
                      label="All associated with this client"
                    />
                    <FormControlLabel
                      value="ProducingFirm"
                      control={<Radio sx={{ color: "#53389e !important" }} />}
                      // label="Producing brokers of firm only"
                      label="producing broker and my firm only"
                    />
                  </RadioGroup>
                </FormControl>
              </Box>
              <Box id="preview"></Box>
            </Grid>
          </>
        )}
      </GridContainer>
      
      <hr />
      <GridContainer className="row">
        <Grid xs="12" md="6" lg="6">
          <Box className="client-text">Comments</Box>
        </Grid>
        <Grid xs="12" md="6" lg="6" className=" mt-2">
          <Textarea
            className={errors.comment?"is-invalid form-control":"form-control"}
            id="exampleFormControlTextarea1"
            // rows="2"
            placeholder="Type exclusion here"
            onChange={(e) => {handleComment(e.target.value);resetFieldError("comment" , e.target.value)}}
            sx={{ width: "100%" }}
            
          ></Textarea>
          {errors.comment && (
            <Box
              className="error-msge"
              style={{ color: "#dc3545", fontSize: "14px" }}
            >
              {errors.comment}
            </Box>
          )}
        </Grid>
      </GridContainer>
    </Box>
  );
};
export default Tab5;
